import { IFieldForm } from "../model/IFieldForm";
import { IOptionsDropdowns } from "shared/models";

export const fieldsForm = (optionsDropdowns: IOptionsDropdowns): IFieldForm[]  => {
    return [
        {
            id: 'itemformTypeDocument',
            name: 'typeDocument',
            type: 'Select',
            label: 'Tipo Documento',
            placeholder: 'Seleccione tipo de documento',
            testId: 'itemformTypeDocument',
            isEnable: true,
            options: optionsDropdowns?.typeDocument,
            defaulValue: String(optionsDropdowns?.typeDocument[0]?.id)
        },
        {
            id: 'itemformDocument',
            name: 'documentNumber',
            type: 'text',
            label: 'Número Documento',
            placeholder: 'Ingrese Nro documento',
            testId: 'itemformDocument',
            isEnable: true
        },
        {
            id: 'dateFrom',
            name: 'dateFrom',
            type: 'datePicker',
            label: 'Fecha de expedición desde',
            placeholder: 'Fecha de expedición desde',
            testId: 'itemformDateFrom',
            isEnable: true
        },
        {
            id: 'dateTo',
            name: 'dateTo',
            type: 'datePicker',
            label: 'Fecha de expedición hasta',
            placeholder: 'Fecha de expedición hasta',
            testId: 'itemformDateTo',
            isEnable: true
        },
        {
            id: 'itemformIdPs',
            name: 'idEntidad',
            type: 'text',
            label: 'Id. Entidad PS',
            placeholder: 'Ingrese id entidad PS',
            testId: 'itemformIdPS',
            isEnable: true
        },
        {
            id: 'itemformNit',
            name: 'nitEntidad',
            type: 'text',
            label: 'Nit Entidad PS',
            placeholder: 'Ingrese nit entidad PS',
            testId: 'itemformNit',
            isEnable: true
        },
        {
            id: 'assigned-users',
            name: 'assigned',
            type: 'Autocomplete',
            label: 'Asignado A',
            placeholder: 'Seleccione usuario',
            testId: 'itemformAssigned',
            isEnable: true,
            options: optionsDropdowns?.users
        },
        {
            id: 'itemformContractNumber',
            name: 'contractNumber',
            type: 'text',
            label: 'Nro. Contrato',
            placeholder: 'Ingrese Nro. Contrato',
            testId: 'itemformContractNumber',
            isEnable: true
        },  
        
    ]
}