import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch, RootState } from 'shared/store/store';
import { IGlosa, accesRoles } from 'shared/models';
import { getData } from 'shared/api/services';
import { GLOSSES } from 'shared/api';
import Image from 'shared/assets/index';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useAuth } from 'shared/auth';
import { statesActivities } from 'shared/models/lineStates';
import { exportFileGlosses } from 'shared/api/services/exportFileGlosses';
import { downloadDocument, downloadsTitle } from 'shared/utils';
import { ModalReplyGlosses } from '../components/modal-reply-glosses/ModalReplyGlosses';
import { useDetailedConsultation } from './useDetailedConsultation';
import { ModalReplyGlossesNoResponse } from '../components/modal-reply-glosses/ModalReplyGlossesNoResponse';
dayjs.locale("es");

const codesGlossasUpdatables = [{
    code: '3',
    name: 'Aceptada'
},
{
    code: '4',
    name: 'Sustentada',
},
{
    code: '5',
    name: 'Sustentada Parcial',
},
{
    code: '6',
    name: 'No Acepta',
},
{
    code: '7',
    name: 'Aceptada Parcial Consolidada',
},
{
    code: '8',
    name: 'Consolidada',
},
]

export const useTabGlosses = (NumberInvoice?: any) => {

    const dispatch = useDispatch<Dispatch>();
    const consolidateGlosas = useSelector((state: RootState) => state.consolidateGlosas);
    const { billingState, valuesPaymentConsolidate } = useSelector((state: RootState) => state.auditDetails);
    const { glosas, glossesTotalValue, edocumentPaymentAuthorization, allGlossConsolidated, sumValuesAllValueAccept, sumValuesAllValueNotAccept, sumValuesAllValueSustained } = consolidateGlosas;
    const { loading } = useSelector((rootState: RootState) => rootState.loading.models.consolidateGlosas);
    const { idDocument } = useParams();
    const { authService } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [dataGlosas, setDataGlosas] = useState<any>(null);
    const [documentsSupports, setDocumentsSupports] = useState<any | null>(null)
    const { callGetDetailedDocument, provider } = useDetailedConsultation();

    const contentDataGlossas = [
        { label: 'Fecha', idLabel: 'dateGlossa' },
        { label: 'Total', idLabel: 'totalGlossa' },
        { label: 'Auditor', idLabel: 'auditGlossa' },
        { label: 'Observaciones', idLabel: 'justification' },
    ];

    const callGetFiles = (idGlossa: number) => {
        setIsLoading(true);
        getData( `${GLOSSES}/${idGlossa}/associated-support`).then((response: any) => {
            convertServiceGetFiles(response)
        }).finally(()=>setIsLoading(false))
    }


    const downloadSupports = async (id: number) => {
        const response = await exportFileGlosses({
            url: `${GLOSSES}/${id}/download`,
        })
        downloadDocument(response, downloadsTitle.successDocument, "SOPORTE descargado con Exito", 'success');
    }

    const viewDocument = (id:any)=>{
        window.open(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/file/${id}`, "_blank", "noreferrer")
    }


    const handleReplyGlossa = (col: IGlosa, read?: boolean, enable?: boolean) => {
        const isReadOnly = (read ?? false) && validateGlossesUpdatatables(col);
        const computedEnableConsolidate = (enable || isGlossaConsolidate(col)) 

        dispatch.messageModal.setMessageModal({
            show: true, 
            title: "Consolidar Glosa",
            size: 'large',
            description: (
                <ModalReplyGlosses
                    billName={NumberInvoice}
                    idGlossa={col.id ?? 0}
                    readOnly={isReadOnly}
                    enableConsolidate={computedEnableConsolidate}
                    billingState={billingState}
                    glosas={glosas}
                    idDocument={idDocument ?? ''}
                    documentsSupports={documentsSupports}
                    callGetFiles={callGetFiles}
                    downloadSupports={downloadSupports}
                    viewDocument={viewDocument}
                    callGetDetailedDocument={callGetDetailedDocument}
                    provider={provider}
                />
            ),
            onClose() {
                dispatch.messageModal.cleanStateMessage()
            },
        })
    };

    const handleReplyGlossaNoResponse = (col: IGlosa) => {
        dispatch.messageModal.setMessageModal({
            show: true, 
            title: "Consolidar Glosa",
            size: 'large',
            description: (
                <ModalReplyGlossesNoResponse
                    onCloseModal={ () => dispatch.messageModal.cleanStateMessage()}
                    idGlossa={col.id ?? 0}
                    glosas={glosas}
                    billName={NumberInvoice}
                    idDocument={idDocument ?? ''}
                    callGetDetailedDocument={callGetDetailedDocument}
                    setIsLoading={setIsLoading}
                />
            ),
            onClose() {
                dispatch.messageModal.cleanStateMessage()
            },
        })
    }

    const validateGlossesUpdatatables = (col: IGlosa) => codesGlossasUpdatables.some((code: any) => code.code === col.statusGlosa.code);
    const isGlossaConsolidate = (col: IGlosa) => (typeof col.glossSupport === 'boolean');

    const convertDataGlossas = () => {
        const data = {
            columns: contentDataGlossas,
            content: {
                dateGlossa: edocumentPaymentAuthorization.createdAt ? dayjs(new Date(edocumentPaymentAuthorization.createdAt)).format('DD MMMM YYYY HH:mm') : "",
                totalGlossa: glossesTotalValue?.toLocaleString("es-CO"),
                auditGlossa: edocumentPaymentAuthorization.createdBy || "",
                justification: edocumentPaymentAuthorization.glossesGeneralDetail || "",
            }
        }
        setDataGlosas(data);
    }

    const acceptedConsolidatedValue = !!valuesPaymentConsolidate?.initialValues.acceptedConsolidatedValue?.value;

    const checkUserRoleToEnableResultAcceptSection = () => {
        const userRoles = authService.getUserRoles();

        const isAudithor = userRoles.some(role => accesRoles.auditor.includes(role));
        const isHealthProviderRol = userRoles.some(role => accesRoles.healthProvider.includes(role));

        const billingCorrectState = statesActivities.acceptAuditConsolidation.includes(billingState.code);
        
        return (isHealthProviderRol || isAudithor) && billingCorrectState;
    }

    const convertServiceGetFiles = (dataDocs: any) => {
        const data = {
            icon: Image.iconoCheck,
            title: 'Soportes Adjuntos',
            columns: [
                { field: 'originalName', headerName: 'Nombre del soporte', minWidth: 180 },
                { field: 'createdAt', headerName: 'Fecha', minWidth: 160 },
                { field: 'actions', headerName: 'Acciones ', minWidth: 160 },
            ],
            content: dataDocs?.map((item: any, index: number) => {
                return {
                    id: item.id || '',
                    createdAt: item.createdAt || "",
                    status: item.status.name || "",
                    createdBy: item.createdBy || "",
                    originalName: item.originalName || "",
                }
            }) || []
        };
        setDocumentsSupports(data);
    }

   
    useEffect(() => {
        convertDataGlossas();
    }, [glosas, glossesTotalValue])

    return {
        loading,
        isLoading,
        dataGlosas,
        glosas,
        glossesTotalValue,
        idDocument,
        billingState,
        dispatch,
        valuesPaymentConsolidate,
        allGlossConsolidated,
        sumValuesAllValueNotAccept,
        sumValuesAllValueSustained,
        sumValuesAllValueAccept,
        acceptedConsolidatedValue,
        consolidateGlosas,
        handleReplyGlossa,
        validateGlossesUpdatatables,
        convertDataGlossas,
        checkUserRoleToEnableResultAcceptSection,
        handleReplyGlossaNoResponse
    }
}