import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { ICommentsState, IComment, ICommentEdited, ICommentList } from "shared/models/store/IComments";
import { apiInstance } from "shared/api/config/apiInstance";
import { GET_COMMENTS } from "shared/api";

export const comments = createModel<RootModel>()({
  state: {
    openDrawer: false,
    comments: {},
  } as ICommentsState,
  reducers: {
    handleOpenDrawer( state, payload ){
        return {
            ...state,
            openDrawer: payload
        }
    },
    addComment(state, payload: IComment[]) {
      return {
        ...state,
        comments: payload
      }
    },
    editComment(state, payload: ICommentEdited) {
      return {
        ...state,
        comments: state.comments.map(comment => {
          if (comment.id === payload.id) {
            return {
              ...comment,
              message: payload.message,
              wasUpdated: true,
              date: new Date(),
            }
          }

          return { ...comment }
        })
      }
    }
  },
  effects: (dispatch) => ({
    async getCommentListAsync(documentId: string) {
      const response = await apiInstance.get(`${GET_COMMENTS}/${documentId}`)

      const addResponseComment: IComment[] = response?.data.map((data:ICommentList ) => {
        const list: IComment  = {
          id : String(data.id),
          author: data.createdBy,
          message: data.comment,
          date: new Date(data.createdAt),
          privacy: data.isPrivate ? 'privado' : 'publico',
          wasUpdated: false,
          isVisible: data.isVisible,
          modifiedAt: data.modifiedAt
        }
        return list
      }) || []
      
      const sortedComments = addResponseComment.sort((a, b) => {
        const dateA = a.modifiedAt ? new Date(a.modifiedAt).getTime() : new Date(a.date).getTime();
        const dateB = b.modifiedAt ? new Date(b.modifiedAt).getTime() : new Date(b.date).getTime();
        return dateB - dateA; // Ordena por la fecha de la más reciente a la más antigua
      });

      dispatch.comments.addComment(sortedComments)
    },
  })
});
