import {useEffect} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Dispatch, RootState } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import Imagen from '../assets/index'
import { useWidth } from 'shared/hooks/HookDetailedConsultation/useWidth';
import styles from './styles/Navbar.module.scss'
import { useNavigateToCustom } from 'shared/hooks/useNavigateToCustom';
import { ButtonNotifications } from './notifications/ButtonNotifications';
import { ModalNotifications } from './notifications/ModalNotifications';

export const Navbar = () => {
  const dispatch = useDispatch<Dispatch>(); 

  const { width } = useWidth();
  const { notifications, menuDrawer } = useSelector((state: RootState) => state);
  const { anchorOrigin } = notifications
  const {navigateHome, navigateToInvoices} = useNavigateToCustom();

  useEffect(() => {
    dispatch.notifications.getNotificationsAsync({
      page: 1,
      status:'N',
      initialLoad: true
    })
  }, [])

  useEffect(() => {
    if (width < 900 && anchorOrigin) {
      dispatch.notifications.addStatemodal(null);
    }
  }, [width, anchorOrigin]);

  return (
    <>
      {
        width > 900 ?
          <Box className={styles.containerTopBar} data-testid='content_box'>
            <Box 
              className={styles.contentLogo}
              data-testid='logo-trazabilidad'
            >
              <img src={Imagen.logoTrazabilidad} alt='Trazabilidad' onClick={ navigateHome } data-testid='icon_trazabilidad'/>
            </Box>
            <Box className={styles.topBar}>
              
              <img className={styles.imageGobernacion} src={Imagen.logoGobernacion} alt="Logo Gobernación" />
              <Box display='flex' gap={3}>
                <Box className={styles.itemSearch} onClick={ navigateToInvoices } data-testid='navigateInvoices'>
                  <SearchIcon/>
                  <span>Buscar</span>
                </Box>
                <ButtonNotifications/>
              </Box>
            </Box>
          </Box>
        : 
        <Box className={styles.containerTopBarMobile} data-testid='content_box_movil'>
          <Box className={styles.contentMobile}>
            <IconButton
              aria-label="btn_toogle_menu"
              size="large"
              edge="start"
              color="success"
              sx={{ mr: 2}}
              onClick={dispatch.menuDrawer.toogleMenu}
              data-testid="menu-mobile"
            >
                <MenuIcon />
              </IconButton>
              <Box 
                className={styles.logoTrazabilidad}
                onClick={ navigateHome }
                data-testid='icon_trazabilidad_movil'
              >
                <img src={Imagen.logoTrazabilidad} alt="trazabilidad" />
              </Box>
              {
                !menuDrawer.open &&
                  <Box className={styles.itemSearchMobile} onClick={navigateToInvoices} data-testid='navigate_invoices_movil'>
                    <SearchIcon/>
                  </Box>
              }
          </Box>
        </Box>
      }
      {
        anchorOrigin && <ModalNotifications/>
      }
    </>
  )
}
