import React, { Fragment, ReactElement } from 'react'
import { Box, Modal, Typography } from '@mui/material';
import styles from './styles/Modal.module.scss'

interface Props {
  title?: string;
  subtitle?: string;
  children?: ReactElement;
  className?: any;
  hidden?: boolean;
  open: boolean;
  onClose: ()=> void;
}

export const ModalFiles = ({title, subtitle, children, open, hidden, className, onClose}:Props) => {

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={()=> onClose()}
        aria-labelledby="modal-upload"
        id="modal-upload"
        data-testid="modal-upload"
        sx={{display: hidden ? 'none' : 'block'}}
      >
        <div>
          <Box className={`${className || ''} ${styles.container}`} display={hidden ? 'none' : 'block'} data-testid="modal-upload-box">
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography className='titleMain fs-7 fs-bold my-1 center'>{title}</Typography>
              <Typography className={styles.subtitle}><b>{subtitle}</b></Typography>
            </Box>
            {children}
          </Box>
        </div>
      </Modal>
    </Fragment>
  )
}
