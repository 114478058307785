import { useCallback } from "react";
import ReactQuill from "react-quill";
import "quill-mention";
import 'react-quill/dist/quill.snow.css';
import "quill-mention/dist/quill.mention.css";
import './styles/_richTextEditor.scss';
import {  useSelector } from "react-redux";
import {  RootState } from "shared/store/store";

interface Props {
    onChange: (value: string) => void;
    onBlur?: () => void;
    onKeyup?: (value:any) => void;
    readOnly: boolean;
    value: any;
    useMention?: boolean;
    propWithMention?: 'custom-ql-height-auto' | 'custom-ql-height-30vh' | 'custom-ql-height-40vh';
    isHeightAuto?: boolean,
    customClass?: string
}

export const RichTextEditor = ({ onChange, onBlur, onKeyup, readOnly, value, useMention, propWithMention, isHeightAuto, customClass }: Props) => {


    const { optionsDropdowns } = useSelector((state: RootState) => state);

    const source = useCallback(async(searchTerm: string, renderItem: (arg0: { id: number; value: string }[] | undefined, arg1: any) => void, mentionChar: string) => {

        let values;

        const matchedPeople =  await suggestPeople(searchTerm);

        if (mentionChar === '@') {
            values = matchedPeople;
        }

        if (searchTerm.length === 0) {
            renderItem(values as any, searchTerm);
        } else if (values) {
            renderItem(matchedPeople, searchTerm);
        }

    }, [optionsDropdowns.users])

     async function suggestPeople(search: any) {
        const matches = optionsDropdowns.users?.map(us => {
            return {
                id: us.id,
                value: us.name
            }
        })
        return matches.filter(person => person.value.toLowerCase().includes(search.toLowerCase()));
    }
    
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
        mention: useMention ? {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            spaceAfterInsert: true,
            mentionDenotationChars: ['@'],
            source,
            mentionContainerClass: 'useMentionClase',
            // renderLoading: () =>{ return <div>Loading...</div>}
        } : undefined, 
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'mention'
    ];
    const formatsWithouMention = formats.filter(format => format !== 'mention');

    return (
        ((value && optionsDropdowns.users.length > 0) || (value && !useMention)) &&
        <ReactQuill
            onKeyUp={(e:any)=> onKeyup && onKeyup(e?.target?.innerHTML)}
            onChange={(value) => { !readOnly && onChange(value) }}
            onBlur={(_, __, editor) => {
                setTimeout(() => {
                    !editor.getSelection() && !readOnly && onBlur && onBlur();
                }, 50)
            }}
            readOnly={readOnly}
            modules={modules}
            formats={useMention ? formats : formatsWithouMention}
            value={value}
            className={`richTextEditor ${customClass} ${isHeightAuto && 'heightAuto'} ${readOnly ? 'disabled' : 'backgroundWhite'} ${propWithMention} `}
        />
    )
}
