import React, { Fragment, useEffect } from 'react';
import { RouterGuard, Spinner } from 'shared/components';
import { useTabGlosses } from '../hooks/useTabGlosses';
import { DetailedGlosse } from './modal-reply-glosses/DetailedGlosse';
import { Box, TableContainer } from '@mui/material';
import { GridAuditGlosses } from './audit-glosses/GridAuditGlosses';
import styles from '../styles/tabGlosses.module.scss';
import { FormAuditGlosses } from './audit-glosses/FormAuditGlosses';
import { accesRoles } from 'shared/models';
import { AuditResultAcceptanceForm } from './AuditResultAcceptanceForm';
import { statesActivities } from 'shared/models/lineStates';

export const TabGlosses = ({ NumberInvoice }: any) => {

    const { 
        idDocument,
        glosas,
        dataGlosas,
        glossesTotalValue,
        billingState,
        dispatch,
        allGlossConsolidated,
        sumValuesAllValueAccept,
        sumValuesAllValueNotAccept, 
        sumValuesAllValueSustained,
        isLoading,
        loading,
        handleReplyGlossa,
        validateGlossesUpdatatables,
        checkUserRoleToEnableResultAcceptSection,
        acceptedConsolidatedValue,
        handleReplyGlossaNoResponse
    } = useTabGlosses( NumberInvoice );

    useEffect(() => {
        dispatch.consolidateGlosas.getlistGlosasAsync(idDocument)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    const renderGlossesContent = () => {
        if (!glosas || glosas.length === 0) return renderNoGlossesContent();

        return (
            <Fragment>
                {dataGlosas && <DetailedGlosse dataGlosas={dataGlosas} title='Detalle de Glosas' />}
                <GridAuditGlosses 
                    glosas={glosas} 
                    billingState={billingState} 
                    glossesTotalValue={glossesTotalValue} 
                    sumValuesAllValueAccept={sumValuesAllValueAccept}
                    sumValuesAllValueNotAccept={sumValuesAllValueNotAccept}
                    sumValuesAllValueSustained={sumValuesAllValueSustained}
                    idDocument={idDocument} 
                    handleReplyGlossa={handleReplyGlossa} 
                    validateGlossesUpdatatables={validateGlossesUpdatatables} 
                    handleReplyGlossaNoResponse={handleReplyGlossaNoResponse}
                />
                {renderConditionalComponents()}
            </Fragment>
        );
    };

    const renderConditionalComponents = () => (
        <Fragment>
            {allGlossConsolidated && (
                <RouterGuard roles={[...accesRoles.auditor, ...accesRoles.healthProvider]} type='component'>
                    <FormAuditGlosses glosas={glosas} />
                </RouterGuard>
            )}
            {checkUserRoleToEnableResultAcceptSection() && (
                <AuditResultAcceptanceForm acceptedConsolidatedValue={acceptedConsolidatedValue} />
            )}
        </Fragment>
    );

    const renderNoGlossesContent = () => {
        const isPaymentState = statesActivities.payments.includes(billingState.code);
        const containerClass = isPaymentState ? styles.gridGlosasResults : styles.gridGlosasResultsNotResults;
        const message = isPaymentState ? "Esta Factura no tiene glosas gestionadas en el proceso de Auditoria" : "Aun no se ha cargado la información correspondiente de esta sección";

        return (
            <TableContainer className={containerClass} data-testid={`grid-glosas${isPaymentState ? '' : '-not-results'}`}>
                <Box className={styles.containerNotResults} data-testid="title-not-results">{message}</Box>
            </TableContainer>
        );
    };

    return (
        <div className={styles.containerGlosses}>
            {renderGlossesContent()}
            {(isLoading ?? loading) && <Spinner/>} 
        </div>
    );
}
