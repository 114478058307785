import { Box, Drawer, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import Imagen from '../../assets/index'
import { ButtonLogout } from '../ButtonLogout';
import { useWidth } from 'shared/hooks/HookDetailedConsultation/useWidth';
import { BagageUser } from './BagageUser';
import { MenuList } from './MenuList';
import { useInfoUser } from 'shared/hooks/useInfoUser';
import SearchIcon from '@mui/icons-material/Search';
import './styles/_sidebar.scss'
import { useNavigateToCustom } from 'shared/hooks/useNavigateToCustom';
import { Spinner } from '../Spinner';
import { Fragment } from 'react';

export const SideBar = () => {

	const dispatch = useDispatch<Dispatch>();
	const { menuDrawer } = useSelector((state: RootState) => state);
	const { open } = menuDrawer
	const { loading } = useSelector((rootState: RootState) => rootState.loading.models.electronicDocuments);
	const { width } = useWidth();
	const {user} = useInfoUser();
	const {navigateHome, navigateToInvoices} = useNavigateToCustom();

	const closeOutsideElementUI = (event: any) => { 
		event.target === event.currentTarget && animateAndCloseMenu() 
	}

	const closeMenu = () => { 
		dispatch.menuDrawer.closeMenu() 
	};

	const animateAndCloseMenu = () => {
		const menu = document.querySelector('.menuMobile');
		if (menu) {
			menu.classList.add('hidden');
			// Esperar a que la animación termine antes de cerrar el menú
			menu.addEventListener('animationend', closeMenu, { once: true });
		}
	}

	return (
		<Fragment>
			{
				width > 900 ?
					<Box component="nav" aria-label="menu trazabilidad" data-testid="menu_drawer">
						<Drawer
							className='menuDrawer'
							variant="persistent"
							anchor="left"
							open={true}
							onClose={dispatch.menuDrawer.closeMenu}
							ModalProps={{
								keepMounted: true,
							}}
						>
							<Box className='headerContenedor'>
								<Box className='containerImgName'>
									<BagageUser user={user}/>
									<Box className='contentInfo'>
										<Typography data-testid='info-user' className='infoUser'>{user} </Typography>
									</Box>
								</Box>
							</Box>

							<MenuList/>

							<Box className='contentBtnLogout'>
								<ButtonLogout/>
							</Box>

						</Drawer>
					</Box>
				: open &&
					<Box data-testid='menu_mobile' className='menuMobile' onClick={closeOutsideElementUI}>
						<Box className='menuMask'>
							<IconButton className='buttonMenu' onClick={animateAndCloseMenu}>
								<CloseIcon />
							</IconButton>
							<Box 
								className='headerContenedor'
								onClick={navigateHome}
								data-testid='headerContenedor'
							>
								<img src={Imagen.logoTrazabilidad} alt="trazabilidad" />
							</Box>
							<Box className={'itemSearchMobile'} onClick={navigateToInvoices}>
								<SearchIcon/>
							</Box>
						</Box>
						<Box className='toogleContainer'>
							<Box className='containerImgName'>
								<BagageUser user={user} />
								<Box className='contentInfo'>
									<Typography data-testid='info-user' className='infoUser'>{user} </Typography>
								</Box>
							</Box>
						</Box>
						<Box className='containerContentMenu'>
							<Box className='mask'></Box>
							<Box className='containerItemsMenu'>
								<MenuList/>
							</Box>
							<Box className='contentBtnLogout'>
								<ButtonLogout closeMenu={animateAndCloseMenu}/>
							</Box>
						</Box>
					</Box>
			}
			{ (loading) &&  <Spinner/>}
		</Fragment>
	);
};
