
import {GridAlignment} from "@mui/x-data-grid/models/colDef/gridColDef";
import {GridRenderCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import * as React from "react";
import {formatCurrency} from "../../../shared/utils";

export const contentTableRowDetailed: typeContentTableRowDetailed[] = [
    { field: 'id', headerName: 'Id', minWidth: 20},
    { field: 'idFecha', headerName: 'Fecha y hora' , minWidth: 230},
    { field: 'idAccion', headerName: 'Acción' , minWidth: 210},
    { field: 'idDescripcion', headerName: 'Descripción', minWidth: 360},
    { field: 'idUsuario', headerName: 'Usuario' , minWidth: 230},
]

export const contentTableRowRipsCUV: typeContentTableRowDetailed[] = [
    { field: 'processId', headerName: 'Proceso Id', minWidth: 140},
    { field: 'invoiceNumber', headerName: 'Factura' , minWidth: 180},
    { field: 'dateCuv', headerName: 'Fecha' , minWidth: 140},
    { field: 'timeCuv', headerName: 'Hora' , minWidth: 140},
    { field: 'processState', headerName: 'Estado Proceso' , minWidth: 140},
    { field: 'cuv', headerName: 'Código Único de Validación (CUV)' , minWidth: 210, gridSizeMd: 8, gridSizeSm: 12},
]

export const contentTableRowRipsUS: typeContentTableRowDetailed[] = [
    { field: 'identificationType', headerName: 'Tipo documento', minWidth: 160},
    { field: 'identificationNumber', headerName: 'Número documento' , minWidth: 180},
    { field: 'userType', headerName: 'Tipo de Usuario' , minWidth: 160},
    { field: 'firstLastName', headerName: 'Primer apellido', minWidth: 180},
    { field: 'secondLastName', headerName: 'Segundo apellido', minWidth: 180},
    { field: 'firstName', headerName: 'Primer nombre', minWidth: 180},
    { field: 'middleName', headerName: 'Segundo nombre', minWidth: 180},
    { field: 'age', headerName: 'Edad', minWidth: 110},
    { field: 'ageUnit', headerName: 'Tipo de edad', minWidth: 140},
    { field: 'gender', headerName: 'Sexo' , minWidth: 110},
    { field: 'province', headerName: 'Departamento' , minWidth: 130},
    { field: 'city', headerName: 'Municipio' , minWidth: 130},
    { field: 'residenceArea', headerName: 'Zona' , minWidth: 130},
    { field: 'birthDate', headerName: 'Fecha de nacimiento' , minWidth: 180},
    { field: 'country', headerName: 'País' , minWidth: 130},
    { field: 'inability', headerName: 'Incapacidad' , minWidth: 130},
    { field: 'countryResidenceCode', headerName: 'Código país de residencia' , minWidth: 180},
]

export const contentTableRowRipsAF: typeContentTableRowDetailed[] = [
    { field: 'healthServiceProviderCode', headerName: 'Código de prestador', minWidth: 160},
    { field: 'healthServiceProviderName', headerName: 'Razón social' , minWidth: 160},
    { field: 'identificationType', headerName: 'Tipo de identificación' , minWidth: 180},
    { field: 'identificationNumber', headerName: 'Número de identificación' , minWidth: 190},
    { field: 'invoiceNumber', headerName: 'Número de factura', minWidth: 180},
    { field: 'invoiceIssuedAt', headerName: 'Fecha expedición de factura', minWidth: 210},
    { field: 'attentionPeriodStartedAt', headerName: 'Fecha inicio prestación' , minWidth: 190},
    { field: 'attentionPeriodEndedAt', headerName: 'Fecha fin prestación' , minWidth: 190},
    { field: 'epsCode', headerName: 'Código entidad', minWidth: 140},
    { field: 'epsName', headerName: 'Nombre entidad', minWidth: 160},
    { field: 'contractNumber', headerName: 'Número del contrato' , minWidth: 160},
    { field: 'benefitsPlan', headerName: 'Plan de beneficios' , minWidth: 160},
    { field: 'insurancePolicyNumber', headerName: 'Número de la póliza' , minWidth: 160},
    { field: 'sharedFeeValue', headerName: 'Valor total del pago compartido' , minWidth:210},
    { field: 'commissionValue', headerName: 'Valor de la comisión' , minWidth: 180},
    { field: 'discountsValue', headerName: 'Valor total de descuentos' , minWidth: 210},
    { field: 'totalValue', headerName: 'Valor neto a pagar por la entidad' , minWidth:210},
]

export const contentTableRowRipsAC: typeContentTableRowDetailed[] = [
    { field: 'invoiceNumber', headerName: 'Número de factura', minWidth: 160},
    { field: 'healthServiceProviderCode', headerName: 'Código de prestador de Salud' , minWidth: 190},
    { field: 'identificationType', headerName: 'Tipo de identificación' , minWidth: 190},
    { field: 'identificationNumber', headerName: 'Número de identificación' , minWidth: 190},
    { field: 'consultationDate', headerName: 'Fecha de consulta' , minWidth: 160},
    { field: 'authorizationNumber', headerName: 'Número de autorización', minWidth: 180},
    { field: 'name', headerName: 'Nombre de consulta', minWidth: 180},
    { field: 'consultationPurpose', headerName: 'Finalidad de la consulta', minWidth: 190},
    { field: 'externalCause', headerName: 'Causa Externa' , minWidth: 180},
    { field: 'primaryDiagnosis', headerName: 'Diagnostico principal' , minWidth: 290},
    { field: 'firstRelatedDiagnosis', headerName: 'Diagnostico Relacionado 1' , minWidth: 190},
    { field: 'secondRelatedDiagnosis', headerName: 'Diagnostico Relacionado 2' , minWidth: 190},
    { field: 'thirdRelatedDiagnosis', headerName: 'Diagnostico Relacionado 3' , minWidth: 190},
    { field: 'primaryDiagnosisType', headerName: 'Tipo de Diagnostico Principal' , minWidth: 230},
    { field: 'consultationValue', headerName: 'Valor de la consulta' , minWidth: 180},
    { field: 'sharedFeeValue', headerName: 'Valor de la cuota moderadora' , minWidth: 220},
    { field: 'totalValue', headerName: 'Valor neto a pagar' , minWidth: 180},
    { field: 'attentionStartDate', headerName: 'Fecha de inicio' , minWidth: 160},
    { field: 'moderatorPaymentFevNumber', headerName: 'Numero FEV pago moderador' , minWidth: 160},
    { field: 'collectionConcept', headerName: 'Concepto recaudo' , minWidth: 160},
    { field: 'serviceCode', headerName: 'Código del servicio' , minWidth: 160},
    { field: 'serviceGroup', headerName: 'Grupo del servicio' , minWidth: 160},
    { field: 'serviceGroupModalityTecsal', headerName: 'Modalidad del grupo de servicios Tecsal' , minWidth: 230},
]

export const contentTableRowRipsAU: typeContentTableRowDetailed[] = [
    { field: 'invoiceNumber', headerName: 'Número de factura', minWidth: 160},
    { field: 'healthServiceProviderCode', headerName: 'Código de prestador de Salud' , minWidth: 180},
    { field: 'identificationType', headerName: 'Tipo de Documento' , minWidth: 160},
    { field: 'identificationNumber', headerName: 'Número de identificación' , minWidth: 180},
    { field: 'admissionDate', headerName: 'Fecha de ingreso a observación' , minWidth: 180},
    { field: 'admissionTime', headerName: 'Hora de ingreso a observación', minWidth: 190},
    { field: 'authorizationNumber', headerName: 'Número de autorización', minWidth: 190},
    { field: 'externalCause', headerName: 'Causa Externa', minWidth: 160},
    { field: 'egressDiagnosis', headerName: 'Diagnóstico a la salida' , minWidth: 190},
    { field: 'firstRelatedEgressDiagnosis', headerName: 'Diagnostico Relacionado 1' , minWidth: 190},
    { field: 'secondRelatedEgressDiagnosis', headerName: 'Diagnostico Relacionado 2' , minWidth: 190},
    { field: 'thirdRelatedEgressDiagnosis', headerName: 'Diagnostico Relacionado 3' , minWidth: 190},
    { field: 'egressDestination', headerName: 'Destino del usuario' , minWidth: 180},
    { field: 'egressVitalState', headerName: 'Estado a la salida' , minWidth: 180},
    { field: 'deathCause', headerName: 'Causa de muerte en urgencias' , minWidth: 220},
    { field: 'egressDate', headerName: 'Fecha de salida de observación' , minWidth: 220},
    { field: 'egressTime', headerName: 'Hora de salida de observación' , minWidth: 220},
    { field: 'admissionDateTime', headerName: 'Fecha y hora de ingreso a observación', minWidth: 220},
    { field: 'entranceDiagnosis', headerName: 'Diagnóstico de entrada', minWidth: 220},
    { field: 'egressDateTime', headerName: 'Fecha y hora de salida de observación', minWidth: 220},
]

export const contentTableRowRipsAH: typeContentTableRowDetailed[] = [
    { field: 'invoiceNumber', headerName: 'Número de factura', minWidth: 160},
    { field: 'healthServiceProviderCode', headerName: 'Código de prestador de Salud' , minWidth: 190},
    { field: 'identificationType', headerName: 'Tipo de Documento' , minWidth: 160},
    { field: 'identificationNumber', headerName: 'Número de identificación' , minWidth: 190},
    { field: 'admissionRoad', headerName: 'Vía de ingreso a la institución' , minWidth: 190},
    { field: 'admissionDate', headerName: 'Fecha de ingreso a observación' , minWidth: 190},
    { field: 'admissionTime', headerName: 'Hora de ingreso a observación', minWidth: 190},
    { field: 'authorizationNumber', headerName: 'Número de autorización', minWidth: 190},
    { field: 'externalCause', headerName: 'Causa Externa', minWidth: 160},
    { field: 'primaryAdmissionDiagnosis', headerName: 'Diagnóstico principal de ingreso' , minWidth: 290},
    { field: 'primaryEgressDiagnosis', headerName: 'Diagnóstico principal de egreso' , minWidth: 280},
    { field: 'firstRelatedEgressDiagnosis', headerName: 'Diagnostico Relacionado 1' , minWidth: 190},
    { field: 'secondRelatedEgressDiagnosis', headerName: 'Diagnostico Relacionado 2' , minWidth: 190},
    { field: 'thirdRelatedEgressDiagnosis', headerName: 'Diagnostico Relacionado 3' , minWidth: 190},
    { field: 'complicationDiagnosis', headerName: 'Diagnóstico de la complicación' , minWidth: 190},
    { field: 'egressVitalState', headerName: 'Estado a la salida' , minWidth: 160},
    { field: 'deathCauseDiagnosis', headerName: 'Diagnóstico causa de muerte' , minWidth: 220},
    { field: 'egressDate', headerName: 'Fecha de salida de observación' , minWidth: 220},
    { field: 'egressTime', headerName: 'Hora de salida de observación' , minWidth: 220},
    { field: 'attentionStartDate', headerName: 'Fecha de inicio de la atención' , minWidth: 220},
    { field: 'egressDateTime', headerName: 'Fecha y hora de salida', minWidth: 220},
]

export const contentTableRowRipsAM: typeContentTableRowDetailed[] = [
    { field: 'invoiceNumber', headerName: 'Número de factura', minWidth: 160},
    { field: 'healthServiceProviderCode', headerName: 'Código de prestador de Salud' , minWidth: 190},
    { field: 'identificationType', headerName: 'Tipo de Documento' , minWidth: 160},
    { field: 'identificationNumber', headerName: 'Número de identificación' , minWidth: 190},
    { field: 'authorizationNumber', headerName: 'Número de autorización', minWidth: 190},
    { field: 'medicineCode', headerName: 'Código del Medicamento', minWidth: 160},
    { field: 'medicineType', headerName: 'Tipo de Medicamento' , minWidth: 190},
    { field: 'activePrinciple', headerName: 'Nombre genérico del medicamento' , minWidth: 190},
    { field: 'pharmaceuticalPresentation', headerName: 'Forma Farmacéutica' , minWidth: 190},
    { field: 'measurementUnit', headerName: 'Unidad de medida del medicamento' , minWidth: 190},
    { field: 'amountOfUnits', headerName: 'Número de unidades' , minWidth: 190},
    { field: 'unitValue', headerName: 'Valor unitario de medicamento', minWidth: 220},
    { field: 'totalValue', headerName: 'Valor total de medicamento', minWidth: 220},
    { field: 'mipresId', headerName: 'Id MIPRES', minWidth: 160},
    { field: 'medicationAdministerAt', headerName: 'Fecha dispensación de medicamento', minWidth: 160},
    { field: 'primaryDiagnosis', headerName: 'Diagnóstico principal', minWidth: 190},
    { field: 'relatedDiagnosis', headerName: 'Diagnóstico relacionado', minWidth: 190},
    { field: 'medicationQuantity', headerName: 'Cantidad de medicamento', minWidth: 160},
    { field: 'treatmentDays', headerName: 'Días de tratamiento', minWidth: 160},
    { field: 'collectionConcept', headerName: 'Concepto de recaudo', minWidth: 190},
    { field: 'sharedFeeValue', headerName: 'Valor del pago moderadora', minWidth: 160},
    { field: 'moderatorPaymentFevNumber', headerName: 'Número FEV pago moderador', minWidth: 190},
    { field: 'concentration', headerName: 'Consecutivo', minWidth: 160},

]

export const contentTableRowRipsAN: typeContentTableRowDetailed[] = [
    { field: 'healthServiceProviderCode', headerName: 'Código de prestador de Salud' , minWidth: 190},
    { field: 'identificationType', headerName: 'Tipo de Documento' , minWidth: 160},
    { field: 'identificationNumber', headerName: 'Número de identificación' , minWidth: 190},
    { field: 'birthDate', headerName: 'Fecha de nacimiento del recién nacido', minWidth: 230},
    { field: 'birthTime', headerName: 'Hora de nacimiento', minWidth: 140},
    { field: 'pregnancyStatus', headerName: 'Edad gestacional', minWidth: 160},
    { field: 'birthControl', headerName: 'Control prenatal', minWidth: 160},
    { field: 'gender', headerName: 'Sexo' , minWidth: 130},
    { field: 'weight', headerName: 'Peso' , minWidth: 130},
    { field: 'newBornDiagnosis', headerName: 'Diagnóstico del recién nacido' , minWidth: 190},
    { field: 'deathCause', headerName: 'Causa básica de muerte' , minWidth: 190},
    { field: 'deathDate', headerName: 'Fecha muerte del recién nacido' , minWidth: 220},
    { field: 'deathTime', headerName: 'Hora muerte', minWidth: 190},
    { field: 'birthDateTime', headerName: 'Fecha y hora de nacimiento', minWidth: 160},
    { field: 'egressDateTime', headerName: 'Fecha y hora de egreso', minWidth: 160},
    { field: 'egressVitalState', headerName: 'Estado de la salida', minWidth: 160},
]

export const contentTableRowRipsAP: typeContentTableRowDetailed[] = [
    { field: 'invoiceNumber', headerName: 'Número de factura', minWidth: 180},
    { field: 'healthServiceProviderCode', headerName: 'Código de prestador de Salud' , minWidth: 210},
    { field: 'identificationType', headerName: 'Tipo de Documento' , minWidth: 160},
    { field: 'identificationNumber', headerName: 'Número de identificación' , minWidth: 180},
    { field: 'procedureDate', headerName: 'Fecha del procedimiento', minWidth: 180},
    { field: 'authorizationNumber', headerName: 'Número de autorización', minWidth: 180},
    { field: 'procedureCode', headerName: 'Código del procedimiento', minWidth: 180},
    { field: 'procedureCodeName', headerName: 'Nombre del procedimiento' , minWidth: 480},
    { field: 'procedureContext', headerName: 'Ámbito de realización del procedimiento' , minWidth: 230},
    { field: 'procedurePurpose', headerName: 'Finalidad del procedimiento' , minWidth: 180},
    { field: 'attendantStaff', headerName: 'Personal que atiende' , minWidth: 180},
    { field: 'primaryDiagnosis', headerName: 'Diagnóstico principal' , minWidth: 280},
    { field: 'relatedDiagnosis', headerName: 'Diagnóstico relacionado' , minWidth: 180},
    { field: 'complication', headerName: 'Complicación' , minWidth: 160},
    { field: 'procedureExecutionMethod', headerName: 'Forma de realización del acto quirúrgico' , minWidth: 250},
    { field: 'procedureValue', headerName: 'Valor del procedimiento' , minWidth: 190},
    { field: 'attentionStartDate', headerName: 'Fecha de inicio de la atención' , minWidth: 180},
    { field: 'mipresId', headerName: 'Id MIPRES', minWidth: 160},
    { field: 'admissionRoad', headerName: 'Vía de ingreso' , minWidth: 160},
    { field: 'serviceGroupModalityTecsal', headerName: 'Modalidad del grupo de servicios Tecsal' , minWidth: 190},
    { field: 'serviceGroup', headerName: 'Grupo del servicio' , minWidth: 190},
    { field: 'serviceCod', headerName: 'Código del servicio' , minWidth: 180},
    { field: 'collectionConcept', headerName: 'Concepto de recaudo' , minWidth: 180},
    { field: 'sharedFeeValue', headerName: 'Valor del pago moderadora' , minWidth: 180},
    { field: 'moderatorPaymentFevNumber', headerName: 'Número FEV pago moderador' , minWidth: 190},
]

export const contentTableRowRipsAT: typeContentTableRowDetailed[] = [
    { field: 'invoiceNumber', headerName: 'Número de factura', minWidth: 180},
    { field: 'healthServiceProviderCode', headerName: 'Código de prestador de Salud' , minWidth: 190},
    { field: 'identificationType', headerName: 'Tipo de Documento' , minWidth: 160},
    { field: 'identificationNumber', headerName: 'Número de identificación' , minWidth: 180},
    { field: 'authorizationNumber', headerName: 'Número de autorización', minWidth: 180},
    { field: 'serviceType', headerName: 'Tipo de servicio', minWidth: 160},
    { field: 'serviceCode', headerName: 'Código del servicio', minWidth: 180},
    { field: 'serviceName', headerName: 'Nombre del servicio', minWidth: 260},
    { field: 'quantity', headerName: 'Cantidad', minWidth: 120},
    { field: 'unitValue', headerName: 'Valor unitario del material e insumo', minWidth: 220},
    { field: 'totalValue', headerName: 'Valor total del material e insumo', minWidth: 220},
    { field: 'mipresId', headerName: 'Id MIPRES', minWidth: 160},
    { field: 'technologyAdministerAt', headerName: 'Fecha suministro de tecnología', minWidth: 160},
    { field: 'collectionConcept', headerName: 'Concepto recaudo', minWidth: 190},
    { field: 'sharedFeeValue', headerName: 'Valor del pago moderadora', minWidth: 190},
    { field: 'moderatorPaymentFevNumber', headerName: 'Número FEV pago moderador', minWidth: 190},
]


export const contentDataDocumentsRelated: typeContentTableRowDetailed[] = [
    { field: 'identificationNumber', headerName: 'Número documento', minWidth: 180},
    { field: 'identificationType', headerName: 'Tipo de Documento' , minWidth: 180},
    { field: 'dateDocument', headerName: 'Fecha del Documento' , minWidth: 190},
    { field: 'idCUDE', headerName: 'CUDE' , minWidth: 190},
    { field: 'totalValue', headerName: 'Valor Total', minWidth: 130},
    { field: 'state', headerName: 'Estado ', minWidth: 160},
    { field: 'actions', headerName: 'Acciones ', minWidth: 170},
]

export const contentDataSupports: typeContentTableRowDetailed[] = [
    { field: 'support', headerName: 'Soporte', minWidth: 190},
    { field: 'originalName', headerName: 'Nombre del soporte' , minWidth: 170},
    { field: 'contentType', headerName: 'Formato ', minWidth: 100},
    { field: 'createdAt', headerName: 'Fecha' , minWidth: 160},
    { field: 'status', headerName: 'Estado' , minWidth: 130},
    { field: 'createdBy', headerName: 'Usuario', minWidth: 160},
    { field: 'actions', headerName: 'Acciones ', minWidth: 160},
]

export const contentDataAuditDetails: typeContentAudtDetailed[] = [
    {"id":"userInfo", "tooltip":'Usted debe verificar:  Tipo de documento (CC, CE, TI, RC, NIUP, MS, AS, PT, DNI), nombre completo del usuario, dirección, teléfono y correo del usuario. Usted además debe verificar que los datos de la facturación coincidan con los datos de los soportes de atención', "label":"¿El usuario relacionado a esta factura cumple con toda la documentación?", "disable":false},
    {"id":"validateRight", "tooltip":'Usted debe verificar afiliación y base de datos en las plataformas: Adres, Sisbén, Migración Colombia, Victimas-Vivanto, siinformacap, EPS. Además, usted podrá tomar captura de pantalla de los hallazgos y anexarlos como soporte de auditoria.', "label":'¿ La Secretaria seccional de salud y protección social de Antioquia debe cubrir los gastos medicos del usuario relacionado? (Validación de derechos )', "disable":false},
    {"id":"adminInfo", "tooltip":'Usted debe verificar soportes adjuntos a la facturación como: documento identificación del usuario, soporte del SIRE, soporte de falta capacidad de pago, soporte de autorización de servicios, soporte de acción de cumplimiento, soporte de fallo de tutela,pago de cuota de recuperación,  entre otros.', "label":'¿La factura cumple con toda la información técnico administrativa?', "disable":false},
    {"id":"clinicHistory", "tooltip":'Usted debe verificar los soportes de atención médica, los soportes de enfermería, los soportes de aplicación de medicamentos, los soportes de paraclínicos, los soportes de traslados, los soportes de transfusiones, y demás soportes que se tengan que ver con la atención facturada', "label":'¿La historia clínica del paciente tiene toda la información requerida para realizar la auditoria médica?', "disable":false},
    {"id":"medicalAttention", "tooltip":'Usted debe verificar los soportes de las consultas realizadas al usuario, las interconsultas, las órdenes médicas, las remisiones y contra-remisiones, las fórmulas generadas, las solicitudes de procedimientos, los días y sitios de estancias, las atenciones de urgencias, entre otros.', "label":'¿La atención médica del paciente es coherente con la factura, la historia clínica y los RIPS de consultas, urgencias u hospitalización?', "disable":false},
    {"id":"process", "tooltip":'Usted debe verificar los soportes de exámenes diagnósticos, procedimientos diagnósticos, terapéuticos y quirúrgicos que estén asociados a la prestación facturada.', "label":'¿Los procedimientos realizados al paciente son coherentes con la factura, la historia clínica y los RIPS?', "disable":false, "isHidden": true},
    {"id":"medications", "tooltip":'Usted debe verificar la cantidad y el valor de los medicamentos facturados en general y los regulados que estén asociados a la atención facturada.', "label":'¿Los Medicamentos aplicados al paciente son coherentes con la factura, la historia clínica y los RIPS?', "disable":false, "isHidden": true},
    {"id":"otherServices", "tooltip":'Usted debe verificar si en la factura se presentan atenciones como centro día, domiciliarias, altas tempranas, programas especiales, devoluciones, entre otros que se encuentren asociados a la atención facturada.', "label":'¿Los otros servicios realizados al paciente son coherentes con la factura, la historia clínica y los RIPS?', "disable":false, "isHidden": true}
]

export const contentInvoicedItems: typeContentTableRowDetailed[] = [
    { headerName: 'Cantidad', field: 'lineQuantity', minWidth: 80, headerAlign: "center", align: "center"},
    { headerName: 'C\u00f3digo producto/servicio', field: 'itemCode', minWidth: 220, headerAlign: "center", align: "center" },
    { headerName: 'Detalle', field: 'itemDescription', minWidth: 480 },
    { headerName: 'Valor unitario', field: 'itemPriceValue', minWidth: 140, headerAlign: "right", align: "right", renderCell: renderParams => formatCurrency(renderParams.value) },
    { headerName: 'Valor Total', field: 'lineTotalValue', minWidth: 140, headerAlign: "right", align: "right", renderCell: renderParams => formatCurrency(renderParams.value)},
]

export const contentGridGlosas: typeContentTableRowDetailed[] = [
    { field: 'id', headerName: 'Id', minWidth: 60},
    { field: 'codeGlosa', headerName: 'Código' , minWidth: 120},
    { field: 'nameGlosa', headerName: 'Nombre de la Glosa' , minWidth: 200},
    { field: 'valueGlosa', headerName: 'Valor de la Glosa' , minWidth: 160},
    { field: 'justification', headerName: 'Justificación de la glosa', minWidth: 360},
    { field: 'statusGlosa', headerName: 'Estado' , minWidth: 160},
    { field: 'valueAccepted', headerName: 'Valor Aceptado' , minWidth: 160},
    { field: 'valueSupported', headerName: 'Valor Sustentado' , minWidth: 160},
    { field: 'valueNotAccepted', headerName: 'Valor no Aceptado' , minWidth: 160},
    { field: 'idUsuario', headerName: 'Acciones' , minWidth: 140},
]

export const contentGridPaymentAuthorization: typeContentTableRowDetailed[] = [
    { headerName: 'Consecutivo', field: 'id', minWidth: 120},
    { headerName: 'Factura de Referencia', field: 'invoiceReference' , minWidth: 180},
    { headerName: 'Fecha de Autorización', field: 'dateAuthorization' , minWidth: 180},
    { headerName: 'Valor de Autorización', field: 'valueAuthorization' , minWidth: 180},
    { headerName: 'Auditor que Autoriza', field: 'auditor', minWidth: 200},
    { headerName: 'Linea de pago', field: 'line' , minWidth: 160},
    { headerName: 'Referencia de Pago', field: 'referencePayment' , minWidth: 160},
    { headerName: 'Estado', field: 'state' , minWidth: 160},
    { headerName: 'Acciones', field: 'actions' , minWidth: 90},
]


export type typeContentTableRowDetailed = {
    field: string,
    headerName: string,
    minWidth: number,
    headerAlign?: GridAlignment,
    align?: GridAlignment,
    renderCell?: (params: GridRenderCellParams<any, any, any>) => React.ReactNode,
    gridSizeMd?: number,
    gridSizeSm?: number,
    gridSizeXs?: number,
}

export type typeContentAudtDetailed = {
    id: string,
    tooltip: string,
    isHidden?: boolean,
    label: string,
    disable: boolean
}

export const contentTableRowBalance: typeContentTableRowDetailed[] = [
    { field: 'name', headerName: 'Transacción' , minWidth: 160},
    { field: 'createdAt', headerName: 'Fecha' , minWidth: 140},
    { field: 'value', headerName: 'valor' , minWidth: 90},
    { field: 'balance', headerName: 'Balance' , minWidth: 90},
]