import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import Image from 'shared/assets/index';
import { RichTextEditor, Spinner } from 'shared/components';
import Swal from 'sweetalert2';
import { removeHTMLTagsFromText } from 'shared/utils';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { paymentsTransform } from 'shared/utils/helpers/paymentsTransform';
import { GET_ELECTRONIC_DOCS, GLOSSES_CONSOLIDATE } from 'shared/api';
import { postData } from 'shared/api/services/postdata';
import { getData } from 'shared/api/services';
import { patchData } from 'shared/api/services/patchData';
import styles from '../modal-reply-glosses/style/formReplyGlosses.module.scss';
import { createRoot } from 'react-dom/client';
import { SwalAlertContent } from '../SwalAlertContent';
import { activities, statesActivities } from 'shared/models/lineStates';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import dayjs from 'dayjs';
import { accesRoles, IGlosa } from 'shared';
import { useAuth } from 'shared/auth';

interface Props {
    glosas?: IGlosa[],
    selectedDocument?: string;
}

export const FormAuditGlosses = ({ glosas, selectedDocument }: Props) => {

    const { idDocument } = useParams();
    
    const dispatch = useDispatch<Dispatch>();
    const { valuesPaymentConsolidate, bill, billingState } = useSelector((state: RootState) => state.auditDetails);
    const { dataGeneralParameters } = useSelector((state: RootState) => state.invoiceData);
    const { content } = dataGeneralParameters[0];
    const { callGetDetailedDocument } = useDetailedConsultation();
    const formikRef = React.useRef<FormikProps<any>>(null);
    const [totalCaracteres, setTotalCaracteres] = useState(0);
    const [richTextValue, setRichTextValue] = useState<string | null>(' ');
    const [isLoading, setIsLoading] = useState(false);
    const [dateResultAccepted, setDateResultAccepted] = useState<string | undefined>('');
    const { authService } = useAuth();
    const userRoles = authService.getUserRoles();

    const isAudithor = userRoles.some(role => accesRoles.auditor.includes(role));
    const isHealthProviderRol = userRoles.some(role => accesRoles.healthProvider.includes(role));
    const isReadOnly = billingState.code !== activities.facturaGlosasRespondidas 

    const validationSchema = () => Yup.object().shape({
        acceptedConsolidatedValue: Yup.string().max(14).required(),
        sustainedConsolidatedValue: Yup.string().max(14).required(),
        consolidatedInNoAgreementValue: Yup.string().max(14).required(),
    });

    const valueGlossa = (value:string) => value ? Number(value.toString().replace(/\$/g, '').replace(/\./g, '').replace(',', '.')) : 0;

    const changeRichText = (richText: string) => {
        const cleanText = removeHTMLTagsFromText(richText);
        setTotalCaracteres(cleanText.length)
        setRichTextValue(cleanText.length < 1 ? '<p><br></p>' : richText);
    }

    const confirmConsolidateGlossa = (paramsGlosa: any) => {
        setIsLoading(true);
        patchData(`${GET_ELECTRONIC_DOCS}/${idDocument}/audit/consolidate`, { ...paramsGlosa, justValidate: false }).then((res) => {
            callGetDetailedDocument(idDocument || '');
            Swal.clickCancel();
            setIsLoading(false);
        }).finally(()=>{
            setIsLoading(false);
        })
    }

    const handleSubmit = (values:any ) => {
        const cleanText = removeHTMLTagsFromText(richTextValue!);
        const paramsGlosa = {
            electronicDocumentId: idDocument,
            acceptedConsolidatedValue: valueGlossa(values.acceptedConsolidatedValue),
            sustainedConsolidatedValue: valueGlossa(values.sustainedConsolidatedValue),
            consolidatedInNoAgreementValue: valueGlossa(values.consolidatedInNoAgreementValue),
        };

        const modalComponent = document.createElement("div");

        if (cleanText.length > 5000 || cleanText.length < 100) {
            return Swal.fire({
                title: "Respuesta a glosa es obligatorio, minimo 100 y máximo 5000 caracteres",
                icon: "error",
                timer: 5000,
            });
        }
        setIsLoading(true);
        saveRichTextOnBlur();
        patchData(`${GET_ELECTRONIC_DOCS}/${idDocument}/audit/consolidate`, { ...paramsGlosa, justValidate: true }).then((res) => {
            createRoot(modalComponent).render(<SwalAlertContent
                description={`¿Confirma que desea conciliar la Auditoria de la factura ${bill} ?`}
                highlightedText={`Recuerde que este resumen será enviada al prestador una vez el prestador acepte la consolidación de auditoria, la factura será autorizada para pagos.`}
                useCheck={true}
                checkText='Confirmo que deseo conciliar Auditoria de factura'
                onCancel={() => { Swal.clickCancel(); }}
                onConfirm={() => confirmConsolidateGlossa(paramsGlosa)}
                textBtn='Conciliar'
            />);
            Swal.fire({
                title: "¡Atención!",
                icon: "warning",
                html: modalComponent,
                showCancelButton: false,
                showConfirmButton: false,
            });
        }).finally(() => setIsLoading(false));
    }

    const handleOnBlur = (field: any, value: any) => {
        const payload = {
            initialValues: {
                acceptedConsolidatedValue: value.acceptedConsolidatedValue ?? "0",
                sustainedConsolidatedValue: value.sustainedConsolidatedValue ?? "0",
                consolidatedInNoAgreementValue: value.consolidatedInNoAgreementValue ?? "0",
            },
            paymentValues: valuesPaymentConsolidate?.paymentValues.map(pay => pay.map((x) => x.id === field ? { ...x, value: valueGlossa(value) } : x))
        }
        dispatch.auditDetails.setFieldsConsolidate(payload);
    }

    const saveRichTextOnBlur = () => {
        const dataReq = {
            auditResultDetail: richTextValue,
            noAgreement: false
        };
        postData(`${GLOSSES_CONSOLIDATE}/${idDocument || ''}`, dataReq);
    }

    useEffect(() => {
        setDateResultAccepted(content.fechaAceptacionResultado);
    }, [content.fechaAceptacionResultado])

    useEffect(() => {
        const id = selectedDocument || idDocument || '';

        getData(`${GLOSSES_CONSOLIDATE}/${id}`).then((res) => {
            setRichTextValue(res.auditResultDetail || "<p><br></p>");
        });

    }, []);

    const validateActivity = () =>  statesActivities.allowConsolidateTheInvoice.includes(billingState.code) && glosas?.every((glosa) => parseInt(glosa.glossStateDays!) >= 20) || statesActivities.allowConsolidateTheInvoice.includes(billingState.code)
    
    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    acceptedConsolidatedValue: valuesPaymentConsolidate?.initialValues?.acceptedConsolidatedValue?.value ?? '0',
                    sustainedConsolidatedValue: valuesPaymentConsolidate?.initialValues?.sustainedConsolidatedValue?.value ?? '0',
                    consolidatedInNoAgreementValue: valuesPaymentConsolidate?.initialValues?.consolidatedInNoAgreementValue?.value ?? '0',
                }}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnBlur={true}
                validateOnChange={false}
                validateOnMount={false}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form data-testid="form-justifyGlosa" className={styles.containerForm}>
                        <Grid container className={styles.contentForm}>
                            <div className={styles.containerTitleCard}>
                                <img src={Image.iconoDocumento} alt="" />
                                <Typography className={styles.titleCard}>Detalle de Resultado de Auditoría</Typography>
                            </div>
                            <Typography className={styles.contentInformation}>
                                Ingrese en este campo el detalle de los acuerdos y resultados obtenidos en la Auditoria, por favor sea  lo suficientemente especifico detallando los resultados obtenidos en todas glosas en los valores Aceptados, sustentados y No Aceptado.
                            </Typography>
                            <Grid
                                container
                                direction={"row"}
                            >
                                <Grid item xs={12} className={styles.contentItem}>
                                    <Box className={styles.textRichDetailsContainer}>
                                        <Typography variant='h6' className={styles.detailsLabel}>
                                            Respuesta Glosa
                                        </Typography>
                                        <Box className={styles.textRichContainer}>
                                            {
                                                richTextValue &&
                                                <RichTextEditor
                                                    onChange={changeRichText}
                                                    readOnly={ (!validateActivity() && isAudithor ) || isHealthProviderRol}
                                                    value={richTextValue}
                                                    onBlur={saveRichTextOnBlur}
                                                />
                                            }
                                        </Box>
                                        <span className='details'>{totalCaracteres}/{5000}</span>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={dateResultAccepted ? 3 : 4} className={styles.contentItemInput}>
                                    <label id='acceptedConsolidatedValueLabel' htmlFor={"acceptedConsolidatedValue"} className={styles.spanForLabel}>
                                        Valor consolidado Aceptado:
                                    </label>

                                    <Field name={"acceptedConsolidatedValue"} className={`${styles.inputItemValue}`}>
                                        {({ field }: FieldProps) => (
                                            <NumericFormat
                                                {...field}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                className={`${styles.inputItemValue} ${formik.errors.acceptedConsolidatedValue && styles.inputItemError}`}
                                                decimalScale={2}
                                                maxLength={13}
                                                value={formik.values.acceptedConsolidatedValue}
                                                placeholder='0'
                                                prefix="$"
                                                onBlur={() => handleOnBlur('acceptedConsolidatedValue', formik.values)}
                                                disabled={isReadOnly && isAudithor || isHealthProviderRol}
                                                id='acceptedConsolidatedValue'
                                            />
                                        )}
                                    </Field>
                                    <Box className={styles.contentSpan}>
                                        <span className='details'>
                                            {formik?.values?.["acceptedConsolidatedValue"]?.toString().replace(/[^\d.]/g, '').length || 0}
                                            /10
                                        </span>
                                    </Box>
                                    {
                                        formik.errors.acceptedConsolidatedValue &&
                                        <Grid item xs={12}>
                                            <span className={styles.textInputError}>Campo obligatorio</span>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={12} md={dateResultAccepted ? 3 : 4} className={styles.contentItemInput}>
                                    <label id='sustainedConsolidatedValueLabel' htmlFor={"sustainedConsolidatedValue"} className={styles.spanForLabel}>
                                        Valor consolidado sustentado:
                                    </label>

                                    <Field name={"sustainedConsolidatedValue"} className={styles.inputItemValue}>
                                        {({ field }: FieldProps) => (
                                            <NumericFormat
                                                {...field}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                className={`${styles.inputItemValue} ${formik.errors.sustainedConsolidatedValue && styles.inputItemError}`}
                                                decimalScale={2}
                                                maxLength={13}
                                                value={formik.values.sustainedConsolidatedValue}
                                                placeholder='0'
                                                prefix="$"
                                                onBlur={() => handleOnBlur('sustainedConsolidatedValue', formik.values)}
                                                disabled={isReadOnly && isAudithor || isHealthProviderRol}
                                                id='sustainedConsolidatedValue'
                                            />
                                        )}
                                    </Field>
                                    <Box className={styles.contentSpan}>
                                        <span className='details'>
                                            {formik?.values?.["sustainedConsolidatedValue"]?.toString().replace(/[^\d.]/g, '').length || 0}
                                            /10
                                        </span>
                                    </Box>
                                    {
                                        formik.errors.sustainedConsolidatedValue &&
                                        <Grid item xs={12}>
                                            <span className={styles.textInputError}>Campo obligatorio</span>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={12} md={dateResultAccepted ? 3 : 4} className={styles.contentItemInput}>
                                    <label id='consolidatedInNoAgreementValueLabel' htmlFor={"consolidatedInNoAgreementValue"} className={styles.spanForLabel}>
                                        Valor consolidado no aceptado
                                    </label>

                                    <Field name={"consolidatedInNoAgreementValue"} className={styles.inputItemValue}>
                                        {({ field }: FieldProps) => (
                                            <NumericFormat
                                                {...field}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                className={`${styles.inputItemValue} ${formik.errors.consolidatedInNoAgreementValue && styles.inputItemError}`}
                                                decimalScale={2}
                                                maxLength={13}
                                                value={formik.values.consolidatedInNoAgreementValue}
                                                placeholder='0'
                                                prefix="$"
                                                onBlur={() => handleOnBlur('consolidatedInNoAgreementValue', formik.values)}
                                                disabled={(!validateActivity() && isAudithor)  || isHealthProviderRol}
                                                id='consolidatedInNoAgreementValue'
                                            />
                                        )}
                                    </Field>
                                    <Box className={styles.contentSpan}>
                                        <span className='details'>
                                            {formik?.values?.["consolidatedInNoAgreementValue"]?.replace(/[^\d.]/g, '').length || 0}
                                            /10
                                        </span>
                                    </Box>
                                    {
                                        formik.errors.consolidatedInNoAgreementValue &&
                                        <Grid item xs={12}>
                                            <span className={styles.textInputError}>Campo obligatorio</span>
                                        </Grid>
                                    }
                                </Grid>
                                {
                                    !!dateResultAccepted &&
                                    <Grid item xs={12} md={3} className={styles.contentItem}>
                                        <label htmlFor={"dateResultAccepted"} className={styles.spanForLabel}>
                                            Fecha de Aceptación:
                                        </label>
                                        <Field
                                            className={styles.inputItemValue}
                                            id="number-agreement"
                                            variant="outlined"
                                            name='number-agreement'
                                            value={dayjs(dateResultAccepted).format('YYYY-MM-DD')}
                                            disabled
                                        />
                                    </Grid>
                                }

                            </Grid>
                            <Box data-testid='payments-container' className={styles.paymentsContainer}>
                                <div className={styles.containerTitleCard}>
                                    <img src={Image.iconoDocumento} alt="" />
                                    <Typography className={styles.titleCard}>Detalle de Autorización de Pagos</Typography>
                                </div>
                                <Typography className={styles.contentInformation}>
                                    A continuación, se detalla la información con la que será autorizado el pago de la factura {bill}, tenga en cuenta que una vez autorizado este pago, usted no podrá realizar ningún ajuste en la Auditoria.
                                </Typography>
                                {
                                    valuesPaymentConsolidate?.paymentValues.map((payment, i) =>
                                        <div key={i} className={styles.contentPayments}>
                                            {
                                                paymentsTransform(payment).map(({ name, value }, index) =>
                                                    <Box key={name} className={styles.payment}>
                                                        <span className={styles.paymentName}>{name}</span>
                                                        <span className={styles.paymentSeparator}></span>
                                                        <span className={`${styles.paymentValue}`}>{value}</span>
                                                    </Box>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </Box>
                            {
                               ( (validateActivity() && isAudithor) && !isHealthProviderRol || !isReadOnly) &&
                                    <Button variant='contained' className={styles.btnSubmitConsolide} type='submit'>Conciliar auditoría</Button>
                            }
                        </Grid>
                    </Form>
                )}
            </Formik>

            {(isLoading) && <Spinner />}
        </>
    )
}
