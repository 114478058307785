import { ITableElement } from "shared/components/interfaces/table.interface";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { formatCurrency } from "shared/utils";

export const columnsTableAutorizations= (): ITableElement<any>[] => {
    
  const columnsTable = [
    {
      field: 'consecutive',
      headerName: 'Consecutivo',
      sortable: 'consecutive',
      minWidth: 150,
      renderCell(params:any) {
        return (<div>{params.consecutive || ''}</div>)
      },
    },
    {
      field: 'authorizedAt',
      headerName: 'Fecha de autorización',
      sortable: 'created_at_payment',
      minWidth: 190,
      renderCell(params:any) {
        return (<div>{dayjs(new Date(params.authorizedAt)).format('DD MMMM YYYY HH:mm')}</div>)
      },
    },
    {
      field: 'authorizationValue',
      headerName: 'Valor de autorización',
      sortable: 'authorization_value',
      minWidth: 180,
      renderCell(params:any) {
        return (<div>{formatCurrency(params.authorizationValue) || ''}</div>)
      },
    },
    {
      field: 'authorizedBy',
      headerName: 'Auditor que autoriza',
      sortable: 'created_by_payment',
      minWidth: 180,
      renderCell(params:any) {
        return (<div>{params.authorizedBy || ''}</div>)
      },
    },
    {
      field: 'paymentLine',
      headerName: 'Linea de pago',
      sortable: 'payment_line_id_payment',
      minWidth: 160,
      renderCell(params:any) {
        return (<div>{params.paymentLine?.name || ''}</div>)
      },
    },
    {
      field: 'paymentReference',
      headerName: 'Referencia de pago',
      sortable: 'payment_reference',
      minWidth: 170,
      renderCell(params:any) {
        return (<div>{params.paymentReference || ''}</div>
        )
      },
    },
    {
      field: 'state',
      headerName: 'Estado Autorización de pago',
      sortable: 'state_id_payment',
      minWidth: 220,
      renderCell(params:any) {
        return (<div>{params.state.name || ''}</div>)
      },
    },
    {
      field: 'invoiceReference',
      headerName: 'Factura de Referencia',
      sortable: 'document_number',
      minWidth: 190,
      renderCell(params:any) {
        return (<div>{params.invoiceReference || ''}</div>)
      },
    },
    {
      field: 'electronicDocument',
      headerName: 'Fecha de factura',
      sortable: 'issued_at',
      minWidth: 190,
      renderCell(params:any) {
        return (<div>{params.electronicDocument?.issuedAt ? dayjs(new Date(params.electronicDocument?.issuedAt)).format('DD MMMM YYYY HH:mm') : ''}</div>
        )
      },
    },
    {
      field: 'provider',
      headerName: 'NIT',
      sortable: 'employer_id_number',
      minWidth: 160,
      renderCell(params:any) {
        return (<div>{params.provider?.employerIdNumber || ''}</div>)
        },
    },
    {
      field: 'provider',
      headerName: 'Razón social',
      sortable: 'hsp_name',
      minWidth: 240,
      renderCell(params:any) {
        return (<div>{params.provider?.name || ''}</div>)
        },
    },
    {
      field: 'electronicDocument',
      headerName: 'Total factura',
      sortable: 'invoice_value',
      minWidth: 160,
      renderCell(params:any) {
        return (<div>{formatCurrency(params.electronicDocument?.totalValue) || ''}</div>)
        },
    },
    {
      field: 'electronicDocument',
      headerName: 'Estado de la factura',
      sortable: 'invoice_state',
      minWidth: 180,
      renderCell(params:any) {
        return (<div>{params.invoiceState?.name || ''}</div>)
        },
    },
    {
      field: 'electronicDocument',
      headerName: 'Nro de radicado',
      sortable: 'filing_number',
      minWidth: 160,
      renderCell(params:any) {
        return (<div>{params.electronicDocument?.filingNumber || ''}</div>)
        },
    },
    {
      field: 'electronicDocument',
      headerName: 'Fecha de radicación',
      sortable: 'filing_at',
      minWidth: 190,
      renderCell(params:any) {
        return (<div>{params.electronicDocument?.filingNumberCreatedAt ? dayjs(new Date(params.electronicDocument?.filingNumberCreatedAt)).format('DD MMMM YYYY HH:mm') : ''}</div>)
        },
    },
  ];

  return columnsTable
} 
    