import React, { useState } from 'react'
import styles from './style/formReplyGlosses.module.scss';
import { Box, Button, Grid, Typography } from '@mui/material';
import Image from 'shared/assets/index';
import { consolidateGlossIsExpired, removeHTMLTagsFromText } from 'shared/utils';
import { RichTextEditor } from 'shared/components';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { Dispatch, IGlosa, TACIT_CONSOLIDATED } from 'shared';
import { SwalAlertContent } from '../SwalAlertContent';
import { createRoot } from 'react-dom/client';
import { postData } from 'shared/api/services/postdata';

interface prop{
    onCloseModal: () => void,
    idGlossa: number,
    glosas: IGlosa[],
    billName: string;
    idDocument: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    callGetDetailedDocument: (idDocument: string) => void;
}

export const ModalReplyGlossesNoResponse = ({ idGlossa, glosas, billName, onCloseModal, idDocument, setIsLoading, callGetDetailedDocument }:prop ) => {

    const dispatch = useDispatch<Dispatch>();
    const [richTextValue, setRichTextValue] = useState<string>(' ');
    const [totalCharacters, setTotalCharacters] = useState(0);
    
    const handleRichTextChange = (richText: string) => {
        const cleanText = removeHTMLTagsFromText(richText);
        setTotalCharacters(cleanText.length);
        setRichTextValue(cleanText.length < 1 ? '<p><br></p>' : richText);
    };
    
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        
        const cleanText = removeHTMLTagsFromText(richTextValue);
        dispatch.messageModal.setMessageModal({ hide: true });
        
        if(cleanText.length > 500 || cleanText.length < 100){
            return Swal.fire({
                title: "El campo consolidar debe tener mínimo 100 caracteres y máximo 500",
                icon: "error",
                timer: 5000,
            }).then(() => dispatch.messageModal.setMessageModal({ hide: false }));
        }

        const modalComponent = document.createElement("div");
        createRoot(modalComponent).render(
            <SwalAlertContent
                description={`¿Está seguro que desea consolidar la glosa de la factura ${billName} ?`}
                highlightedText=''
                useCheck={true}
                checkText='Confirmo que deseo consolidar Glosas'
                onCancel={() => {
                    Swal.clickCancel();
                    dispatch.messageModal.setMessageModal({ hide: false });
                }}
                onConfirm={confirmConsolidateGlossa}
                textBtn='Responder'
            />
        );

        Swal.fire({
            title: "¡Atención!",
            icon: "warning",
            html: modalComponent,
            showCancelButton: false,
            showConfirmButton: false,
        })?.then(() => dispatch.messageModal.setMessageModal({ hide: false }));
          
    }

    const confirmConsolidateGlossa = async () => {
        setIsLoading(true);

        const data = {
            documentId: parseInt(idDocument),
            tacitJustification: removeHTMLTagsFromText(richTextValue),
            answerGloss: "No acuerdo"
        }

        try {
            await postData(`${TACIT_CONSOLIDATED}/${idGlossa}`, data);
                dispatch.consolidateGlosas.getlistGlosasAsync(idDocument);
                callGetDetailedDocument(idDocument);
                onCloseModal();
                Swal.clickCancel();
            
        } catch (error) {
            console.error('Error consolidating glossa:', error);
        }finally {
            setIsLoading(false);
            dispatch.messageModal.cleanStateMessage()
        }
    };

    return (
        <form className={styles.containerForm} onSubmit={handleSubmit} data-testid='form_consolidate_glosses'>
            <Grid container className={`${styles.contentForm} ${styles['isMaxHeight']}`}>
                <div className={styles.containerTitleCard}>
                    <img src={Image.iconoResponder} alt="" />
                    <Typography className={styles.titleCard}>Consolidar Glosa</Typography>
                </div>
                <Typography 
                    className={styles.contentInformation} 
                    dangerouslySetInnerHTML={{ __html: consolidateGlossIsExpired }} 
                />

                <Grid container direction="row">
                    
                    <Grid item xs={12} className={styles.contentItem}>
                        <Box className={styles.textRichDetailsContainer}>
                            <Typography variant='h6' className={styles.detailsLabel}>
                                Justificar consolidación de glosa tácitamente - Obligatorio
                            </Typography>
                            <Box className={styles.textRichContainer}>
                            <RichTextEditor
                                onChange={handleRichTextChange}
                                value={richTextValue}
                                readOnly={false}
                            />
                            </Box>
                            <span className={styles.details} data-testid='total_characters'>{totalCharacters}/{500}</span>
                        </Box>
                    </Grid>
                    <Box className={styles.buttonsContainer}>
                        <Button
                            variant='contained'
                            className={styles.confirmButton}
                            data-testid='reply_glosses'
                            type='submit'
                        >
                            Conciliar
                        </Button>
                        <Button
                            variant='contained'
                            className={styles.cancelButton}
                            data-testid='cancel_reply_glosses'
                            onClick={onCloseModal}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}
