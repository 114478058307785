import React, { useEffect, useState } from 'react'
import Image from 'shared/assets/index'
import { Box, Button, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from 'shared/store/store'
import { PaymentDetailsForm, Spinner } from 'shared/components'
import { removeHTMLTagsFromText } from 'shared/utils'
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation'
import { useNavigate, useParams } from 'react-router'
import { getData } from 'shared/api/services'
import { DOCUMENT_GLOSSAS, GET_ELECTRONIC_DOCS, PAYMENT_AUTHORIZATION } from 'shared/api'
import { patchPaymentAuthorization } from 'shared/api/services/patchPaymentAuthorization'
import { activities } from 'shared/models/lineStates'
import { SwalAlertContent } from 'pages/detailed-consultation/components'
import { valuesPayment } from 'shared/models/store/IAuditDetails'
import Swal from 'sweetalert2'
import styles from '../styles/formAuthorize.module.scss'
import { apiInstance } from 'shared/api/config/apiInstance'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const FormAuthorizePayments = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch<Dispatch>();
    const { idDocument } = useParams();
    const { paymentInformation, bill, billingState } = useSelector((state: RootState) => state.auditDetails);
    const { glosas, glossesTotalValue } = useSelector((state: RootState) => state.consolidateGlosas);
    const { callGetDetailedDocument } = useDetailedConsultation();
    const [isLoading, setIsLoading] = useState(false);
    const [richTextIsChanged, setRichTextIsChanged] = useState(false);
    const [richText, setRichText] = useState('');
    const [data, setData] = useState<any>('');
    const minLength = 100;


    const disableConfirmationButton = (): boolean => { 
        return glosas.length < 1 || data.trim().length < minLength
    }
    const currentAuthorizationValue = paymentInformation?.find(value => value.name === valuesPayment.totalAuthotizate)?.value.toLocaleString("es-CO");

    const onChangeRichText = (richTextProp: string) => {
        const cleanText = removeHTMLTagsFromText(richTextProp);
        setRichTextIsChanged(true);
        
        cleanText.trim() === '' ?
            setData(' ')
            : setData(cleanText);
        setRichText(richTextProp);
    };

    const saveAuditDetailsOnChange = () => {
        const dataReq= {
            glossesGeneralDetail: richText
        }
        richTextIsChanged && 
            patchPaymentAuthorization(idDocument || '', dataReq);
    };
    
    const handleCancelGlossas = ()=>{
        navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}?option=5`)
    }

    const glossarAuthorizePayment = () => {        
        
        if (!idDocument) return;
        
        setIsLoading(true);
        apiInstance.patch(`${DOCUMENT_GLOSSAS}/${idDocument}`).then(()=>{
            setIsLoading(false);
            dispatch.messageModal.cleanStateMessage();
            dispatch.auditDetails.setReadOnly(true);
            dispatch.consolidateGlosas.patchGlosasAsync(idDocument);
            dispatch.electronicDocuments.getElectronicDocuments({states:"", id: idDocument}, `${GET_ELECTRONIC_DOCS}/page`).then(()=>{
                callGetDetailedDocument(idDocument || '');
                navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}`);
                Swal.clickCancel();
            })
        }).finally(()=>{
            setIsLoading(false);
        });

    }

    const onGlossar = () => {
        const cleanText = removeHTMLTagsFromText(richText);
        saveAuditDetailsOnChange(); 
        setRichTextIsChanged(false);
        if(cleanText.length > 5000){
            dispatch.messageModal.setMessageModal({
                show: true, 
                title: "Atención",
                size: 'small',
                description: (
                  <div className={styles.contentDescriptionModal} data-testid='content_description'>
                    <HighlightOffIcon className={styles.iconButton}/>
                    <Typography>
                        Debe diligenciar el detalle general de glosas, máximo 5000 caracteres.
                    </Typography>
                  </div>
                ),
                onClose() {
                    dispatch.messageModal.cleanStateMessage()
                },
                onOk(){
                    dispatch.messageModal.cleanStateMessage()
                },
                OkTitle: 'Aceptar',
            })
            return;
        }

        dispatch.messageModal.setMessageModal({
            show: true, 
            title: "Atención",
            size: 'medium',
            description: (
                <div className={styles.contentDescriptionModal} data-testid='content_description'>
                    <ErrorOutlineIcon className={`${styles.iconButton} ${styles.iconButtonWarning}`}/>
                    <SwalAlertContent
                        description={`¿Está seguro que desea crear ${glosas.length} glosa(s) relacionada(s) a la factura ${bill} por un valor de $${glossesTotalValue?.toLocaleString("es-CO")} y autorizar para pagos la suma de $${currentAuthorizationValue} pesos?`}
                        highlightedText='Recuerde que esta factura será autorizada para pagos y una vez autorizada no se podrá cambiar este valor'
                        useCheck
                        checkText='Confirmo que quiero Crear las Glosas y autorizar el pago'
                        onCancel={() => { dispatch.messageModal.cleanStateMessage() }}
                        onConfirm={glossarAuthorizePayment}
                        textBtn='Glosar'
                    />
                  </div>
            ),
            onClose() {
                dispatch.messageModal.cleanStateMessage()
            },
        })
    }

    useEffect(() => {
        billingState.code &&
            ![activities.AsignadaParaAuditoria].includes(billingState.code) && 
                navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingState])
    

    useEffect(() => {
        !paymentInformation?.length && callGetDetailedDocument(idDocument || '')
        getData(`${PAYMENT_AUTHORIZATION}/${idDocument}`).then((res)=>{
            setData(res.glossesGeneralDetail || ' ')
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <div className={styles.containerAuthorizePayments}
        data-testid='form_authorize'
    >
        {
            !!data &&
                <>
                    <PaymentDetailsForm
                        title='Detalle de Autorización de Pagos'
                        description={` A continuación, se detalla la información con la que será autorizado el pago de la factura ${bill}, tenga en cuenta que una vez autorizado este pago, usted no podrá realizar ningún ajuste en la Auditoria.`}
                        image={Image.iconoGlosanoacuerdo}
                        maxLength={5000}
                        richTextLabel='Detalle  General de las Glosas:'
                        onChangeRichText={onChangeRichText}
                        onBlur={() => { saveAuditDetailsOnChange(); setRichTextIsChanged(false); }}
                        dataRichText={data}
                    />
                    <Box className={styles.buttonsContainer}>
                        <Button
                            variant='contained'
                            className={styles.confirmButton}
                            data-testid='authorize_payment_button'
                            disabled={disableConfirmationButton()}
                            onClick={onGlossar}
                        >
                            GLOSAR FACTURA
                        </Button>
                        <Button
                            variant='contained'
                            className={styles.cancelButton}
                            data-testid='cancel_authorize_payment_button'
                            onClick={handleCancelGlossas}
                        >
                            Cancelar
                        </Button>
                    </Box>
                
                </>
        }
        {isLoading && <Spinner/>}
    </div>
  )
}
