
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip, Typography } from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { StyledBadge } from 'shared/utils';
import CommentIcon from '@mui/icons-material/Comment';
import style from '../styles/_acordionComponent.module.scss'
import { Comments } from '../comments/Comments';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AcordionComponent = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const comments =  useSelector((state: RootState) => state.comments);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => { 
        setExpanded(isExpanded ? panel : false);
    };

  return (

    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={style.accordionContent}>
        <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            expandIcon={<ExpandMoreIcon className={style.expandIcon}/>}
        >
            <section className={`${style.acordionHeader}`}>
                <IconButton 
                    className={`${style.commentsButton}`} 
                    disableRipple
                    data-testid='open_comments'
                >
                    <StyledBadge badgeContent={comments.comments.length} color="primary" >
                        <CommentIcon />
                    </StyledBadge>
                </IconButton>
                <Typography className={style.acordionTitle}>
                    Comentarios
                </Typography>
            </section>
        </AccordionSummary>
        <AccordionDetails>
            <Comments comments={comments.comments}/>
        </AccordionDetails>
    </Accordion>

  )
}
