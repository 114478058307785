import { ILinkDocument } from "shared/models/";

export const linkDocAdapter = (data: ILinkDocument) => ({
  id: data.id,
  link: data.link,
  originalName: data.originalName,
  nameNewsRips: null
});
export const linkDocAdapterResolution = (data: ILinkDocument) => ({
  id: data.id,
  link: data.link,
  originalName: null,
  nameNewsRips: data.originalName
});
