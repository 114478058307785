import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

const theme = createTheme({
	palette: {
		primary: {
			main: 'rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)',
		},
		secondary: {
			main: 'rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b),0.5)',
		},
		success: {
			main: '#ffffff',
		},
		error: {
			main: red.A400,
		},
		common:{
			black: '#1f1f1f'
		},
		action: {
			hover: '#f7f7f7'
		}
	},
	components: {
		MuiButton:{
			variants:[
				{
					props: { variant: 'contained' },
					style: {
					  textTransform: 'none',
					  background:'rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)',
					  color: 'white',
					  fontSize: 14,
					},
				},
			],
		},
		MuiModal:{
			variants:[
				{
					props: {},
					style: { zIndex: 1302 },
				},
			],
		},
		MuiInputBase:{
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						backgroundColor: '#ebebeb99',
					}
				}
			}
		}
	},
	typography: {
		button: {
			height: 'auto',
			textTransform: 'none',
			width: 'auto',
			borderRadius: '5px',
		},
		allVariants: {
			fontFamily: 'Poppins',
		},
		fontFamily: 'Poppins',
		fontSize: 14,
		htmlFontSize: 14,
		body2: {
			color: 'rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1)',
		}
	},
});

export default theme;
