import { createModel } from "@rematch/core"
import { RootModel } from "../rootModel"
import { IAuditDetails, valuesPayment } from "shared/models/store/IAuditDetails"

export const auditDetails = createModel<RootModel>()({
    state: {
      billingState:{code:'',name:''},
      paymentInformation: [],
      bill: '',
      totalValue: 0,
      readOnly: false,
      glossesGeneralDetailStatus: '',
      valuesPaymentConsolidate:{
        initialValues:{},
        paymentValues:[]
      },
      provider: {
        code: '',
        name: ''
      },
      activityStatus:{
        id: '',
        code: '',
        name: ''
      },
      resolution: ''
    } as IAuditDetails,
    reducers: {
      addDetails( state, payload: IAuditDetails ){
          return{
            ...state,
            billingState: payload.billingState,
            bill: payload.bill,
            totalValue: payload.totalValue,
            glossesGeneralDetailStatus: payload.glossesGeneralDetailStatus,
            provider: {
              name: payload.provider.name,
              code: payload.provider.code
            },
            paymentInformation: [
              {
                name: valuesPayment.totalNeto,
                value: payload.totalValue
              },
              {
                name: valuesPayment.totalNotasDebito,
                value: payload.paymentValues?.debitTotalValue
              },
              {
                name: valuesPayment.totalNotasCredito,
                value: payload.paymentValues?.creditTotalValue 
              },
              {
                name: valuesPayment.totalGlossas,
                value: payload.paymentValues?.glossesTotalValue
              },
              {
                name: valuesPayment.totalAuthotizate,
                value: payload.paymentValues?.paymentAuthorizationCurrentValue 
              },
            ],
            valuesPaymentConsolidate:{
              initialValues: {
                acceptedConsolidatedValue: {
                  name: valuesPayment.valorConsolidado,
                  value: payload.paymentValues?.acceptedConsolidatedValue.toString()
                },
                consolidatedInNoAgreementValue: {
                  name: valuesPayment.valorConsolidadoNoAcuerdo,
                  value: payload.paymentValues?.consolidatedInNoAgreementValue.toString()
                },
                sustainedConsolidatedValue: {
                  name: valuesPayment.valorConsolidadoSustentado,
                  value: payload.paymentValues?.sustainedConsolidatedValue.toString()
                }
              },
              paymentValues: [
                [
                  {
                    name: valuesPayment.totalNeto,
                    value: payload.totalValue
                  },
                  {
                    name: valuesPayment.totalNotasDebito,
                    value: payload.paymentValues?.debitTotalValue
                  },
                  {
                    name: valuesPayment.totalNotasCredito,
                    value: payload.paymentValues?.creditTotalValue 
                  },
                  {
                    name: valuesPayment.totalGlossas,
                    value: payload.paymentValues?.glossesTotalValue
                  },
                  {
                    name: valuesPayment.valorInicialAutorizado,
                    value: payload.paymentValues?.paymentAuthorizationTotalValue 
                  },
                ],
                [
                  {
                    name: valuesPayment.valorConsolidado,
                    value: payload.paymentValues?.acceptedConsolidatedValue,
                    id: 'acceptedConsolidatedValue'
                  },
                  {
                    name: valuesPayment.valorConsolidadoSustentado,
                    value: payload.paymentValues?.sustainedConsolidatedValue,
                    id: 'sustainedConsolidatedValue'
                  },
                  {
                    name: valuesPayment.valorConsolidadoNoAcuerdo,
                    value: payload.paymentValues?.consolidatedInNoAgreementValue,
                    id: 'consolidatedInNoAgreementValue'
                  },
                ],
                [
                  {
                    name: valuesPayment.nuevoValorAutorizado,
                    value: payload.paymentValues?.sustainedConsolidatedValue,
                    id: 'sustainedConsolidatedValue'
                  }
                ]
              ]
            },
            activityStatus: payload.activityStatus,
            resolution: payload.resolution,
            documentTotals: payload.documentTotals
          }
      },
      setFieldsConsolidate(state, payload){
        return {
          ...state,
          valuesPaymentConsolidate: {
            initialValues: payload.initialValues,
            paymentValues: payload.paymentValues
          }
        }
      },
      setReadOnly(state, payload: boolean) {
        return {
          ...state,
          readOnly: payload
        }
      },
      setCleanBillingState(state){
        return{
          ...state,
          billingState : {code:'',name:''},
        }
      }
    },
  })