import React, { useEffect } from 'react';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTabAuthorizatePayments } from '../hooks/useTabAuthorizatePayments';
import { useParams } from 'react-router-dom';
import { IContentTabPaymentAuthorization } from '../Models/IContentTabs';
import { RouterGuard, Spinner } from 'shared/components';
import { accesRoles } from 'shared/models';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styles from '../styles/tabAuthotizatePayments.module.scss';
import { ModalDocumentsPdf } from 'shared/components/ModalDocuments.pdf';
import { useOpenPdf } from 'shared/hooks/useOpenPdf';
import { POST_PAYMENT_AUTHORIZATION_DETAILS } from 'shared';

export const TabAuthorizatePayments = () => {

    const { idDocument } = useParams();
    const {callDataPaymentAuthorization, dataPayments} = useTabAuthorizatePayments();
    const {documentPdf, openIframePdf, isLoading, callServicePdf, closeModalPdf} = useOpenPdf();
    
    const viewPayment = (id:any) =>{
        const url = `${POST_PAYMENT_AUTHORIZATION_DETAILS}/${idDocument}/download/${id}`
        callServicePdf(url)
    }

    useEffect(() => {
        callDataPaymentAuthorization(idDocument || '');
    }, [])

  return (
    <div className={styles.containerAutorizate}>
        {
            dataPayments.content.length > 0  ?
            <Box>
                <Box className='containerTitleCardTabs' paddingLeft={'0 !important'}>
                    <img src={dataPayments.icon} alt="autorizacion" />
                    <Typography data-testid='payments-title'>{dataPayments.title}</Typography>
                </Box>
                <Typography className={styles.contentInformation}>
                    {dataPayments.description}
                </Typography>
                <TableContainer
                    className={`${styles.gridGlosasResults} ${styles.gridPayments}`}
                    data-testid="grid-payments"
                >
                    <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                    >
                        <TableHead>
                            <TableRow className={styles.tableHeader}>
                                {dataPayments.columns.map((col: any, i: number) => (
                                <TableCell
                                    key={i}
                                    className={styles.tableCell}
                                    style={{ minWidth: col.minWidth }}
                                >
                                    {col.headerName}
                                </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody >
                        {dataPayments.content.map((col: IContentTabPaymentAuthorization, i: number) => (
                            <TableRow key={i} data-testid={"table-results-body"}>
                                <TableCell className={styles.tableCell}>
                                    {col.consecutive}
                                </TableCell>
                                <TableCell className={styles.tableCell}>
                                    {col.invoiceReference}
                                </TableCell>
                                <TableCell className={styles.tableCell} data-testid="payments-date">
                                    {col.dateAuthorization}
                                </TableCell>
                                <TableCell className={styles.tableCell}>
                                    ${
                                        Number(col.valueAuthorization).toLocaleString("es-CO")
                                    }
                                </TableCell>
                                <TableCell className={styles.tableCell} data-testid="payments-auditor">
                                    {col.auditor}
                                </TableCell>
                                <TableCell className={styles.tableCell}>
                                    {col.line}
                                </TableCell>
                                <TableCell className={styles.tableCell}>
                                    {col.referencePayment}
                                </TableCell>
                                <TableCell className={styles.tableCell}>
                                    {col.state}
                                </TableCell>

                                <TableCell className={styles.tableCell}>
                                    {
                                        <RouterGuard roles={accesRoles.reader} type='component'>
                                            <IconButton
                                                aria-label="view"
                                                data-testid="view-payment"
                                                onClick={()=> viewPayment(col.id)}
                                            >
                                                <VisibilityIcon/>
                                            </IconButton>
                                        </RouterGuard>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>

                    </Table>
                
                </TableContainer>
            </Box>
            : (
                <TableContainer
                    className={styles.gridGlosasResultsNotResults}
                    data-testid="grid-glosas"
                    >
                    <Box className={styles.containerNotResults} data-testid="title-not-results">Aun no se ha cargado la información correspondiente de esta sección</Box>
                </TableContainer>
            )
        }

    { openIframePdf && <ModalDocumentsPdf document={documentPdf.pdfBase64} openIframePdf={openIframePdf} handleOpenIframePdf={closeModalPdf}/> }
    {(isLoading) && <Spinner/>}

    </div>
  )
}
