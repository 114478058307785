import { Fragment, useEffect } from 'react'
import { Box, Button, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { DeleteOutline, Info } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { Spinner } from 'shared/components/Spinner';
import { Dispatch } from 'shared/store/store';
import { GET_DATA_SUPPORT_CONTAINER} from 'shared/api';
import styles from '../styles/modalRipsSoportes.module.scss';
import { apiInstance } from 'shared/api/config/apiInstance';
import { ILoadSupports } from '../model/ILoadSupports';
import { useLoadSupports } from '../hooks/useLoadSupports';
import { ModalLoadSupports } from './ModalLoadSupports';
import { textWithInstructions } from '../../../shared/utils/helpers/textWithInstructions';
import { useCheckRoleAccess } from 'shared/hooks/useCheckRoleAccess';
import { accesRoles } from 'shared';
import { EResolutionDocument, EResponseActivityStatus } from 'shared/constants/api.enum';
import { useNavigate } from 'react-router-dom';
import iconos from 'shared/assets';

export const LoadSupports = ({idDoc, isHadleSubmit, setIsHadleSubmit}: ILoadSupports) => {

  const navigate = useNavigate();

  const {
    errorFileSize, handleChangeFile, errorFileType, handleOnclickUpload, removeFile, handleChangeOpenModal,
    hasErrors, loading, typeAccept, maxLength, supports, openModal, activityStatus, resolution
  } = useLoadSupports({ idDoc,isHadleSubmit, setIsHadleSubmit, navigate })

  const { checkRoles } = useCheckRoleAccess();

  const dispatch = useDispatch<Dispatch>();

  const TitleTooltip = ({ id }: any) => {
    const textInstructions = textWithInstructions(id);
    return (
      <section className={styles.sectionTooltip}>
        <Typography data-testid='title_tooltip'> Se permite archivo de hasta <b>{maxLength}MB</b></Typography>
        <Typography>Tipos de archivo permitidos: 
          {typeAccept.map((type:string, i:number) => <b key={i}> {type}. </b>)}
        </Typography>
        {
          checkRoles(accesRoles.healthProvider) && 
            <Typography>{ textInstructions }</Typography>
        }
      </section>
    )
  }

  const validateResolutionAndFail = ()=> {
    if(activityStatus?.name !== EResponseActivityStatus.FAIL){
      return true
    }else if(resolution === EResolutionDocument.r3374){
      return true
    }
    return false;
  }

  useEffect(() => {
    if (isHadleSubmit && validateResolutionAndFail())  {
      handleOnclickUpload?.();
      setIsHadleSubmit(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isHadleSubmit])
  
  useEffect(() => {
    async function fetchData() {
      const response = await apiInstance.get(GET_DATA_SUPPORT_CONTAINER);
      dispatch.linkSupports.addSupports(response.data);
    }
    supports?.length === 0 && fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    openModal && dispatch.messageModal.setMessageModal({
      show: true,
      title: 'Carga de soportes masivos',
      description:(
        <ModalLoadSupports 
          isHadleSubmit={isHadleSubmit} 
          supports={supports} 
          setIsHadleSubmit={setIsHadleSubmit} 
          onClose={()=> {
            handleChangeOpenModal(false);
            dispatch.messageModal.cleanStateMessage();
          }} 
          id={idDoc} 
          navigate={navigate} />),
      onClose() {
        handleChangeOpenModal(false);
        dispatch.messageModal.cleanStateMessage();
      },
      size:'biggest'
    })
  },[openModal]);

  return (
    <Fragment>
      <Grid container className={styles.containerGrid} data-testid='container_load_support'>
        <Button 
          className={styles.btnModal}
          onClick={()=> handleChangeOpenModal(true)}
          data-testid='btn_load_support'
        >
          Clic aquí para cargar soportes Masivamente
        </Button>
        {
          supports && supports.map(( doc, index )=>(
            <Grid item xs={12} sm={6} md={6} lg={4}  className={styles.containerSupports} key={index}>
                <Box display='flex' gap={1} alignItems='center' data-testid={`container_label_${index}`}>
                  <Tooltip title={ <TitleTooltip id={doc.id} /> } >
                    <Info fontSize='small' color="primary" data-testid={`tooltip_${index}`} />
                  </Tooltip>
                  <Typography className={`${styles.textLabel} ${doc.name.includes('(Obligatorio)') && 'text-required'}`} data-testid={`label_name_${index}`}>{ doc.name }</Typography>
                </Box>
                <div className={styles.divContentModal}>
                  <FileUploader
                    types={typeAccept}
                    maxSize={maxLength}
                    handleChange={(e:any)=> handleChangeFile(e, doc.id)}
                    hoverTitle={' '}
                    onTypeError={ errorFileType }
                    onSizeError={ errorFileSize }
                    fileOrFiles={doc.file}
                  >
                    <Box className={styles.contentFile} data-testid='btn_file_upload'>
                      <Box data-testid={`name-file-${index}`} className={`${styles.nameFile} ${hasErrors && doc.name.includes('(Obligatorio)') && styles.errorFile}`}>
                          { 
                            doc.file ? <span>{doc.originalName}</span> 
                              :( 
                                <div className={styles.contentDragDrop}>
                                  <img  src={iconos.iconoPDF} alt='iconoPDF'/>
                                  <div>Arrastrar el archivo a cargar </div>
                                </div>
                              )
                          }
                          <Box display='flex' gap={1}>
                            {
                              doc.file &&
                                <Box className={styles.contentFilesSelected}>
                                  <IconButton onClick={()=> removeFile(doc)} data-testid={`file-remove-${index}`}>
                                    <DeleteOutline/>
                                  </IconButton>
                                </Box>
                            }
                            <Link className={styles.fileDocument}><b> CARGAR</b></Link>
                          </Box>
                      </Box>
                    </Box>
                    
                  </FileUploader>
                </div>
                <Box display='flex' justifyContent='space-between'>
                  <span className={styles.spanError} data-testid={`message_error_${index}`}>
                    { hasErrors && doc.name.includes('(Obligatorio)') && 'Campo obligatorio' }
                  </span>
                </Box>
          </Grid>
          ))
        }
      </Grid>
      {(loading) && <Spinner/>}

    </Fragment>
  )
}
