import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout } from 'shared/utils';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, FormControl, InputLabel, MenuItem, Pagination, PaginationItem, Select, Typography } from '@mui/material';
import { SearchItems } from 'shared/components/paginator/SearchItems';
import { Spinner } from 'shared/components';
import { getDownloadReports } from 'shared/api/services/getDownloadReports';
import { debounceFunction } from 'shared/utils/helpers/debounceFunction';
import './styles/_reportPreview.scss'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

export const ReportPreview = () => {

  const params = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { columns, data, page, pageSize, totalItems, order, reportDefinition, rangeDate, size  } = useSelector((state: RootState) => state.reportSingle);
  const { open, text } = useSelector((state: RootState) => state.searcher);
  const { loading } = useSelector((rootState: RootState) => rootState.loading.models.reportSingle);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    open &&
    debounceFunction(() => {
      dispatch.reportSingle.getReportSingleAsync({id: params.idReport, params:`searchTerm=${text}&dateStart=${rangeDate?.initialDate}&dateEnd=${rangeDate?.finalDate}`})
        ?.then(()=> dispatch.reportSingle.updateReports({limit: pageSize, page:0, search:text}))
    });
  }, [text]);

  useEffect(() => {
    if(data.length <= 0 ){
      navigate(`/reportes`)
    }

    return () => {
      dispatch.reportSingle.clearState()
    }
  }, [])

  const handlePageChange = (pages: number, sizeRows: string) => {
    dispatch.reportSingle.getReportSingleAsync({
      id: params.idReport,
      params:`page=${pages}&size=${sizeRows}&searchTerm=${text}&orderBy=${order.field}:${order.sort}&dateStart=${rangeDate?.initialDate}&dateEnd=${rangeDate?.finalDate}`
    });
    dispatch.reportSingle.updateReports({ page: pages, limit: parseInt(sizeRows)});
  };

  const handleSortModelChange = (payload:any) => {
    const itemSort = payload[0]?.field.replace('#','%23') || '';

    dispatch.reportSingle.getReportSingleAsync({
      id: params.idReport,
      params:`page=${page+1}&size=${pageSize}&searchTerm=${text}&orderBy=${itemSort}:${payload[0]?.sort || ''}`
    });
    payload[0]?.field && dispatch.reportSingle.updateSort(payload[0]);
  };

  const downloadReports = ()=> {
    setIsLoading(true)
    getDownloadReports({
      id: Number(params.idReport),
      params: `${text}&dateStart=${rangeDate?.initialDate}&dateEnd=${rangeDate?.finalDate}`,
      text:"Reportes descargados con Exito"
    }).then(()=> setIsLoading(false))
    .catch((error)=> {
      setIsLoading(false)
    })
  }

  return (
    <Layout>
      <div className='reportsPreview' data-testid="component_report_preview">
        <Box className='headerGrid'>
          <Typography className='titleMain'>{reportDefinition.title}</Typography>
          <Box className='headerGridOptions'>
            <SearchItems data={data} placeholder='Buscar reporte' autocomplete={false} />
            <Button
              className='btnDownload'
              data-testid='btn_icono_dowloand_report'
              onClick={downloadReports}
            > Descargar</Button>
          </Box>
        </Box>
        <DataGrid
          className='dataGridCustom'
          columns={columns}
          disableColumnMenu={true}
          loading={loading}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(row:any) => row.$key}
          paginationMode='server'
          paginationModel={{pageSize, page}}
          pageSizeOptions={[10,25,50,100]}
          rows={data}
          rowCount={totalItems}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          hideFooter={true}
          slotProps={{  
            baseIconButton:{
              title: 'Ordenar'
            },
          }}
          data-testid='data_grid_preview'
        />
        {
          data.length > 0 &&
          <Box className='footerGridData' data-testid='box_paginacion'>
              <Box className='contenedorResultadosPorPaginaData'>
                <p>Resultados por página</p>
                <FormControl fullWidth className='containerSizeRowsData'>
                  <InputLabel id="select-size-label">Cantidad</InputLabel>
                  <Select
                    labelId="select-size-label"
                    id="select-size"
                    value={size}
                    label="Cantidad"
                    className='selectSizeRowsData'
                    onChange={(e) => handlePageChange(page, String(e.target.value))}
                    disabled={data.length === 0 || totalItems === 0}
                    data-testid='name-select'
                  >
                    <MenuItem value={10} data-testid='menu_item_10'>10</MenuItem>
                    <MenuItem value={25} data-testid='menu_item_25'>25</MenuItem>
                    <MenuItem value={50} data-testid='menu_item_50'>50</MenuItem>
                    <MenuItem value={100} data-testid='menu_item_100'>100</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {
                data &&
                <Pagination
                  className='paginadorData'
                  data-testid='action-pagination'
                  page={page}
                  count={Math.ceil(totalItems / size)}
                  siblingCount={0}
                  boundaryCount={1}
                  disabled={size > totalItems}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: () => <Box className='botonPaginadorData'><KeyboardArrowLeft /><p>Ant.</p></Box>,
                        next: () => <Box className='botonPaginadorData'><p>Sig.</p><KeyboardArrowRight/></Box>
                      }}
                      {...item}
                    
                    />
                  )}
                  onChange={(e, page) => { handlePageChange(page, String(size)) }}
                />
              }
          </Box>
        }
      </div>
      {(loading || isLoading) && <Spinner/>}
    </Layout>
  )
}
