import { Box, Button, Modal } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import styles from './styles/ModalDocumentPdf.module.scss';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot  } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useModalDocuments } from 'shared/hooks/useModalDocuments';

interface Props {
    document: any;
    openIframePdf: boolean;
    handleOpenIframePdf: ()=> void;
}

export const ModalDocumentsPdf = ({document, openIframePdf, handleOpenIframePdf}:Props) => {

    const [url, setUrl] = useState('');
    const { base64toBlob, defaultLayoutPluginInstance } = useModalDocuments();

    useEffect(() => {
        const blob = base64toBlob(document);
        setUrl(URL.createObjectURL(blob));
    }, [])
    
    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <>
                <Modal data-testid='iframe-documentos' open={openIframePdf} onClose={handleOpenIframePdf}>
                    <div id='iframeDocumentos' className={styles.documentModal}>
                        <Button onClick={handleOpenIframePdf} className={styles.btnCloseModal}>
                            <CloseFullscreenIcon/>
                        </Button>
                        <Box className={styles.contentDocument}>
                                <Viewer 
                                    fileUrl={url} 
                                    plugins={[defaultLayoutPluginInstance]}
                                />
                        </Box>
                    </div>
                </Modal>
            </>
        </Worker>
        
    )
}
