import React from 'react'
import { useAuth } from '../auth/oauh2-with-pkce/AuthContext';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ExitToApp } from '@mui/icons-material';
import { Dispatch } from '../store/store';
import { useDispatch } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography } from '@mui/material';
import styles from './styles/ButtonLogout.module.scss';

interface IProp{
  closeMenu?: () => void
}

export const ButtonLogout = ({ closeMenu }: IProp) => {
    const {authService} = useAuth();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = React.useState(false);
    const dispatch = useDispatch<Dispatch>(); 

    React.useEffect(() => {
      if(openModal){
        closeMenu?.()
        dispatch.messageModal.setMessageModal({
          show: true, 
          title: "Cerrar sesión",
          size: 'small',
          description: (
            <div className={styles.contentDescription} data-testid='content_description'>
              <ErrorOutlineIcon className={styles.iconButton}/>
              <Typography>
                ¿Estás seguro de salir?
              </Typography>
            </div>
          ),
          onClose() {
            setOpenModal(false);
            dispatch.messageModal.cleanStateMessage()
          },
          onCancel() {
            setOpenModal(false);
            dispatch.messageModal.cleanStateMessage()
          },
          onOk(){
            setOpenModal(false);
            dispatch.messageModal.cleanStateMessage()
            authService.logout();
          },
          OkTitle: 'Aceptar',
          cancelTitle: 'Cancelar'
        })
      }
    }, [authService, navigate, openModal])

  return (
    <Button
      className='fs-9'
      color="success" 
      variant='outlined' 
      startIcon={<ExitToApp/>}
      aria-label="btn-logout"
      onClick={()=>  setOpenModal(true)}
      data-testid='btn_logout'
      >
        Cerrar sesión
    </Button>
  )
}
