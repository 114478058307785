import { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useTabDocumentsRelated } from '../hooks/useTabDocumentsRelated';
import { Spinner } from 'shared/components';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styles from '../styles/tabDocumentsRelated.module.scss'
import { TooltipLinkToGlosses } from './TooltipLinkToGlosses';
import { formatCurrency } from 'shared/utils';
import dayjs from 'dayjs';

export const TabDocumentsRelated = () => {

    const { idDocument } = useParams();
    const { 
        isLoading, 
        documents,
        callGetDocumentsElectronics, 
        downloadDocument, 
        viewDocument, 
    } = useTabDocumentsRelated()

    useEffect(() => {
        callGetDocumentsElectronics(idDocument || "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>

            {
                documents ? (
                        <Paper className={styles.containerTableResults}>
                            <div className='containerTitleCardTabs'>
                                <img src={documents.icon} alt="" />
                                <Typography data-testid="title-documents-related">{documents.title}</Typography>
                            </div>
                            <TableContainer className={styles.tableResults} data-testid="table-documents-related">
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" data-testid="table-results">
                                    <TableHead>
                                        <TableRow className={styles.tablaHeader}>
                                            {
                                                documents.columns.map((col:any,i:number)=>(
                                                    <TableCell 
                                                        key={i} 
                                                        className={styles.tableCell}
                                                        style={{ minWidth: col.minWidth }}
                                                    >
                                                        { col.headerName }
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            documents.content.map((col:any,i:number)=>(
                                                <TableRow key={i} data-testid={"table-results-body"}>
                                                    <TableCell className={styles.tableCell}>{ col.identificationNumber }</TableCell>
                                                    <TableCell className={styles.tableCell}>{ col.identificationType }</TableCell>
                                                    <TableCell className={styles.tableCell}>{ dayjs(new Date(col.dateDocument)).format('DD MMMM YYYY HH:mm') }</TableCell>
                                                    <TableCell className={`${styles.tableCell} ellipsisCustom max-width-190`}>{ col.idCUDE }</TableCell>
                                                    <TableCell className={styles.tableCell}>{ formatCurrency(col.totalValue) || '' }</TableCell>
                                                    <TableCell className={styles.tableCell}>{ col.state }</TableCell>

                                                    <TableCell className={styles.tableCell}>
                                                        <Tooltip title={'descargar'} placement="top" arrow>
                                                            <span>
                                                                <IconButton disabled={!col.attachment} aria-label="download" data-testid="download-documents" onClick={()=>downloadDocument(col.id, col.attachment)}>
                                                                    <DownloadForOfflineIcon />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title={'Ver documento'} placement="top" arrow>
                                                            <span>
                                                                <IconButton disabled={!col.attachment} aria-label="view" data-testid="view-documents" onClick={()=>viewDocument(col.id, col.attachment)}>
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                        {
                                                            col.identificationType === 'Nota Crédito' && col.linkToGlosses &&
                                                            <TooltipLinkToGlosses />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )
                : (
                    <Box className={styles.containerNotResults} data-testid="title-not-results">Aún no se ha cargado la información correspondiente de esta sección</Box>
                )
            }
            {(isLoading) && <Spinner/>}
        </>

    )
}
