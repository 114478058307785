import { Layout } from 'shared/utils'
import { FormReadDocument } from './components/FormReadDocument'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import { Spinner } from 'shared/components/Spinner';
import { useEffect, useState } from 'react';
import { apiInstance } from 'shared/api/config/apiInstance';
import { GET_DATA_PROVIDER } from 'shared/api';
import { TableComponent } from 'shared/components/Table.component';
import { ComboBoxCustom, CustomTabs, RouterGuard } from 'shared/components';
import { useTableResults } from '../../shared/hooks/useTableResults';
import { accesRoles } from 'shared/models';
import { columnsTable, fieldsForm, columnsTableTransform } from './helper';
import { columnsCreditNote } from './helper/columnsCreditNote';
import { ModalDocumentsPdf } from 'shared/components/ModalDocuments.pdf';
import { useOpenPdf } from 'shared/hooks/useOpenPdf';
import { useModalLoadRipsFiles } from 'shared/hooks/Modals/useModalLoadRipsFiles';

export const ReadDocuments = () => {
  const dispatch = useDispatch<Dispatch>();
  const electronicDocuments = useSelector((state: RootState) => state.electronicDocuments);
  const optionsDropdowns = useSelector((state: RootState) => state.optionsDropdowns);
  const consolidateGlosas = useSelector((state: RootState) => state.consolidateGlosas); 
  const { loading } = useSelector((rootState: RootState) => rootState.loading.models.electronicDocuments);
  const { sumValuesAllValueNotAccept } = consolidateGlosas;
  const [ isBillValueNotAccept, setIsBillValueNotAccept ] = useState(false);
  const [dataProvider, setDataProvider] = useState(null);
  const [dataFields, setDataFields] = useState<any[]>([]);
  const [ isSearch, setIsSearch ] = useState(true);

  const {
    paramsUrl,
    rowSelected,
    dataMassiveAssigment,
    activateDropDown,
    actionModalAbortDE,
    actionModalResetAudit,
    handleSelectAllClick,
    handleChangeCheck,
    handleUserAssignment,
    linkToDian,
    handleMassiveAssignment,
    validateUserAuditor,
    validateEqualStates,
  } = useTableResults();

  const { documentPdf, openIframePdf, isLoading, closeModalPdf  } = useOpenPdf();
  const { showModal, isLoading: LoadingDocumentRips } = useModalLoadRipsFiles()

  const valuesResultsPages = [ 50, 100, 150, 200 ];

  const optionsTrazact = (id: string) => {
    return (
      <RouterGuard roles={accesRoles.usersSSSA} type="component">
        <ComboBoxCustom
          handleChange={handleMassiveAssignment}
          idsDoc={rowSelected}
          data={dataMassiveAssigment}
          placeholder='Asignar'
          isDisabled={activateDropDown || validateUserAuditor()}
          useButtonIcon
          idComboBoxCustom={id}
        />
      </RouterGuard>
    )
  }

  useEffect(() => {
    validateEqualStates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelected])

  useEffect(() => {
    async function fetchData() {
      const response = await apiInstance.get(GET_DATA_PROVIDER);
      setDataProvider(response.data)
    }
    fetchData();
  }, [])

  useEffect(() => {
    const fields = fieldsForm(optionsDropdowns)
    setDataFields(fields)
  }, [optionsDropdowns])

  useEffect(() => {
    const billNotAccept = sumValuesAllValueNotAccept <= 0;
    setIsBillValueNotAccept(!billNotAccept);
  }, [sumValuesAllValueNotAccept])

  useEffect(() => {
    dispatch.optionsDropdowns.getUsers();
    dispatch.optionsDropdowns.getTypesDocuments();
    dispatch.optionsDropdowns.getDepartments();
    dispatch.optionsDropdowns.getActivityStatus()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setValueTabs = ( electronicDocuments?.paramsUrl?.typeDocument && !isNaN( parseInt(electronicDocuments?.paramsUrl?.typeDocument) ) ) ? parseInt(electronicDocuments?.paramsUrl?.typeDocument) : 1;
  
  const getElectronicDocumentsTabs = (typeDocument: string) => {
    setIsSearch(false)
    dispatch.searcher.addText('');
    const {url, paramsUrl: paramsUrlElectonic} = electronicDocuments
    const newParams = { ...paramsUrlElectonic, typeDocument, filter: null }
    dispatch.electronicDocuments.getElectronicDocuments( newParams, url )
  }

  const callGetFileExcelToExport = () => {
    dispatch.electronicDocuments.getFileExcelToExport({
      urlByParams: electronicDocuments.url?.slice( electronicDocuments.url.indexOf('?') + 1 ),
      paramsUrl,
      text: "Archivo excel descargado"
    })
  }

  const component = (idTable: string) => {
    return (
      <TableComponent
        columns={ columnsTableTransform( columnsCreditNote( { onAction: showModal, dispatch } ) ) }
        valuesResultsPages={valuesResultsPages}
        downloadExcel={callGetFileExcelToExport}
        isDisabledButtonTraza={activateDropDown || validateUserAuditor()}
        isStyleActivity={false}
        idTable={idTable}
        isSearch={isSearch}
      />
    )
  }

  return (
    <Layout>
      {
        electronicDocuments.content ?
          <CustomTabs
            tabProps={
              [
                {
                  label: 'Facturas',
                  component:
                    <TableComponent
                      columns={ columnsTableTransform( columnsTable( { linkToDian, actionModalAbortDE, actionModalResetAudit, isBillValueNotAccept, paramsUrl }, handleUserAssignment, validateUserAuditor), ['filedAt']) }
                      valuesResultsPages={valuesResultsPages}
                      optionsTrazact={optionsTrazact}
                      rowSelected={rowSelected}
                      downloadExcel={callGetFileExcelToExport}
                      selectAllRows={handleSelectAllClick}
                      selectRow={handleChangeCheck}
                      isDisabledButtonTraza={activateDropDown || validateUserAuditor()}
                      isStyleActivity={true}
                      idTable='table-facturas'
                      isSearch={isSearch}
                    />,
                  title: 'Gestión de trazabilidad de factura',
                  callService(typeDocument){ 
                    getElectronicDocumentsTabs( String(typeDocument + 1) ) 
                  }
                },
                {
                  label: 'Notas Crédito',
                  component: component('table-notas-credito'),
                  title: 'Gestión de Notas Crédito',
                  callService(typeDocument){ 
                    getElectronicDocumentsTabs( String(typeDocument + 1) ) 
                  }
                },
                {
                  label: 'Notas Débito',
                  component: component('table-notas-debito'),
                  title: 'Gestión de Notas Débito',
                  callService(typeDocument){ 
                    getElectronicDocumentsTabs( String(typeDocument + 1) ) 
                  }
                }
              ]
            }
            hasStyleCustomTabs={true}
            valueTab={ setValueTabs - 1 }
          />
          : <FormReadDocument dataProvider={dataProvider} dataFields={dataFields}/>
      }

      { ( loading || isLoading || LoadingDocumentRips ) && <Spinner/> }
      { openIframePdf && <ModalDocumentsPdf document={documentPdf} openIframePdf={openIframePdf} handleOpenIframePdf={closeModalPdf}/> }
    </Layout>
  )
}
