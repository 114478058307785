
export const textWithInstructions = (idText: number): string => {

    const instructionsMap: { [key: number]: string } = {
        1270: 'Aquí debe cargar la factura en PDF y el detalle de cargos. Recuerde que archivo debe nombrarse con la siguiente estructura: NIT_NUMEROFACTURA. Por ejemplo: 99999999_FE238.pdf',
        1271: 'Aquí debe cargar los soportes de los datos clínicos de la atención (historia clínica, notas de enfermería, aplicación de transfusiones, quimioterapia, hemodiálisis, etc.) y Aquí también debe cargar los soportes administrativos relacionados con la atención (documento identificación del usuario, reporte de SIRE, carta de no capacidad de pago, soporte de certificado de atención, búsqueda en bases de datos, búsqueda en migración Colombia, hoja de ruta, autorización de servicios, etc.). Recuerde que archivo debe nombrarse con la siguiente estructura: HEV_NIT_NUMEROFACTURA. Por ejemplo: HEV_99999999_FE238.pdf',
        1272: 'Aquí debe cargar el soporte del resumen de atención. Recuerde que archivo debe nombrarse con la siguiente estructura: EPI_NIT_NUMEROFACTURA. Por ejemplo: EPI_99999999_FE238.pdf',
        1273: 'Aquí debe cargar los resultados de procedimientos diagnósticos (laboratorios, imagenología, ecografía, etc.). Recuerde que archivo debe nombrarse con la siguiente estructura: PDX_NIT_NUMEROFACTURA. Por ejemplo: PDX_99999999_FE238.pdf',
        1274: 'Aquí debe cargar las notas de procedimientos quirúrgicos realizadas y partograma. Recuerde que archivo debe nombrarse con la siguiente estructura: DQX_NIT_NUMEROFACTURA. Por ejemplo: DQX_99999999_FE238.pdf',
        1275: 'Aquí debe cargar los registros de anestesia de los procedimientos realizados al paciente. Recuerde que archivo debe nombrarse con la siguiente estructura: RAN_NIT_NUMEROFACTURA. Por ejemplo: RAN_99999999_FE238.pdf',  
        1276: 'Aquí debe cargar los recibos de los pagos realizados por el usuario (cuota de recuperación, copagos, cuotas moderadoras). Recuerde que archivo debe nombrarse con la siguiente estructura: CRC_NIT_NUMEROFACTURA. Por ejemplo: CRC_99999999_FE238.pdf',
        1277: 'Aquí debe cargar el soporte de la factura de compra del Materia de Osteosíntesis utilizado para el paciente. Recuerde que archivo debe nombrarse con la siguiente estructura: FMO_NIT_NUMEROFACTURA. Por ejemplo: FMO_99999999_FE238.pdf',
        1278: 'Aquí debe cargar el soporte de los traslados en ambulancia. Recuerde que archivo debe nombrarse con la siguiente estructura: TAP_NIT_NUMEROFACTURA. Por ejemplo: TAP_99999999_FE238.pdf',
        1279: 'Aquí debe cargar el soporte de los traslados realizados por el paciente (particulares). Recuerde que archivo debe nombrarse con la siguiente estructura: TNA_NIT_NUMEROFACTURA. Por ejemplo: TNA_99999999_FE238.pdf',
        1280: 'Aquí debe cargar el soporte y facturas de superación del tope de SOAT. Recuerde que archivo debe nombrarse con la siguiente estructura: FAT_NIT_NUMEROFACTURA. Por ejemplo: FAT_99999999_FE238.pdf',
        1281: 'Aquí debe cargar la formula medica y las ordenes medicas. Recuerde que archivo debe nombrarse con la siguiente estructura: OPF_NIT_NUMEROFACTURA. Por ejemplo: OPF_99999999_FE238.pdf',
        1282: 'Aquí debe cargar la lista de precios de la institución utilizada para la facturación del servicio. Recuerde que archivo debe nombrarse con la siguiente estructura: LDP_NIT_NUMEROFACTURA. Por ejemplo: LDP_99999999_FE238.pdf',
        1283: 'Aquí debe cargar los soportes de atención de urgencias. Recuerde que archivo debe nombrarse con la siguiente estructura: HAU_NIT_NUMEROFACTURA. Por ejemplo: HAU_99999999_FE238.pdf',
        1284: 'Aquí debe cargar el soporte de la atención por odontología. Recuerde que archivo debe nombrarse con la siguiente estructura: HAO_NIT_NUMEROFACTURA. Por ejemplo: HAO_99999999_FE238.pdf',
        1285: 'Aquí debe cargar el soporte de la aplicación de medicamentos. Recuerde que archivo debe nombrarse con la siguiente estructura: HAM_NIT_NUMEROFACTURA. Por ejemplo: HAM_99999999_FE238.pdf',
        1286: 'Aquí debe cargar el soporte de la solicitud de autorizaciones y otro documento que requiera.. Recuerde que archivo debe nombrarse con la siguiente estructura: PDE_NIT_NUMEROFACTURA. Por ejemplo: PDE_99999999_FE238.pdf'
    };

    return instructionsMap[idText] || '';
}

export const consolidateGlossIsExpired = `<b>La SSSA informa al Prestador de Servicios de Salud, que a las glosas formuladas en la presente factura le vencieron los términos de respuesta estipulados por Ley considerando entonces una ACEPTACION TACITA de las mismas.</b> <br />
Ley 1438 de 2011 en su articulo 57 establece los plazos perentorios para el tramite de glosas, así: “El prestador de servidos de salud deberá dar respuesta a las glosas presentadas por las entidades responsables del pago de servidos de salud, dentro de los Quince (15) días hábiles siguientes a su recepción, indicando su aceptación o justificando la no aceptación.”
Así mismo, el decreto 441 de 2022 en su Artículo 2.5.3.4.4.2 cita: “Aceptación de las glosas. La aceptación de las glosas por parte del prestador de servicios de salud ocurre de forma expresa cuando dicha entidad informa de ello a la entidad responsable de pago, o de forma tácita, si transcurridos los plazos previstos en el artículo 57 de la Ley 1438 de 2011, el prestador de servicios de salud no se pronuncia respecto de las glosas formuladas.`

export const consolidateGloss = `La respuesta a glosas se realizará conforme al Manual Único de Devoluciones, Glosas y Respuestas expedido por el Ministerio de Salud y Protección Social, el cual es de obligatoria adopción por los prestadores de servicios de salud según lo establecido en el artículo 57 de la Ley 1438 de 2011. <b> Recuerde que una vez termine la respuesta a la totalidad de las  glosas, éstas no podrán ser modificadas.</b>`
export const textReconciliationAuditResults = `El prestador de Servicios de Salud aprueba el resultado de la auditoría de la presente factura, previa verificación de los saldos que ella contiene (valores aceptados por IPS, valores sustentados-levantados por SSSA, y valores en NO Acuerdo) y se compromete a la gestión posterior de aquellos saldos que hubiesen quedado en No acuerdo. Con la presente aprobación se firma tácitamente el Acta de Conciliación de glosas por ambas partes.`
