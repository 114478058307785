
import { Box, Typography } from '@mui/material';
import { RESOLUTION_2275_2023 } from 'pages/read-documents/helper';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { apiInstance, Dispatch, GET_ELECTRONIC_DOCS, RootState } from 'shared';
import { CustomFileUploader } from 'shared/components';
import Swal from 'sweetalert2';
import { downloadDocument } from '../../utils/helpers/downloadDocument';
import { downloadsDescription, downloadsTitle } from 'shared/utils';

interface propuseModalLoadRipsFiles{
    title: string,
    idDocument: string,
    onAction?: (prevState: any) => void
}

export const useModalLoadRipsFiles = () => {

    const dispatch = useDispatch<Dispatch>();
    const { id, nameNewsRips } = useSelector((state: RootState) => state.linkDocument);
    const typeAccept = ["ZIP"];

    const [ resolution2275Document, setResolution2275Document ] = useState<any>(null);
    const [typeResolutionSet, setTypeResolutionSet] = useState<string>('');
    const [ propsModal, setPropsModal ] = useState<propuseModalLoadRipsFiles>({
        title: '',
        idDocument: ''
    });
    const [isLoading, setIsLoading] = useState(false)
    const [callShowModal, setCallShowModal] = useState(false)

    useEffect(() => {
        (nameNewsRips && callShowModal) && showModal({ title: propsModal.title, idDocument: propsModal.idDocument})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameNewsRips]); 

    const removeFile = () => {
        //Remove RESOLUTION_2275_2023
        setResolution2275Document(null);
        dispatch.linkDocument.cleanStateNameNewsRips();
    }

    const handleChangeFile = (file: any, typeResolution: string, idDocument: string) => {
        setTypeResolutionSet(typeResolution);
        setIsLoading(true);
        if (file && file.size < (5000000) && file.name.split('.').pop() === "zip" && typeResolution === RESOLUTION_2275_2023) {
            setResolution2275Document(file);
            getLink(file, typeResolution, idDocument)
        }
    };

    const getLink = async(file:any, typeResolution: string, idDocument: string ) => {
        dispatch.linkDocument.getLinkAsync({
          file: file, 
          url: `${GET_ELECTRONIC_DOCS}/${idDocument}/rips/generate-upload-link`,
          typeResolution
        })
        ?.then(() => setIsLoading(false))
        .catch((error) => setResolution2275Document(null) )
        .finally(()=> setCallShowModal(true));
      }

    const showModal = ({title,  idDocument, relatedInvoiceNumber}: any) => {
        propsModal.idDocument === '' && setPropsModal({title, idDocument})

        dispatch.messageModal.setMessageModal({
            show: true, 
            title: `Cargar RIPS ${title}`,
            size: 'medium',
            description: (
                <Box className='displayFlex column gap-10 padding-10'>
                    <Typography className='textCustom'> Factura de referencia: <b>{relatedInvoiceNumber}</b></Typography>
                    <Typography className='textCustom' data-testid='text_upload_2275'>Cargue RIPS Segun Resolución 2275 el <b> JSON y CUV dentro de un archivo en formato .ZIP </b></Typography>
                        <CustomFileUploader 
                            fileLength={5}
                            originalName={nameNewsRips}
                            typeAccept={typeAccept}
                            document={resolution2275Document}
                            handleChangeFile={(file)=>handleChangeFile(file, RESOLUTION_2275_2023, idDocument)}
                            removeFile={removeFile}
                        />
                </Box>
            ),
            onClose() { closeModal()},
            onCancel() { closeModal() },
            onOk() { validateDownload(id) },
            OkTitle: 'Cargar RIPS',
            cancelTitle: 'Cancelar',
        })
    }

    const validateDownload = (idLinkDocument: any) => {
        if(idLinkDocument && typeResolutionSet){ 
            postRips() 
            return
        }
        dispatch.messageModal.cleanStateMessage()
        showAlert('error', 'Debe cargar archivo RIPS')
    }

    const postRips = async() => {
        setIsLoading(true)

        await apiInstance.post(`${GET_ELECTRONIC_DOCS}/${propsModal.idDocument}/rips`, {
          attachmentId: id,
          typeResolution: typeResolutionSet
        })?.then((response) => {
            closeModal()
            validateResponse(response)
        })
        .catch((error) => removeFile())
        .finally(()=> {
            closeModal()
            setIsLoading(false)
        });
    }

    const closeModal = () => {
        dispatch.messageModal.cleanStateMessage()
        dispatch.linkDocument.cleanState();
        dispatch.linkDocument.cleanStateNameNewsRips();
    }

    const showAlert = (icono: 'error' | 'warning' | 'success', text: string) => {
        Swal.fire({
            title: text,
            icon: icono,
            timer: 5000,
            confirmButtonText: "Aceptar",
        });
    }

    const validateResponse = (responseRips: any) => {
        if(responseRips?.status === 206){
            downloadDocument(responseRips, downloadsTitle.errorRips, downloadsDescription.errorRips, 'error')
        } else {
            showAlert('success', 'Se realizó la carga con éxito')
        }
    }

    return{
        showModal,
        removeFile,
        isLoading
    }
}
