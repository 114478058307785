import React, { useEffect } from 'react'
import { Paper, Typography} from '@mui/material';
import { contentTableRowDetailed } from 'pages/detailed-consultation/helpers/contentTableRowDetailed';
import { useParams } from 'react-router-dom';
import { useTablaTrazabilidad } from 'pages/detailed-consultation/hooks/useTablaTrazabilidad';
import Images from 'shared/assets/index'
import { CustomPagination, Spinner } from 'shared/components';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import '../styles/_tableDetaildConsultation.scss'



export const TablaTrazabilidad = () => {
    
    const { idDocument } = useParams();
    const { callGetEventTrace, traceabilityDetails, isLoading } = useTablaTrazabilidad();

    useEffect(() => {
        callGetEventTrace(idDocument || "")
    },[])


  return (
    <Paper className={'containerTableResults'} data-testid='container_table_results'>
        <div className='containerTitleCardTabs'>
            <img src={Images.iconoCheck} alt="" />
            <Typography>Detalle de Trazabilidad de Factura</Typography>
        </div>
        <DataGrid
            rows={traceabilityDetails}
            columns={contentTableRowDetailed}
            className={'tableResultsData'}
            data-testid="datagrid-history"
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu={true}
            hideFooterSelectedRowCount={true}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
            pagination
            pageSizeOptions={[25,50,75,100]}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                },
            }}
            slots={{ 
                toolbar: GridToolbar, 
                pagination: CustomPagination, 
            }}
        />
        
        {(isLoading) && <Spinner/>}
    </Paper> 
  )
}
