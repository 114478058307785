import { ITableElement } from '../../../shared/components/interfaces/table.interface';
import { IRespDocuments } from '../../../shared/models/store/IElectronicDocuments';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'shared/utils';
import { MenuCustom } from 'shared/components/tabs/MenuCustom';
import { Tooltip } from '@mui/material';
import { Dispatch, EApplicationStatus } from 'shared';

interface propColumnsCreditNote{
  onAction?: (prevState: any) => void,
  dispatch?: Dispatch
}

export const columnsCreditNote = ( { onAction, dispatch  }: propColumnsCreditNote ): ITableElement<any>[] => {

  return [
    { 
      field: 'ToId',
      headerName: '',  
      minWidth: 30,
      renderCell(params: IRespDocuments){
        return(
          <Link to={`/consulta_documentos_electronicos/detalle_documento/${params.relatedInvoiceId}`} data-testid='link-detailed'
            onClick={() => dispatch?.electronicDocuments.cleanState()}
          >
            <AddCircleIcon />
          </Link>
        )
      }
    },
    {
      field: 'documentNumber',
      headerName: 'Número de documento',
      sortable: 'ELECTRONIC_DOCUMENTS.document_number',
      minWidth: 190,
      renderCell(params:IRespDocuments) {
        return (<div data-testid={`documentNumber-${params.documentNumber}`}> {params.documentNumber} </div>)
      },
    },
    {
      field: 'applicationStatus',
      headerName: 'Aplicación',
      sortable: 'ELECTRONIC_DOCUMENTS.application_status',
      minWidth: 210,
      isReduceCellPadding: true,
      renderCell(params:IRespDocuments) {
        return (
        <div 
          className={`statusBadge ${params.applicationStatus?.name === EApplicationStatus.pendingLoadingRips && "highlighted"}`}
          data-testid={`applicationStatus-${params.applicationStatus}`}>{params.applicationStatus?.name ?? '-'}
        </div>
        )
      },
    },
    {
      field: 'issuedAt',
      headerName: 'Fecha de documento',
      sortable: 'ELECTRONIC_DOCUMENTS.issued_at',
      minWidth: 190,
      renderCell(params:IRespDocuments) {
        return (<div data-testid={`issuedAt-${params.issuedAt}`}>{dayjs(new Date(params.issuedAt)).format('DD MMMM YYYY HH:mm')}</div>)
      },
    },
    {
      field: 'provider',
      headerName: 'NIT',
      sortable: 'employer_id_number',
      minWidth: 84,
      renderCell(params:IRespDocuments) {
        return (<div data-testid={`provider-${params.provider.employerIdNumber}`}>{params.provider.employerIdNumber}</div>)
      },
    },
    {
      field: 'business_name',
      headerName: 'Razón social',
      sortable: 'business_name',
      minWidth: 190,
      renderCell(params:IRespDocuments) {
        return (<div data-testid={`business_name-${params.provider.name}`}>{params.provider.name}</div>)
      },
    },
    {
      field: '',
      headerName: 'Tipo de documento',
      sortable: '',
      minWidth: 160,
      renderCell(params: IRespDocuments) {
        return( 
          <div
            data-testid={`type-${params.type.name}`}
          >{params.type.name}</div> 
        )
      },
    },
    {
      field: 'relatedInvoiceNumber',
      headerName: 'Factura de referencia',
      sortable: '',
      minWidth: 160,
      renderCell(params:IRespDocuments) {
        return (
          <div
            data-testid={`relatedInvoice-${params.relatedInvoiceNumber}`}
          >{params.relatedInvoiceNumber}</div>
        )
      },
    },
    {
      field: 'totalValue',
      headerName: 'Valor total del documento',
      sortable: '',
      minWidth: 190,
      renderCell(params: IRespDocuments) {
        return (
          <div
            data-testid={`totalValue-${params.totalValue}`}
          >{ formatCurrency(params.totalValue) }</div>
        )
      },
    },
    {
      field: 'stateName',
      headerName: 'Estado del documento',
      sortable: '',
      minWidth: 160,
      renderCell(params:IRespDocuments) {
        return (
          <div
            data-testid={`stateName-${params.state.name}`}
          >{params.state.name}</div>
        )
      },
    },
    {
      field: 'uniqueCodeElectronicBilling',
      headerName: 'CUDE',
      sortable: '',
      minWidth: 100,
      renderCell(params:IRespDocuments) {
        return (
          <Tooltip title={params.uniqueCodeElectronicBilling} placement="top" arrow data-testid={`uniqueCodeElectronicBilling-${params.uniqueCodeElectronicBilling}`}>
            <div className='ellipsisCustom width-150'>{params.uniqueCodeElectronicBilling} </div>
          </Tooltip>
        )
      },
    },
    {
      field: 'actions',
      headerName: '',
      minWidth: 120,
      renderCell(params: any) {
        return (
          <MenuCustom 
            paramsDoc={params}
            onAction={onAction}
          />
        )
      },
    },
  ]
}