import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Spinner } from 'shared/components';
import { AddCreditNotesForm } from './AddCreditNotesForm';
import { useAuditResultAcceptanceForm } from '../hooks/useAuditResultAcceptanceForm';
import Image from 'shared/assets/index';
import styles from '../styles/auditResultAcceptanceForm.module.scss';
import { CreditNoteType } from '../Models/ECreditNoteType';
import { textReconciliationAuditResults } from 'shared/utils';
import { Fragment } from 'react';

interface Props {
    acceptedConsolidatedValue: boolean
}

export const AuditResultAcceptanceForm = ({acceptedConsolidatedValue}:Props) => {

    const { 
        isLoading, 
        readOnly, 
        acceptSettlement,
        idDocument,
        showAcceptancePolicy, 
        showConfirmResultAlert
    } = useAuditResultAcceptanceForm();

    return (
        <>
            <Box className={styles.gridGlosasResults}>
                <Box className={styles.containerTitleCard}>
                    <img src={Image.iconoResumen} alt="" />
                    <Typography className={styles.titleCard}>Conciliación del Resultado de Auditoría</Typography>
                </Box>
                        <Box className={styles.containerContentCard}>
                        {
                            acceptedConsolidatedValue &&(
                                <Fragment>
                                    <Typography variant='body1' className={styles.descriptionCard} dangerouslySetInnerHTML={{ __html: textReconciliationAuditResults }}/> 

                                    <AddCreditNotesForm 
                                        readOnly={readOnly} 
                                        creditNoteType={CreditNoteType.Accepted}
                                        selectedDocument={idDocument || ''}
                                    />
                                </Fragment>
                            )
                        }
                            {
                                !readOnly &&
                                <>
                                    <FormControlLabel
                                        className={styles.checkboxContainer}
                                        classes={{ label: styles.checkboxLabelContainer }}
                                        control={
                                            <Checkbox
                                                disableRipple
                                                className={styles.checkbox}
                                                checked={acceptSettlement}
                                                onClick={showAcceptancePolicy}
                                                data-testid='input-check-accept'
                                            />
                                        }
                                        label={<span className={styles.checkboxLabel}>Confirmo que  deseo aceptar la conciliación de Auditoria</span>}
                                    />
                                    <Box className={styles.buttonContainer}>
                                        <Button
                                            data-testid='accept-button'
                                            variant='contained'
                                            disabled={!acceptSettlement}
                                            onClick={showConfirmResultAlert}
                                        >
                                            Conciliar Auditoría
                                        </Button>
                                    </Box>
                                </>
                            }
                        </Box>
            </Box>
            {isLoading && <Spinner />}
        </>
    )
}
