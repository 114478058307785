
import * as React from "react";
import { styled, css } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";

export const useStyleSubmitInvoice = () => {
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:"linear-gradient(136deg, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1) 0%, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 82%) 35%, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 52%) 100%)",         
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
        "linear-gradient(136deg, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1) 0%, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 82%) 35%, rgba(210, 214, 222) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 1,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));
      
  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
      backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 58,
      height: 58,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      fontSize: "1.3rem",
      alignItems: "center",
      ...(ownerState.active && {
        backgroundImage:
        "linear-gradient(136deg, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1) 0%, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 82%) 35%, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 52%) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      }),
      ...(ownerState.completed && {
        backgroundImage:
        "linear-gradient(136deg, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 1) 0%, rgba(var(--general-color-r), var(--general-color-g), var(--general-color-b), 82%) 35%, rgba(210, 214, 222) 100%)",
      }),
      [theme.breakpoints.down("sm")]: css`
        width: 45px;
        height: 45px;
        fontSize: 1.1rem;
      `,
  }));
      
    function ColorlibStepIcon(props: StepIconProps) {
      const { active, completed, className } = props;
      
      const icons: { [index: string]: String } = {
        1: "1",
        2: "2",
        3: "3",
      };
      
      return (
        <ColorlibStepIconRoot
          ownerState={{ completed, active }}
          className={className}
        >
          {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
      );
    }

    return {
      ColorlibStepIcon,
      ColorlibConnector 
    }
      
}
