import { useState } from 'react'
import Image from 'shared/assets/index';
import { contentTableRowRipsAC, contentTableRowRipsAF, contentTableRowRipsAH, contentTableRowRipsAM, contentTableRowRipsAN, contentTableRowRipsAP, contentTableRowRipsAT, contentTableRowRipsAU, contentTableRowRipsCUV, contentTableRowRipsUS } from '../helpers/contentTableRowDetailed';
import { getDataRips } from 'shared/api/services/getDataRips';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import _ from 'lodash';
import { IResponseTabRips, ripsReportAC, ripsReportAM, ripsReportAN, ripsReportAP, ripsReportAT, ripsReportRegistry } from '../Models/IResponseTabRips';
import { RESOLUTION_3374_2000 } from 'pages/read-documents/helper/resolutions';
import { NestedObject } from 'shared';

export const useTabRips = () => {

  const [ ripDetails, setRipDetails ] = useState<any[]>([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ dataRipsRegistry, setDataRipsRegistry ] = useState<ripsReportRegistry>()
  const dispatch = useDispatch<Dispatch>();

  const filterTableRow = (tableRow: any[], fieldToExclude: NestedObject) => {
    return tableRow?.filter(row => {
      const fieldValue = fieldToExclude[row.field as keyof NestedObject];
      return !_.isEmpty(fieldValue); 
    });
  };

  const convertServiceRips = (dataRips: IResponseTabRips) => {
    const data = [
      {
        id: "CUV",
        icon: Image.iconoRips,
        title: 'Información del CUV',
        columns: filterTableRow(contentTableRowRipsCUV, dataRips?.ripsCUV),
        content: _.isEmpty(dataRips.ripsCUV) ? {}
          : {
            processId: dataRips.ripsCUV?.processId ?? "-",
            invoiceNumber: dataRips.ripsCUV?.invoiceNumber ?? "-",
            dateCuv: dataRips.ripsCUV?.dateCuv ?? "-",
            timeCuv: dataRips.ripsCUV?.timeCuv ?? "-",
            processState: dataRips.ripsCUV?.processState ?? "-",
            cuv: dataRips.ripsCUV?.cuv ?? "-",
          }
      },
      {
        id:"US",
        icon: Image.iconoRips,
        title: 'INFORMACIÓN DE USUARIOS',
        columns: filterTableRow( contentTableRowRipsUS, dataRips?.ripsReportUS ),
        content: _.isEmpty(dataRips.ripsReportUS) ? {}
          : {
            identificationType: dataRips.ripsReportUS?.identificationType ?? "-",
            identificationNumber: dataRips.ripsReportUS?.identificationNumber ?? "-",
            userType: dataRips.ripsReportUS?.userType ?? "-",
            firstLastName: dataRips.ripsReportUS?.firstLastName ?? "-",
            secondLastName: dataRips.ripsReportUS?.secondLastName ?? "-",
            firstName: dataRips.ripsReportUS?.firstName ?? "-",
            middleName: dataRips.ripsReportUS?.middleName ?? "-",
            age: dataRips.ripsReportUS?.age ?? "-",
            ageUnit: dataRips.ripsReportUS?.ageUnit ?? "-",
            gender: dataRips.ripsReportUS?.gender ?? "-",
            province: dataRips.ripsReportUS?.province ?? "-",
            city: dataRips.ripsReportUS?.city ?? "-",
            residenceArea: dataRips.ripsReportUS?.residenceArea ?? "-",
            birthDate: dataRips.ripsReportUS?.birthDate ?? "-",
            country: dataRips.ripsReportUS?.country ?? "-",
            inability: dataRips.ripsReportUS?.inability ?? "-",
            countryResidenceCode: dataRips.ripsReportUS?.countryResidenceCode ?? "-",
          },
      },
      {
        id:"AF",
        icon: Image.iconoRips,
        title: 'INFORMACIÓN DE FACTURA',
        columns: filterTableRow(contentTableRowRipsAF, dataRips?.ripsReportAF ),
        content: _.isEmpty(dataRips.ripsReportAF) ? {}
          : {
            healthServiceProviderCode: dataRips.ripsReportAF.healthServiceProviderCode ?? "-",
            healthServiceProviderName: dataRips.ripsReportAF.healthServiceProviderName ?? "-",
            identificationType: dataRips.ripsReportAF.identificationType ?? "-",
            identificationNumber: dataRips.ripsReportAF.identificationNumber ?? "-",
            invoiceNumber: dataRips.ripsReportAF.invoiceNumber ?? "-",
            invoiceIssuedAt: dataRips.ripsReportAF.invoiceIssuedAt ?? "-",
            attentionPeriodStartedAt: dataRips.ripsReportAF.startDate ?? '',
            attentionPeriodEndedAt: dataRips.ripsReportAF.endDate ?? '',
            epsCode: dataRips.ripsReportAF.epsCode ?? "-",
            epsName: dataRips.ripsReportAF.epsName ?? "-",
            contractNumber: dataRips.ripsReportAF.contractNumber ?? "-",
            benefitsPlan: dataRips.ripsReportAF.benefitsPlan ?? "-",
            insurancePolicyNumber: dataRips.ripsReportAF.insurancePolicyNumber ?? "-",
            sharedFeeValue: dataRips.ripsReportAF.sharedFeeValue ?? "-",
            commissionValue: dataRips.ripsReportAF.commissionValue ??"-",
            discountsValue: dataRips.ripsReportAF.discountsValue ?? "-",
            totalValue: dataRips.ripsReportAF.totalValue ?? "-",
          },
      },
      {
        icon: Image.iconoRips,
        title: 'INFORMACIÓN DE CONSULTAS',
        columns: filterTableRow(contentTableRowRipsAC, dataRips?.ripsReportAC[0] ),
        content: _.isEmpty(dataRips.ripsReportAC[0]) ? {}
          : dataRips?.ripsReportAC?.map((item: ripsReportAC, index:number) => {
          return {
            id: index,
            invoiceNumber: item.invoiceNumber ?? "-",
            healthServiceProviderCode: item.healthServiceProviderCode ?? "-",
            identificationType: item.identificationType ?? "-",
            identificationNumber: item.identificationNumber ?? "-",
            consultationDate: item.consultationDate ?? "-",
            authorizationNumber: item.authorizationNumber ?? "-",
            consultationCode: item.consultationCode ?? "-",
            consultationCodeName: item.consultationCodeName?.name ?? "-",
            consultationPurpose: item.consultationPurpose ?? "-",
            externalCause: item.externalCause ?? "-",
            primaryDiagnosis: item.primaryDiagnosis ?? "-",
            firstRelatedDiagnosis: item.firstRelatedDiagnosis ?? "-",
            secondRelatedDiagnosis: item.secondRelatedDiagnosis ?? "-",
            thirdRelatedDiagnosis: item.thirdRelatedDiagnosis ?? "-",
            primaryDiagnosisType: item.primaryDiagnosisType ?? "-",
            consultationValue: item.consultationValue ?? "-",
            sharedFeeValue: item.sharedFeeValue ?? "-",
            totalValue: item.totalValue ?? "-",
            attentionStartDate: item.attentionStartDate ?? "-",
            moderatorPaymentFevNumber: item.moderatorPaymentFevNumber ?? "-",
            collectionConcept: item.collectionConcept ?? "-",
            serviceCod: item.serviceCod ?? "-",
            serviceGroup: item.serviceGroup ?? "-",
            serviceGroupModalityTecsal: item.serviceGroupModalityTecsal ?? "-",
          }
        }) ?? []
      },
      {
        id: "AU",
        icon: Image.iconoRips,
        title: 'INFORMACIÓN DE URGENCIAS',
        columns: filterTableRow( contentTableRowRipsAU, dataRips?.ripsReportAU),
        content: _.isEmpty(dataRips.ripsReportAU) ? {}
          : {
            invoiceNumber: dataRips.ripsReportAU.invoiceNumber?? "-",
            healthServiceProviderCode: dataRips.ripsReportAU.healthServiceProviderCode ?? "-",
            identificationType: dataRips.ripsReportAU.identificationType ?? "-",
            identificationNumber: dataRips.ripsReportAU.identificationNumber ?? "-",
            admissionDate: dataRips.ripsReportAU.admissionDate ?? "-",
            admissionTime: dataRips.ripsReportAU.admissionTime ?? "-",
            authorizationNumber: dataRips.ripsReportAU.authorizationNumber ?? "-",
            externalCause: dataRips.ripsReportAU.externalCause ?? "-",
            egressDiagnosis: dataRips.ripsReportAU.egressDiagnosis ?? "-",
            firstRelatedEgressDiagnosis: dataRips.ripsReportAU.firstRelatedEgressDiagnosis ?? "-",
            secondRelatedEgressDiagnosis: dataRips.ripsReportAU.secondRelatedEgressDiagnosis ?? "-",
            thirdRelatedEgressDiagnosis: dataRips.ripsReportAU.thirdRelatedEgressDiagnosis ?? "-",
            egressDestination: dataRips.ripsReportAU.egressDestination ?? "-",
            egressVitalState: dataRips.ripsReportAU.egressVitalState ?? "-",
            deathCause: dataRips.ripsReportAU.deathCause ?? "-",
            egressDate: dataRips.ripsReportAU.egressDate ?? "-",
            egressTime: dataRips.ripsReportAU.egressTime ?? "-",
            admissionDateTime: dataRips.ripsReportAU.admissionDateTime ?? "-",
            entranceDiagnosis: dataRips.ripsReportAU.entranceDiagnosis ?? "-",
            egressDateTime: dataRips.ripsReportAU.egressDateTime ?? "-",
          }
      },
      {
        id:"AH",
        icon: Image.iconoRips,
        title: 'INFORMACIÓN DE HOSPITALIZACION',
        columns: filterTableRow( contentTableRowRipsAH, dataRips?.ripsReportAH),
        content: _.isEmpty(dataRips.ripsReportAH) ? {}
          : {
            invoiceNumber: dataRips.ripsReportAH.invoiceNumber ?? "-",
            healthServiceProviderCode: dataRips.ripsReportAH.healthServiceProviderCode ?? "-",
            identificationType: dataRips.ripsReportAH.identificationType ?? "-",
            identificationNumber: dataRips.ripsReportAH.identificationNumber ?? "-",
            admissionRoad: dataRips.ripsReportAH.admissionRoad ?? "-",
            admissionDate: dataRips.ripsReportAH.admissionDate ?? "-",
            admissionTime: dataRips.ripsReportAH.admissionTime ?? "-",
            authorizationNumber: dataRips.ripsReportAH.authorizationNumber ?? "-",
            externalCause: dataRips.ripsReportAH.externalCause ?? "-",
            primaryAdmissionDiagnosis: dataRips.ripsReportAH.primaryAdmissionDiagnosis ?? "-",
            primaryEgressDiagnosis: dataRips.ripsReportAH.primaryEgressDiagnosis ?? "-",
            firstRelatedEgressDiagnosis: dataRips.ripsReportAH.firstRelatedEgressDiagnosis ?? "-",
            secondRelatedEgressDiagnosis: dataRips.ripsReportAH.secondRelatedEgressDiagnosis ?? "-",
            thirdRelatedEgressDiagnosis: dataRips.ripsReportAH.thirdRelatedEgressDiagnosis ?? "-",
            complicationDiagnosis: dataRips.ripsReportAH.complicationDiagnosis ?? "-",
            egressVitalState: dataRips.ripsReportAH.egressVitalState ?? "-",
            deathCauseDiagnosis: dataRips.ripsReportAH.deathCauseDiagnosis ?? "-",
            egressDate: dataRips.ripsReportAH.egressDate ?? "-",
            egressTime: dataRips.ripsReportAH.egressTime ?? "-",
            attentionStartDate: dataRips.ripsReportAH.attentionStartDate ?? "-",
            egressDateTime: dataRips.ripsReportAH.egressDateTime ?? "-",
          }
      },
      {
        icon: Image.iconoRips,
        title: 'INFORMACIÓN PROCEDIMIENTOS',
        columns: filterTableRow( contentTableRowRipsAP, dataRips?.ripsReportAP[0] ),
        content: _.isEmpty(dataRips.ripsReportAP[0]) ? {}
          : dataRips.ripsReportAP?.map(( item: ripsReportAP, index:number) => {
            return {
              id:index,
              invoiceNumber: item.invoiceNumber ?? "-",
              healthServiceProviderCode: item.healthServiceProviderCode ?? "-",
              identificationType: item.identificationType ?? "-",
              identificationNumber: item.identificationNumber ?? "-",
              procedureDate: item.procedureDate ?? "-",
              authorizationNumber: item.authorizationNumber ?? "-",
              procedureCode :item.procedureCode ?? "-",
              procedureCodeName: item.procedureCodeName?.name ?? "-",
              procedureContext: item.procedureContext ?? "-",
              procedurePurpose: item.procedurePurpose ?? "-",
              attendantStaff: item.attendantStaff ?? "-",
              primaryDiagnosis: item.primaryDiagnosis ?? "-",
              relatedDiagnosis: item.relatedDiagnosis ?? "-",
              complication: item.complication ?? "-",
              procedureExecutionMethod: item.procedureExecutionMethod ?? "-",
              procedureValue: item.procedureValue ?? "-",
              attentionStartDate: item.attentionStartDate ?? "-",
              mipresId: item.mipresId ?? "-",
              admissionRoad: item.admissionRoad ?? "-",
              serviceGroupModalityTecsal: item.serviceGroupModalityTecsal ?? "-",
              serviceGroup: item.serviceGroup ?? "-",
              serviceCod: item.serviceCod ?? "-",
              collectionConcept: item.collectionConcept ?? "-",
              sharedFeeValue: item.sharedFeeValue ?? "-",
              moderatorPaymentFevNumber: item.moderatorPaymentFevNumber ?? "-",
            }
        }) ?? []
      },
      {
        icon: Image.iconoRips,
        title: 'INFORMACIÓN DE OTROS SERVICIOS',
        columns: filterTableRow( contentTableRowRipsAT, dataRips?.ripsReportAT[0]),
        content: _.isEmpty(dataRips.ripsReportAT[0]) ? {}
          : dataRips.ripsReportAT?.map((item:ripsReportAT, index:number) => {
            return {
              id: index,
              invoiceNumber: item.invoiceNumber ?? "-",
              healthServiceProviderCode: item.healthServiceProviderCode ?? "-",
              identificationType: item.identificationType ?? "-",
              identificationNumber: item.identificationNumber ?? "-",
              authorizationNumber: item.authorizationNumber ?? "-",
              serviceType: item.serviceType ?? "-",
              serviceCode: item.serviceCode ?? "-",
              serviceName: item.serviceName ?? "-",
              quantity: item.quantity ?? "-",
              unitValue: item.unitValue ?? "-",
              totalValue: item.totalValue ?? "-",
              mipresId: item.mipresId ?? "-",
              technologyAdministerAt: item.technologyAdministerAt ?? "-",
              collectionConcept: item.collectionConcept ?? "-",
              sharedFeeValue: item.sharedFeeValue ?? "-",
              moderatorPaymentFevNumber: item.moderatorPaymentFevNumber ?? "-",  
            }
        })  ?? []
      },
      {
        icon: Image.iconoRips,
        title: 'INFORMACIÓN DE MEDICAMENTOS',
        columns: filterTableRow( contentTableRowRipsAM, dataRips.ripsReportAM[0] ),
        content: _.isEmpty(dataRips.ripsReportAM[0]) ? {}
          : dataRips.ripsReportAM?.map((item: ripsReportAM, index:number) => {
              return {
                id: index,
                invoiceNumber: item.invoiceNumber ?? "-",
                healthServiceProviderCode: item.healthServiceProviderCode ?? "-",
                identificationType: item.identificationType ?? "-",
                identificationNumber: item.identificationNumber ?? "-",
                authorizationNumber: item.authorizationNumber ?? "-",
                medicineCode: item.medicineCode ?? "-",
                medicineType: item.medicineType ?? "-",
                activePrinciple: item.activePrinciple ?? "-",
                pharmaceuticalPresentation: item.pharmaceuticalPresentation ?? "-",
                measurementUnit: item.measurementUnit ?? "-",
                amountOfUnits: item.amountOfUnits ?? "-",
                unitValue: item.unitValue ?? "-",
                totalValue: item.totalValue ?? "-",
                mipresId: item.mipresId ?? "-",
                medicationAdministerAt: item.medicationAdministerAt ?? "-",
                primaryDiagnosis: item.primaryDiagnosis ?? "-",
                relatedDiagnosis: item.relatedDiagnosis ?? "-",
                medicationQuantity: item.medicationQuantity ?? "-",
                treatmentDays: item.treatmentDays ?? "-",
                collectionConcept: item.collectionConcept ?? "-",
                sharedFeeValue: item.sharedFeeValue ?? "-",
                moderatorPaymentFevNumber: item.moderatorPaymentFevNumber ?? "-",
                concentration: item.concentration ?? "-",
              }
        })  ?? []
      },
      {
        icon: Image.iconoRips,
        title: 'INFORMACIÓN DE RECIEN NACIDOS',
        columns: filterTableRow( contentTableRowRipsAN, dataRips.ripsReportAN[0]),
        content: _.isEmpty(dataRips.ripsReportAN[0]) ? {}
          : dataRips.ripsReportAN?.map((item: ripsReportAN, index:number) => {
            return {
              id: index,
              healthServiceProviderCode: item.healthServiceProviderCode ?? "-",
              identificationType: item.identificationType ?? "-",
              identificationNumber: item.identificationNumber ?? "-",
              birthDate: item.birthDate ?? "-",
              birthTime: item.birthTime ?? "-",
              pregnancyStatus: item.pregnancyStatus ?? "-",
              birthControl: item.birthControl ?? "-",
              gender: item.gender ?? "-",
              weight: item.weight ?? "-",
              newBornDiagnosis: item.newBornDiagnosis ?? "-",
              deathCause: item.deathCause ?? "-",
              deathDate: item.deathDate ?? "-",
              deathTime: item.deathTime ?? "-",
              birthDateTime: item.birthDateTime ?? "-",
              egressDateTime: item.egressDateTime ?? "-",
              egressVitalState: item.egressVitalState ?? "-",
            }
          })  ?? []
      },
    ]
    setRipDetails(data);
    setIsLoading(false);
  }

  const callGetDataRips = (idDocument: string) => {
    ripDetails.length <= 0 && getDataRips( idDocument ?? "-" ).then((response: IResponseTabRips) => {
      convertServiceRips(response)
      setDataRipsRegistry(response.ripsReportRegistry)
    }).catch(()=> setIsLoading(false))
  }

  const downloadsRips = (id:any)=>{
    dispatch.electronicDocuments.getFileExcelToExport({ 
      url:`${id}/rips/download`, 
      text:"RIPS descargados con Exito" 
    })
  }

  const showButtonDowloadRips = (): boolean =>  dataRipsRegistry?.resolution === RESOLUTION_3374_2000

  return {
    isLoading,
    ripDetails,
    callGetDataRips,
    downloadsRips,
    convertServiceRips,
    showButtonDowloadRips
  }
}