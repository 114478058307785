import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {  Dispatch, GET_ELECTRONIC_DOCS, ISupports, RootState } from 'shared';
import Swal from 'sweetalert2';
import { transformNameSupportToId } from '../helper/transformNameSupportToId';

interface Props {
  idDoc: number;
  handleOnclickUpload: (noRequired?: boolean | undefined) => Promise<void>;
  response: any[] | undefined;
  setIsHadleSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  supports: ISupports[];
}

export const useModalLoadSupports = ({idDoc, response, supports, handleOnclickUpload, setIsHadleSubmit}:Props) => {

  const dispatch = useDispatch<Dispatch>();
  const [documents, setDocuments] = useState<any[] >([]);
  const [idDocuments, setIdDocuments] = useState<string[]>([]);
  const [progresses, setProgresses] = useState<Record<string, number>>({});
  const maxSize= 17;
  const requiredIds = ["1", "2", "7"];

  const handleChangeFile = (files: any) => {
      let prefixId: any[] = [];
      const arrayFiles: any[] = files.length !== undefined  ? Array.from(files) : [files];    
      
      if (files.length > maxSize || documents.length + files.length > maxSize) {
        return errorLengthFiles();
      }
    
      for (const file of arrayFiles) {
        const code = transformNameSupportToId(file.name.slice(0, 4));
        if (code === null) {
          prefixId = [];
          return errorNameFiles();
        }
        prefixId.push(code);
    
        setIdDocuments((prevIds) =>
          prevIds.includes(code) ? prevIds : [...prevIds, code]
        );
      }
    
      const filesDuplicates = documents.some(doc =>  arrayFiles.some(file => file.name === doc.name) );
  
      if (filesDuplicates) return errorDuplicatesFiles();
    
      if (prefixId.length > 0) {
        setDocuments((prevDocs) => arrayFiles.concat(prevDocs));
  
        arrayFiles.forEach(file => {
  
          setProgresses((prevProgresses) => ({
            ...prevProgresses,
            [file.name]: 0
          }));

          dispatch.linkSupports.getLinkAsync({
              file: arrayFiles,
              url: `${GET_ELECTRONIC_DOCS}/${idDoc}/supports/generate-upload-link`,
              codes: [supports.find(support => prefixId.includes(support.code))?.id]
            })?.finally(() => {
  
              // Control de progreso individual de cada archivo
              const intervalId = setInterval(() => {
                setProgresses(prev => ({
                  ...prev,
                  [file.name]: Math.min(prev[file.name] + 10, 100)
                }));
              }, 10);
      
  
                setProgresses((prev) => ({ ...prev, [file.name]: 100 }));
                clearInterval(intervalId);
            
                // Espera un breve tiempo para permitir que se vea el 100% antes de ocultarlo
                setTimeout(() => {
                  setProgresses((prev) => {
                    const updatedProgresses = { ...prev };
                    delete updatedProgresses[file.name];
                    return updatedProgresses;
                  });
                }, 500);
              
            });
        });
      }
    };

  const submitSupports = ()=>{
    const missingIds = requiredIds.filter(id => !idDocuments.includes(id));
    if (missingIds.length > 0) {
      Swal.fire({
        icon: "error",
        title: `Atención`,
        text: `Debe garantizar la carga de los soportes Obligatorios`,
        confirmButtonText: "Aceptar",
      })  
      return  
    }

    handleOnclickUpload(true);
    setIsHadleSubmit(false);
    
  }

  const removeFile = (file: any) => {
    const codeDelete = transformNameSupportToId(file.name.slice(0,4))
    setIdDocuments(idDocuments.filter(id => id !== codeDelete))
    const currentDocs = documents && documents.filter(doc => doc.name !== file.name);
    const filterDocuments = response?.filter(doc => doc.originalName !== file.name.replace(/\s+/g, ''));
    setDocuments(currentDocs);
    dispatch.linkSupports.getLink(filterDocuments);
    setProgresses((prevProgresses) => {
      const updatedProgresses = { ...prevProgresses };
      delete updatedProgresses[file.name];
      return updatedProgresses;
    });
  };

  const errorNameFiles = () => {
    Swal.fire({
      title: 'Carga fallida',
      text: `Debe garantizar que la estructura de los nombres de los soportes se  encuentren de manera correcta`,
      icon: 'error',
      timer: 5500
    })
  }

  const errorDuplicatesFiles = () => {
    Swal.fire({
      title: 'Carga fallida',
      text: `Debe garantizar que los archivos no estén duplicados`,
      icon: 'error',
      timer: 5500
    })
  }

  const errorLengthFiles = () => {
    Swal.fire({
      title: 'Carga fallida',
      text: `El máximo de archivos permitidos es de ${maxSize}`,
      icon: 'error',
      timer: 5500
    })
  }

  return {
    documents,
    progresses,

    removeFile,
    handleChangeFile,
    submitSupports
  }
}
