import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { IElectronicDocuments } from "shared/models";
import { apiInstance } from "shared/api/config/apiInstance";
import { GET_ELECTRONIC_DOCS } from "shared/api";
import { downloadDocument } from "shared/utils/helpers/downloadDocument";
import { downloadsTitle } from "shared/utils/data/infoDownloadDocument";
import { getFileToExport } from "shared/api/services/getFileToExport";

export const electronicDocuments = createModel<RootModel>()({
  state: {
    content: null,
    totalElements: 0,
    number: 0,
    size: 0,
    contentSize: 0,
    paramsUrl: {},
    url: "",
  } as IElectronicDocuments,
  reducers: {
    addState(state, payload){
      return{
        paramsUrl: payload.paramsUrl,
        url: payload.url,
        ...payload.res,
        content: payload.res.content,
      }
    },
    cleanState(state){
      return {
        ...state,
        content: null,
        totalElements: 0,
        number: 0,
        size: 0,
        contentSize: 0,
        paramsUrl: {},
        url: "",
      }
    },
    getElectronicDoc(state,payload){
      const updatedContent = state.content ? state.content.map((doc) => 
        doc.id === payload.content[0].id ? payload.content[0] : doc ): payload.content
      
      return {
        ...state,
        content: updatedContent
      }
    },
  },
  effects: dispatch => ({

    async getElectronicDocumentAsync(paramsUrl, state) {
      const response = await apiInstance.get(`${GET_ELECTRONIC_DOCS}/page`,{
        params: {...paramsUrl, states:""}
      });
      dispatch.electronicDocuments.getElectronicDoc(response.data)
    },
    
    async getElectronicDocuments(paramsUrl, state, url) {
      
      try {
        const response = await apiInstance.get(url,{
          params: {...paramsUrl}
        })

        state.electronicDocuments = {
          paramsUrl,
            url,
          ...response.data
        }
  
        dispatch.electronicDocuments.addState({
          paramsUrl,
          url,
          res: response.data
        })

      } catch (error) {
        dispatch.electronicDocuments.cleanState()
      }
    },
    
    async putPrepareInvoiceAsync(payload:any, state) {
      await apiInstance.put(`${GET_ELECTRONIC_DOCS}/${payload.url}`,payload.params);
    },

    async putCancelDocument(payload:any, state) {
      await apiInstance.put(`${GET_ELECTRONIC_DOCS}/${payload.url}`,{
        cancelReason: payload.cancelReason,
      });
    },

    async getFileExcelToExport(payload, state) {
      const response = await getFileToExport(payload)
      downloadDocument(response, downloadsTitle.successDocument, payload.text, 'success')
    },
 })
})
