import { useState } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { FormNoAgreementBalance } from "./FormNoAgreementBalance";
import stylesModalResult from '../styles/modalResultAcepptance.module.scss';
import styles from '../../../shared/components/styles/ModalMessage.module.scss';
import Image from 'shared/assets/index';
import { FormAuditGlosses } from "pages/detailed-consultation/components/audit-glosses/FormAuditGlosses";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    open: boolean;
    selectedDocument: string;
    onOpen: (open: boolean) => void;
}

export const ModalResultAcepptance = ({ open, selectedDocument, onOpen }: Props) => {

    const [hideModal, setHideModal] = useState(false);

    const onCloseModal = () => { onOpen(false) };
    
    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            className={styles.modalStates}
            sx={{ display: hideModal ? 'none' : '' }} 
            data-testid='modal-result-acepptance'
        >
            <Box className={`${styles.container} ${styles['large']}`}>
                <section className={styles.header}>
                    <Typography className={styles.titleModal}> Gestionar saldo en No Acuerdo </Typography>
                    <IconButton
                        className={styles.buttonClose}
                        onClick={onCloseModal}
                        data-testid='boton_cerrar'
                    >
                        <CloseIcon />
                    </IconButton>
                </section>
                <section className={stylesModalResult.descriptionDetail} data-testid='content_description'>
                    <FormAuditGlosses selectedDocument={selectedDocument} />

                    <Box className={stylesModalResult.titleContainer}>
                        <img src={Image.iconoResumen} alt="Resumen" />
                        <Typography className={stylesModalResult.title}>Detalle gestión de Saldo en No Acuerdo</Typography>
                    </Box>
                    <Box className={stylesModalResult.contentContainer}>
                        <Typography className={stylesModalResult.description}>
                            La conciliación de glosas es un proceso que se realiza cuando no hay acuerdo entre las partes. El proceso de conciliación tiene como finalidad el saneamiento de la cartera y contribuir a mejorar las relaciones con los prestadores. 
                            Ante la controversia suscitada por el pago de los servicios entre las IPS y las ERP, ésta puede ser resuelta por la Superintendencia Nacional de Salud, en el marco de su función jurisdiccional tal y como lo prevé el literal f) del artículo 41 de la Ley 1122 de 2007, modificado por el artículo 6 de la Ley 1949 de 2019. Así mismo, la IPS podrá acudir a la facultad de conciliación ante dicha superintendencia en virtud de lo previsto en el artículo 38 de la Ley 1122 de 2007. 
                            La resolución del saldo en no acuerdo puede modificarse también por una orden judicial, por saneamiento masivo de cartera, acuerdos directivos, entre otras justificaciones.
                        </Typography>
                        <FormNoAgreementBalance selectedDocument={selectedDocument} hideModal={setHideModal} closeModal={onCloseModal} />
                    </Box>  
                </section>
            </Box>
        </Modal>
    )
}
