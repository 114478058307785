import React from 'react';
import { Box, Button, IconButton, LinearProgress, Link, Typography } from '@mui/material';
import styles from '../styles/modalLoadSupports.module.scss';
import '../styles/_readDocumentsMUI.scss'
import { DataGrid } from '@mui/x-data-grid';
import { columnsFieldsSupports, fieldsSupports } from '../helper/fieldsSupports';
import { FileUploader } from 'react-drag-drop-files';
import { useLoadSupports } from '../hooks/useLoadSupports';
import { EResolutionDocument, EResponseActivityStatus, ISupports } from 'shared';
import { DeleteOutline } from '@mui/icons-material';
import { useModalLoadSupports } from '../hooks/useModalLoadSupports';
import iconos from 'shared/assets';
import { NavigateFunction } from 'react-router-dom';

interface Props {
    id: number;
    onClose: ()=> void;
    isHadleSubmit: boolean,
    setIsHadleSubmit: React.Dispatch<React.SetStateAction<boolean>>,
    supports: ISupports[];
    navigate: NavigateFunction
}

export const ModalLoadSupports = ({id: idDoc, onClose, isHadleSubmit, supports, setIsHadleSubmit, navigate}: Props) => {

    const {
        errorFileSize, errorFileType, typeAccept, maxLength,  bill, maxSize, response, resolution, handleOnclickUpload, activityStatus, loading
    } = useLoadSupports({ idDoc,isHadleSubmit, setIsHadleSubmit, navigate });

    const {documents, progresses, handleChangeFile, submitSupports, removeFile} = useModalLoadSupports({idDoc, response, supports, handleOnclickUpload, setIsHadleSubmit})

    return (
        <Box className={styles.informationContainer} data-testid="modal-load-supports">

            <Typography variant='body2' className={styles.descriptionTextSupports} data-testid="modal-text">Señor usuario a través del siguiente campo podrá cargar la totalidad de soportes asociados a la factura {bill}, por favor tenga en cuenta la siguiente estructura de nombramiento de cada uno de los soportes para que pueda hacerlo a través de esta ventana modal.</Typography>
            
            <DataGrid
                rows={fieldsSupports}
                columns={columnsFieldsSupports}
                className={'tableResults customTable'}
                data-testid="datagrid-supports"
                getRowId={(row:any) => row.abbreviation}
                hideFooterPagination={true}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu={true}
                hideFooterSelectedRowCount={true}
                hideFooter
                disableVirtualization={true}
                rowHeight={maxSize}
            />

            <section
                className={styles.sectionSupports}
            >
                <Typography 
                    variant='body2' 
                    className={styles.descriptionTextSupports} 
                    data-testid="modal-text">
                        Tenga en cuenta que solo se aceptarán los archivos que se encuentren nombrados de esta manera, teniendo presente las extensiones o formatos validos que son: <b>PDF, JPG, PNG, XLSX, DOCX, PPTX, HTML Y ZIP </b> y que el tamaño máximo permitido de cada archivo es de {maxLength} MB. 
                </Typography>
                <Typography 
                    variant='body2' 
                    className={styles.descriptionTextSupports} 
                    data-testid="modal-text">
                        Estimado usuario debe garantizar que por lo menos se carguen los archivos correspondientes a los soportes obligatorios.  Recuerde que es un solo archivo relacionado a un soporte, si necesita cargar varias evidencias correspondientes a un soporte, por favor unifique los archivos en un documento PDF,  esto con el fin de facilitar el proceso de auditoria.
                </Typography>

                <div className={styles.divContentModal}>
                    <FileUploader
                        types={typeAccept}
                        maxSize={maxLength}
                        handleChange={handleChangeFile}
                        hoverTitle={' '}
                        onTypeError={ errorFileType }
                        onSizeError={ errorFileSize }
                        fileOrFiles={documents}
                        disabled={loading}
                    >
                        <Box className={`${styles.contentFile} ${loading && styles.isDisabled}`} data-testid='btn_file_upload' >
                        {
                            documents ? (
                                <Box>
                                <Typography data-testid="name-file">
                                    <Link className={styles.fileDocument}><b> Agrega los soportes de la factura seleccionando uno a la vez</b></Link>
                                </Typography>
                                <Box className={styles.contentFilesSelected}>
                                    {
                                        documents.map((doc:any, i:any)=>
                                            <div key={i}>
                                                <section className={styles.contentUploadedFiles}>
                                                    <img  src={iconos.iconoPDF} alt='iconoPDF'/>
                                                    <Typography className='nombre-documento'>{ doc.name }</Typography>
                                                    <IconButton onClick={()=> removeFile(doc)} data-testid="file-remove">
                                                        <DeleteOutline/>
                                                    </IconButton>
                                                </section>
                                                {
                                                    progresses[doc.name] !== undefined  && (
                                                        <LinearProgress className={styles.progress} variant="determinate" value={progresses[doc.name]} data-testid={`linea_progresso_${i}`} />  
                                                    ) 
                                                }
                                                
                                            </div>
                                        )
                                    }
                                </Box>
                                </Box>
                            ): (
                            <>
                                <Typography data-testid="name-file-no-document">
                                    <Link className={styles.fileDocument} data-testid="file-uploader">
                                        <b> click aquí para seleccionar ARCHIVOS </b>
                                    </Link>
                                </Typography>
                            </>
                            )   
                        }
                        </Box>
                        
                    </FileUploader> 

                </div>

            </section>
                
            <Box className={styles.buttonsContainer}>
                <Button
                    variant='contained'
                    className={styles.confirmButton}
                    data-testid='submit-reset-audit'
                    onClick={submitSupports}
                    disabled={(activityStatus?.name === EResponseActivityStatus.FAIL && resolution === EResolutionDocument.r2275) || !resolution}
                >
                    Radicar factura
                </Button>
                <Button
                    variant='contained'
                    className={styles.cancelButton}
                    data-testid='cancel-upload-documents'
                    onClick={onClose}
                >
                    Cancelar
                </Button>
            </Box>
        </Box>
    )
}
