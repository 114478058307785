import React, { useEffect } from 'react';
import style from "../styles/detailedConsultation.module.scss";
import {Typography} from "@mui/material";
import Image from 'shared/assets/index';
import {contentInvoicedItems} from "../helpers/contentTableRowDetailed";
import {DataGrid, esES, GridColDef} from "@mui/x-data-grid";
import {IElectronicDocumentLine} from "../../../shared/models";
import {formatCurrency} from "../../../shared/utils";
import { ShowBalanceHistory } from './ShowBalanceHistory';
import { RootState } from 'shared';
import { useSelector } from 'react-redux';

interface Props {
    documentLines?: IElectronicDocumentLine[];
}


export const DocumentLinesTable = ({ documentLines }: Props) => {
    const { documentTotals } = useSelector((state: RootState) => state.auditDetails);
    const noRows =()=> {
        return(
            <div className='textNoRows' data-testid='content_message_error'>
                Aún no se ha cargado la información correspondiente de esta sección
            </div>
        )
    }
    const renderDocumentLines = () => {
        return !!documentLines ? (
            <div data-testid='content_data_grid'>
                <div className='containerTitleCardTabs'>
                    <img src={Image.iconoCheck} alt=""/>
                    <Typography>Items Facturados</Typography>
                </div>
                <DataGrid
                    rows={documentLines}
                    columns={contentInvoicedItems}
                    className={'tableResultsRips'}
                    style={{position:'relative'}}
                    data-testid="datagrid-document-items"
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu={true}
                    hideFooterSelectedRowCount={true}
                    hideFooterPagination={true}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    slots={{
                        noRowsOverlay: noRows
                    }}
                />
            </div>
        ) : null;
    }
    const renderTotals = () => {
        const columnsDef: GridColDef[] = [
            {
                field: 'Nombre',
                align: "right",
                headerAlign: "right",
                minWidth: 780,
                cellClassName: "c-black fs-bold",
            },
            {
                field: 'Valor',
                align: "right",
                headerAlign: "right",
                minWidth: 120,
                cellClassName: "c-black fs-bold",
                renderCell: renderParams => renderParams.value
            },
            {
                field: 'Ver',
                align: "center",
                headerAlign: "center",
                minWidth: 60,
                cellClassName: "c-black fs-bold",
                renderCell: renderParams => renderParams.value && <ShowBalanceHistory />
            },
        ];
        const totalRows = [
            {id: 1, Nombre: 'Valor Factura', Valor: formatCurrency(documentTotals?.totalValue!) || 0},
            {id: 4, Nombre: 'Total Notas Cr\u00e9dito', Valor: `${formatCurrency(documentTotals?.creditTotalValue!)}` || 0},
            {id: 5, Nombre: 'Total Notas D\u00e9bito', Valor: `${formatCurrency(documentTotals?.debitTotalValue!)}` || 0},
            {id: 6, Nombre: 'Total Autorizaciones de Pago', Valor: `${formatCurrency(documentTotals?.authorizations!) || 0}`},
            {id: 7, Nombre: 'Valor Sin Autorización de Pagos', Valor: formatCurrency(documentTotals?.totalBalance!) || 0, Ver: true}
        ]
        
        return !!documentTotals ? (
            <div>
                <div className='containerTitleCardTabs'>
                    <img src={Image.iconoMonedas} alt=""/>
                    <Typography >Totales facturados</Typography>
                </div>
                <DataGrid
                    rows={totalRows}
                    columns={columnsDef}
                    className={'tableResultsTotals'}
                    data-testid="datagrid_content_totals"
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu={true}
                    hideFooterSelectedRowCount={true}
                    hideFooterPagination={true}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
        ) : null;
    }
      
    return (
        <>
            <div className={`${style.containerBody} containerFormsss containerTableResults`} data-testid={'container_document_items'}>
                {renderDocumentLines()}
                {renderTotals()}
            </div>
        </>
    )
}