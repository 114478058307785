import React, { useState} from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { ButtonAction } from 'shared/components/ButtonAction'
import { Link, useParams } from 'react-router-dom'
import { accesRoles } from 'shared/models'
import { ModalDocumentsPdf } from 'shared/components/ModalDocuments.pdf'
import { useOpenPdf } from 'shared/hooks/useOpenPdf'
import { POST_PAYMENT_AUTHORIZATION_DETAILS, Spinner } from 'shared'

interface Props {
    id:any,
    detailed?: boolean 
    idPayment: string
}

export const MenuActions =({id, detailed, idPayment }: Props) => {

  const { idDocument } = useParams();
  const {documentPdf, openIframePdf, isLoading, callServicePdf, closeModalPdf} = useOpenPdf();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  const isOpenFunctions = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };  

  const viewSupport = (idProp: any) =>{
    const url = `${POST_PAYMENT_AUTHORIZATION_DETAILS}/${idDocument}/download/${idProp}`
    callServicePdf(url)
  }

  return (
    <div className={'MenuFunctions'}>
        <IconButton
            data-testid={`buttonFunctions`}
            onClick={handleClick}
        >
            Acciones <ExpandMore/>
        </IconButton>
        <Menu
            id="menu-options"
            data-testid='menuOptions'
            className={'menuOptions'}
            anchorEl={anchorEl}
            open={isOpenFunctions}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
        >
            <MenuItem>
              <ButtonAction
                roles={accesRoles.finance}
                disabled={!!detailed}
              >
                <Link to={`/consulta_documentos_electronicos/detalle_documento/${id}`} data-testid='link-detailed'>
                  Ver factura
                </Link>
              </ButtonAction>
            </MenuItem>
            <MenuItem>
              <ButtonAction
                roles={accesRoles.finance}
                disabled={false}
                action={()=>viewSupport(idPayment)}
                dataTestid='button-viewSupport'
              >
                <span>Ver autorización de pago</span>
              </ButtonAction>
            </MenuItem>   

        </Menu>
        { openIframePdf && <ModalDocumentsPdf document={documentPdf} openIframePdf={openIframePdf} handleOpenIframePdf={closeModalPdf}/> }
        {(isLoading) && <Spinner/>}
    </div>
  )
}
