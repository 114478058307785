
import { ExpandMore } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import '../../../pages/read-documents/styles/_menuFunctions.scss'  
import { Link } from 'react-router-dom'
import { ButtonAction } from '../ButtonAction';
import { accesRoles, IRespDocuments } from 'shared/models';
import { useDispatch } from 'react-redux';
import { Dispatch, ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE, EStatusInvoice, GET_ELECTRONIC_DOCS, GET_ELECTRONIC_DOCUMENTS_RELATED, SpinnerText } from 'shared';
import { getData } from 'shared/api/services';
import { useOpenPdf } from 'shared/hooks/useOpenPdf';
import Swal from 'sweetalert2';
import { ModalDocumentsPdf } from '../ModalDocuments.pdf';
import { RESOLUTION_3374_2000 } from 'pages/read-documents/helper';

interface MenuCustomProps {
    paramsDoc: IRespDocuments,
    menuItemToExclude?: ('cargarRips' | 'verFactura' | 'descargarDocumento' | 'verNotaCredito')[];
    disableViewCreditNote?: boolean;
    disableDocumentDownload?: boolean;
    onAction?: (prevState: any) => void
}

export const MenuCustom = ( { menuItemToExclude, paramsDoc, disableViewCreditNote: disableInvoicebutton = false, disableDocumentDownload: disableCreditNoteButton = false, onAction }: MenuCustomProps) => {

    const dispatch = useDispatch<Dispatch>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ responseGetDocuments, setResponseGetDocuments ] = useState<any>(null);
    const [ btnDisabled, setBtnDisabled ] = useState<boolean>(true);
    const [ showSpinner, setShowSpinner ] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    const { documentPdf, openIframePdf, callServicePdf, closeModalPdf } = useOpenPdf();

    const handleAction = (action: string) => {
        const actionsMap: { [key: string]: () => void } = {
            viewDocument: () => {
                const url = `${GET_ELECTRONIC_DOCS}/${paramsDoc.id}${ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE}-base64?supportId=${responseGetDocuments?.attachment?.id}`;
                callServicePdf(url);
            },
            downloadDocument: () => {
                dispatch.electronicDocuments.getFileExcelToExport({
                    url: `${paramsDoc.id}${ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE}?supportId=${responseGetDocuments?.attachment?.id}`,
                    text: "DOCUMENTOS descargado con Exito"
                });
            }
        };
        actionsMap[action]();
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {   
        const anchorElement = event.currentTarget;    
        setShowSpinner(true);
        setAnchorEl(anchorElement);

        getData(`${GET_ELECTRONIC_DOCS}/${paramsDoc.relatedInvoiceId}${GET_ELECTRONIC_DOCUMENTS_RELATED}`) 
            ?.then((response: any) => {
                if (response.length > 0) {
                    setBtnDisabled(Object.keys(response[0]?.attachment).length === 0 )
                    setResponseGetDocuments(response[0]);
                }else{
                    showAlert("¡Ups!", "No se encontraron documentos relacionados");
                    setBtnDisabled(true)
                } 
            })
            .catch((error) => {
                console.log('error', error);
            }).finally(() => {
                setShowSpinner(false);
            })
    }

    const handleClose = () => setAnchorEl(null);

    const showAlert = (title: string, text: string) => {
        setAnchorEl(null)
        setResponseGetDocuments(null);

        setTimeout(() => {
            Swal.fire({
                title: title,
                text: text,
                icon: "warning",
                timer: 5000,
                confirmButtonText: "Aceptar",
            });
        }, 500);
    }

    const filterMenuItem = (menuItem: { name: string, label: string, component: JSX.Element }[], fieldToExclude: string[] = []) => {
        return menuItem.filter(column => !fieldToExclude?.includes(column.name));
    }

    const menuItems: { name: string, label: string, component: JSX.Element  }[] = [
        { 
            name: 'cargarRips', 
            label: 'Cargar Rips',
            component: 
                <ButtonAction
                    roles={accesRoles.healthProvider}
                    disabled={paramsDoc.state.name === EStatusInvoice.docReceived && paramsDoc?.resolution === RESOLUTION_3374_2000}
                    dataTestid='cargar_rips'
                    action={() => {
                        setAnchorEl(null)
                        onAction?.({
                            title: paramsDoc.type.name,
                            idDocument: paramsDoc.id,
                            relatedInvoiceNumber: paramsDoc.relatedInvoiceNumber,
                        })
                    }}
                >
                    <span>Cargar Rips</span>
                </ButtonAction>
        },
        { 
            name: 'verFactura', 
            label: 'Ver Factura',
            component:  
                <ButtonAction
                    roles={accesRoles.reader}
                    disabled={false}
                    dataTestid='ver_factura'
                    action={() => dispatch.electronicDocuments.cleanState()}
                >
                    <Link to={`/consulta_documentos_electronicos/detalle_documento/${paramsDoc.relatedInvoiceId}`} data-testid='link-detailed'>
                        Ver factura
                    </Link>
                </ButtonAction>
        },
        { 
            name: 'verNotaCredito', 
            label: paramsDoc.type.name,
            component: 
                <ButtonAction
                    roles={accesRoles.reader}
                    disabled={btnDisabled || disableInvoicebutton}
                    action={() => handleAction('viewDocument') }
                    dataTestid='ver_nota_credito'
                >
                    <span>Ver {paramsDoc.type.name.toLowerCase()}</span> 
                </ButtonAction>
        },
        { 
            name: 'descargarDocumento', 
            label: 'Descargar Documento',
            component: 
                <ButtonAction
                    roles={accesRoles.reader}
                    disabled={btnDisabled || disableCreditNoteButton}
                    action={()=> handleAction('downloadDocument') }
                    dataTestid='descargar_soporte'
                >
                    <span>Descargar Documento</span> 
                </ButtonAction>
        }
    ];

    return (
        <div className={'MenuFunctions'} data-testid='menu-functions'>
            <IconButton onClick={handleClick} data-testid='icon-button-menu'>
                Acciones <ExpandMore/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button'}}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                data-testid='menu-options'
            >
                {
                    showSpinner ? 
                        <MenuItem disabled={true}>
                            <SpinnerText />
                        </MenuItem>
                        :
                        filterMenuItem(menuItems, menuItemToExclude).map((item) => (
                            <MenuItem key={item.name}>
                                {item.component}
                            </MenuItem>
                        ))
                }
            </Menu>
            { openIframePdf && <ModalDocumentsPdf document={documentPdf} openIframePdf={openIframePdf} handleOpenIframePdf={closeModalPdf}/> }
        </div>
    )
}
