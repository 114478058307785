import { Fragment, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { Spinner } from 'shared/components';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import styles from '../styles/tabDocumentsRelated.module.scss'
import { useTabSupports } from '../hooks/useTabSupports';
import { EdocumentsOpened } from '../helpers/enumDocumentsOpened';
import { useOpenPdf } from 'shared/hooks/useOpenPdf';
import { ModalDocumentsPdf } from 'shared/components/ModalDocuments.pdf';
import { ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE, GET_ELECTRONIC_DOCS, accesRoles } from 'shared';
import { useAuth } from 'shared/auth';

export const TabSupports = () => {

    const { idDocument } = useParams();
    const { isLoading: isLoadingSupport, documents, invoiceIsLessThanConsolidated, callGetSupports, downloadSupports, downloadDocument, supportNotLoaded, uploadDocument, supportLoaded } = useTabSupports()
    const { documentPdf, openIframePdf, isLoading, callServicePdf, closeModalPdf } = useOpenPdf();

    const {authService} = useAuth();
    
    const validateTypeDocument = (value:string) => {
        const typeDoc = value.split('.')
        return Object.values(EdocumentsOpened).includes(typeDoc[typeDoc.length -1])
    }
    
    const viewSupport = (id:any) =>{
        const url = `${GET_ELECTRONIC_DOCS}/${idDocument}${ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE}-base64?supportId=${id}`
        callServicePdf(url);
    }
    
    useEffect(() => {
        callGetSupports(idDocument || "")
    },[])
    
    const memoizedResults = useMemo(() => {
        const userRoles = authService.getUserRoles();
        const userRolIsHealthProvider = userRoles.some(role => accesRoles.healthProvider.includes(role));
        return documents?.content.map((col) => ({
            ...col,
            supportLoaded: supportLoaded(col.state),
            supportNotLoaded: supportNotLoaded(col.state),
            invoiceIsLessThan:  invoiceIsLessThanConsolidated,
            userRolIsHealthProvider
          })); 
    }, [documents]);

    return (
        <Fragment>
            {
                documents.content.length > 0 ? (
                    <>
                        <Button 
                            color='primary' 
                            variant='contained' 
                            sx={{display:"block", marginLeft: "auto", marginTop:"1rem", marginBottom:"1rem", borderRadius:100}}
                            onClick={()=>downloadSupports()}
                            data-testid='btn-download-supports'
                        >
                            Descargar Soportes
                        </Button>
                        <Paper className={styles.containerTableResults}>
                            <div className='containerTitleCardTabs'>
                                <img src={documents.icon} alt="" />
                                <Typography data-testid="title-documents-supports">{ documents.title }</Typography>
                            </div>
                            <TableContainer className={styles.tableCommon} data-testid="table-documents-supports">
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" data-testid="table-results">
                                    <TableHead>
                                        <TableRow className={styles.tablaHeader}>
                                            {
                                                documents.columns.map((col:any,i:number)=>(
                                                    <TableCell 
                                                        key={i} 
                                                        className={styles.tableCell}
                                                        style={{ minWidth: col.minWidth }}
                                                    >
                                                        { col.headerName }
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            memoizedResults.map((col: any, i: number) => (
                                                <TableRow key={i} data-testid="table-results-body">
                                                    <TableCell className={styles.tableCell}>{ col.support.name }</TableCell>
                                                    <TableCell className={styles.tableCell}>{ col.originalName }</TableCell>
                                                    <TableCell className={styles.tableCell}>{ col.contentType }</TableCell>
                                                    <TableCell className={styles.tableCell} data-testid={`createAd_${i}`}>{ col.supportNotLoaded ? '-' : col.createdAt } </TableCell>
                                                    <TableCell className={styles.tableCell}>{ col.state }</TableCell>
                                                    <TableCell className={styles.tableCell} data-testid={`createdBy_${i}`}>{ col.supportNotLoaded ? '-' : col.createdBy } </TableCell>
                                                    <TableCell className={styles.tableCell}>
                                                        { 
                                                            ( col.userRolIsHealthProvider && col.supportNotLoaded && col.invoiceIsLessThan ) && (
                                                                <Tooltip title="Cargar" placement="top" arrow>
                                                                    <IconButton aria-label="upload" data-testid={`upload_support_${i}`} onClick={() => uploadDocument( col.id, col.support)}>
                                                                        <FileUploadIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )
                                                        }
                                                        {
                                                            (col.supportLoaded ) && (
                                                                <>
                                                                    <Tooltip title="Ver documento" placement="top" arrow disableFocusListener={!validateTypeDocument(col.originalName)} >
                                                                        <span>
                                                                            <IconButton
                                                                                aria-label="view"
                                                                                data-testid="view-support"
                                                                                onClick={() => viewSupport(col.id)}
                                                                                disabled={!validateTypeDocument(col.originalName)}
                                                                            >
                                                                                <VisibilityIcon />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                  {
                                                                    col.invoiceIsLessThan && col.userRolIsHealthProvider && col.support.code && (
                                                                        <Tooltip title="Reemplazar soporte" placement="top" arrow>
                                                                            <span>
                                                                                <IconButton aria-label="edit" data-testid="edit-support" onClick={() => uploadDocument( col.id, col.support, true)}>
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            </span>
                                                                        </Tooltip>
                                                                    )
                                                                  }
                                                                </>
                                                            )
                                                        }
                                                        <Tooltip title="Descargar" placement="top" arrow disableFocusListener={!(col.supportLoaded && col.invoiceIsLessThan)}>
                                                            <span>
                                                                <IconButton
                                                                    aria-label="download"
                                                                    data-testid="download-support"
                                                                    onClick={() => downloadDocument( col?.id )}
                                                                    disabled={!(col.supportLoaded)}
                                                                >
                                                                    <DownloadForOfflineIcon />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                                
                        </Paper>
                    </>
                    )
                : (
                    <Box className={styles.containerNotResults} data-testid="title-not-results">Aún no se ha cargado la información correspondiente de esta sección</Box>
                )
            }
            { openIframePdf && <ModalDocumentsPdf document={documentPdf} openIframePdf={openIframePdf} handleOpenIframePdf={closeModalPdf}/> }
            {(isLoading || isLoadingSupport) && <Spinner/>}
        </Fragment>
    )
}
