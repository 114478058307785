import { Box } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { DetailedGlosse } from './DetailedGlosse';
import { FormReplyGlosse } from './FormReplyGlosse';
import styles from './style/modalReplyGlosses.module.scss';
import { FormConsolidateGlossas } from './FormConsolidateGlossas';
import { activities } from 'shared/models/lineStates';
import { IModalReplyGlosses } from './interface/ImodalReplyGlosses';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared';

export const ModalReplyGlosses = ({billName, idGlossa, readOnly, enableConsolidate, glosas, idDocument, documentsSupports, billingState, 
  viewDocument, downloadSupports, callGetFiles, callGetDetailedDocument, provider}: IModalReplyGlosses) => {

    const dispatch = useDispatch<Dispatch>();
    const [lastGlossa, setLastGlossa] = useState(false);
    const [dataSingleGlosa, setDataSingleGlosa] = useState<any>({
      columns:[],
      content:{
        id: null,
        idGlosa: null,
        codeGlosa: '',
        nameGlosa: '',
        valueGlosa: '',
        statusGlosa: '',
        justification: ''
      }
    });

    const contentDataSingleGlosa = [
      { label: 'ID', idLabel: 'id' },
      { label: 'Código', idLabel: 'codeGlosa' },
      { label: 'Nombre de la glosa', idLabel: 'nameGlosa' },
      { label: 'Valor de la glosa', idLabel: 'valueGlosa' },
      { label: 'Estado', idLabel: 'status' },
      { label: 'Justificación de la glosa', idLabel: 'justification' },
    ]

    const statusOpen = 'Abierta';

    const onCloseModal = () => dispatch.messageModal.cleanStateMessage()

    useEffect(() => {
      const findGlossa = glosas.find(data => data.id === idGlossa);

      const validateLastGlosse = ():boolean =>{
        if(glossasOpen.length <= 1 && !findGlossa?.glossAnswer)
            return true;
        else if(billingState.code === activities.facturaGlosasEnviadas && glossasOpen.length === 0 ){
          return true;
        }
        return false
      }
      
      const data = {
        columns: contentDataSingleGlosa,
        content: {
          id: findGlossa?.id ?? "1",
          codeGlosa: findGlossa?.codeGlosa ?? "",
          idGlosa: findGlossa?.idGlosa ?? "",
          nameGlosa: findGlossa?.nameGlosa ?? "",
          valueGlosa: Number(findGlossa?.valueGlosa).toLocaleString("es-CO"),
          statusGlosa:{
            name: findGlossa?.statusGlosa.name ?? "",
            code: findGlossa?.statusGlosa.code ?? "",
          },
          status: findGlossa?.statusGlosa.name ?? "",
          justification: findGlossa?.justification ?? "",
          valueAccept: findGlossa?.valueAccept ?? '',
          valueSustained: findGlossa?.valueSustained ?? '',
          valueNotAccept: findGlossa?.valueNotAccept ?? '',
          glossAnswer: findGlossa?.glossAnswer ?? '',
          answerConsolited: findGlossa?.answerConsolited ?? ' ',
          glossSupport: findGlossa?.glossSupport
        }     
      };
      const glossasOpen = glosas.filter(data => data.statusGlosa.name === statusOpen);
      setDataSingleGlosa(data);
      setLastGlossa(validateLastGlosse);
    }, [idGlossa]);
  
  return (
    <Fragment>
      <section className={styles.modal} >
        <Box className={styles.modalContainer}>
          { dataSingleGlosa && <DetailedGlosse dataGlosas={dataSingleGlosa} title='Resumen de Glosa'/> }
          <FormReplyGlosse 
            onCloseModal={onCloseModal} 
            dataSingleGlosa={dataSingleGlosa.content} 
            billName={billName} 
            lastGlossa={lastGlossa}
            readOnly={readOnly}
            downloadDocument={downloadSupports}
            viewDocument={viewDocument}
            callGetFiles={callGetFiles}
            documentsSupports={documentsSupports}
            idDocument={idDocument} 
            callGetDetailedDocument={callGetDetailedDocument}
          />
          {
            enableConsolidate &&
              <FormConsolidateGlossas
                onCloseModal={onCloseModal} 
                dataSingleGlosa={dataSingleGlosa.content} 
                billName={billName} 
                idDocument={idDocument!} 
                callGetDetailedDocument={callGetDetailedDocument} 
                provider={provider}                     
              />
          }
        </Box>
      </section>
    </Fragment>
  )
}
