import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Dispatch } from "shared/store/store";
import { RichTextEditor } from "shared/components";
import styles from './styles/CommentForm.module.scss';
import { removeHTMLTagsFromText } from "../../utils/helpers";
import { postCommentToAdd } from "shared/api/services/requestComment";
import { useParams } from "react-router-dom";
import { useAuth } from "shared/auth";
import { accesRoles } from "shared/models";

export const privacyOption = {
    public: 'publico',
    privacy: 'privado'
}

export const CommentForm = () => {
    const {authService} = useAuth();
    const userData = authService.getUser();
    const rol = authService.getUserRoles() 
    const { idDocument } = useParams()
    const dispatch = useDispatch<Dispatch>();
    const formRef = useRef<FormikProps<any>>(null);
    const [comment, setComment] = useState('<p><br></p>');
    const [totalCaracteres, setTotalCaracteres] = useState(0);
  
    const validationSchema = () => Yup.object().shape({
        privacy: Yup.string().required(),
    });

    const addComment = (commentText: any) => {
        const element = document.createElement('div');
        element.innerHTML = commentText.message;

        const mentions: { [key: string]: string } = {};
        Array.from(element.querySelectorAll('[data-id]')).forEach(value => {
            mentions[value.textContent ?? ''] = value.getAttribute('data-id') ?? '';
        });
    
        const commentObj = {
            ...(Object.keys(mentions).length > 0  ? { mentions } : { mentions: { } }),
            message: commentText.message,
            isPrivate: commentText.privacy === privacyOption.privacy
        };

        
        postCommentToAdd(commentObj, (idDocument || '')).then(response => {
            dispatch.comments.getCommentListAsync(idDocument ?? '')
        })
        
        setComment('<p><br></p>');
        setTotalCaracteres(0)
        formRef.current!.resetForm();
    }

    useEffect(() => {
        return () => setComment('<p><br></p>');  
    },[])

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                privacy: rol.some(role => accesRoles?.healthProvider?.includes(role)) ? privacyOption.public : '',
            }}
            validationSchema={validationSchema}
            onSubmit={({ privacy }) => { 
                addComment({ 
                    id: userData?.oid ?? '', 
                    message: comment, 
                    privacy: privacy || 'TODOS',
                    author: userData?.name ?? '',
                    date: new Date(),
                    wasUpdated: false,
                })
            }}
            validateOnMount
            validateOnChange
            data-testid='comment_form'
        >
            {
                formik => (
                    <Form className={styles.formCommnet} data-testid='form-invoice'>
                        <Box className={styles.formContainer}>
                            <Field name={"acceptedConsolidatedValue"} className={`${styles.inputItemValue}`}>
                                {({ field }: FieldProps) => (
                                    
                                    rol.some(role => accesRoles?.healthProvider?.includes(role)) ? <></> 
                                        :<FormControl {...field} fullWidth className={styles.itemForm}>
                                            <RadioGroup
                                                aria-labelledby="radio-buttons-invoice"
                                                name="privacy"
                                                row
                                                className={styles.radioGroup}
                                                data-testid='radio_group'
                                            >                                               
                                                <FormControlLabel checked={formik.values.privacy === privacyOption.public} 
                                                    value={privacyOption.public} control={<Radio/>} label="Visible para Prestador" data-testid='radio_public' />
                                                <FormControlLabel checked={formik.values.privacy === privacyOption.privacy}
                                                    value={privacyOption.privacy} control={<Radio/>} label="Interno SSSA" data-testid="radio_pivacy"/>
                                            </RadioGroup>
                                        </FormControl>
                                )}
                            </Field>
                            <Box className={styles.richTextCommentContainer}>
                                <RichTextEditor 
                                    value={comment} 
                                    onChange={(text) => { 
                                        setTotalCaracteres(removeHTMLTagsFromText(text).length)
                                        formik.validateForm(); 
                                        setComment(text); 
                                    }} 
                                    readOnly={false}
                                    useMention={ rol.some(role => accesRoles?.usersSSSA?.includes(role)) }
                                />
                            </Box>
                                <span className={`${totalCaracteres > 5000 ? styles.totalCharactersError : styles.totalCharacters}`}>{totalCaracteres}/5000</span>
                            <section className={styles.commentButtonContainer}>
                                <Button
                                    className={styles.commentButton}
                                    variant="contained"
                                    type='submit'
                                    disabled={!formik.isValid || !removeHTMLTagsFromText(comment) || totalCaracteres > 5000}
                                >
                                    Comentar
                                </Button>
                            </section>
                        </Box>
                    </Form>
                )
            }
        </Formik>
    )
}
