import { IResponseStates } from "shared/models/store/IInvoiceStates"

export const convertInvoiceStates = (options: IResponseStates[]): IResponseStates[] => {
    return options.map(state => {
        return {
            id: state.id,
            code: state.code,
            name: state.name,
            group: {
                code: state.group?.code || '',
                name: state.group?.name || ''
            }
        }
      })
}