import React, {Fragment, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Spinner } from 'shared/components';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useTabRips } from '../hooks/useTabRips';
import style from '../styles/detailedConsultation.module.scss'
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const TabRips = () => {

    const { idDocument } = useParams();
    const {callGetDataRips, downloadsRips, showButtonDowloadRips, ripDetails, isLoading} = useTabRips()

    useEffect(() => {
        callGetDataRips(idDocument ?? "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Fragment>
            {
                ripDetails.length > 0 ? (
                    <Fragment>
                        {
                            showButtonDowloadRips() && 
                                <Button 
                                    color='primary' 
                                    variant='contained' 
                                    sx={{display:"block", marginLeft: "auto", marginTop:"1rem", marginBottom:".5rem", borderRadius:100}}
                                    onClick={()=>downloadsRips(idDocument)}
                                    data-testid='btn-download-rips'
                                >
                                    Descargar Rips
                                </Button>
                        }
                        { 
                            ripDetails.map((item: any, index:number) =>
                                <div key={uuidv4()}>
                                    {
                                        item.id ? (
                                            !(_.isEmpty(item.content)) &&
                                                <div className={`${style.containerBody}`} data-testid={'tabs-details'}>
                                                    <div className='containerTitleCardTabs'>
                                                        <img src={item.icon} alt="" />
                                                        <Typography>{item.title}</Typography>
                                                    </div>
                                            
                                                    <Grid container direction={"row"} className={style.contentDetails}>
                                                    {
                                                        item.columns.map((column:any, index2:number) => {
                                                            return(
                                                                <Grid item xs={12} sm={column?.gridSizeSm ?? 4} md={column?.gridSizeMd ?? 3} className={style.field} key={uuidv4()} 
                                                                    data-testid={`grid_value_${column.field}`}
                                                                >
                                                                    <Typography className={style.titleDetails}>{column.headerName}: </Typography>
                                                                    <input data-testid={`input_value_${index2}`} className={style.inputDetails} type='text' disabled defaultValue={
                                                                        item.content[column.field]
                                                                    }/>
                                                                </Grid> 
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                </div>
                                        ) : (
                                            !(_.isEmpty(item.content) ) &&
                                                <Paper className={'containerTableResults'} key={uuidv4()} data-testid='container_table_rips'>
                                                    <div className='containerTitleCardTabs'>
                                                        <img src={item.icon} alt="" />
                                                        <Typography>{item.title}</Typography>
                                                    </div>
                                                        <DataGrid
                                                            rows={item.content}
                                                            columns={item?.columns?.map((column:any) => ({
                                                                ...column,
                                                                sortable: false,
                                                            }))}
                                                            className='tableResultsRips'
                                                            data-testid="datagrid-rips"
                                                            disableColumnFilter
                                                            disableColumnSelector
                                                            disableDensitySelector
                                                            disableColumnMenu={true}
                                                            hideFooterSelectedRowCount={true}
                                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                            hideFooter={true}
                                                        />
                                                    
                                                </Paper> 
                                        )
                                    }
                                </div>   
                            )
                        } 
                    </Fragment>
                ) : (
                    <Box className={style.containerResults}>Aún no se ha cargado la información correspondiente de esta sección</Box>
                )
            }
            {(isLoading) && <Spinner/>}
        </Fragment>
    )
}