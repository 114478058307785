import { Box, Grid, Input, Typography } from '@mui/material';
import style from '../styles/detailedConsultation.module.scss'
import { ComboBoxCustom } from 'shared/components/ComboBoxCustom';
import { RouterGuard } from 'shared/components';
import { accesRoles, IElectronicDocumentLine, IRespDocuments } from 'shared/models';
import { useTableResults } from 'shared/hooks/useTableResults';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentLinesTable } from "./DocumentLinesTable";
import { ProvitionServiceDateField } from './ProvitionServiceDateField';
import { LinePaymentField } from './LinePaymentField';
import { ViewDetailButton } from './ViewDetailButton';
import Image from 'shared/assets/index';
import { LABEL_ACTIVIDAD } from '../helpers';
import { findActivityStatus } from 'pages/read-documents/helper/findActivityStatus';
import { Fragment } from 'react';
import { ShowBalanceHistory } from './ShowBalanceHistory';
import styles from '../styles/showPayment.module.scss';

interface Props {
    detailedParameters: any;
    documentLines?: IElectronicDocumentLine[];
    showDocumentLines?: boolean;
    docState?:IRespDocuments,
}

export const TabDetails = ({ detailedParameters, documentLines, showDocumentLines, docState }: Props) => {

    const { handleUserAssignment, validateUserAuditor } = useTableResults();
    const { idDocument } = useParams();
    const navigate = useNavigate();
    
    return (
        <Fragment>
            {
                detailedParameters.length > 0 && detailedParameters.map((item: any, index: number) =>
                    <div className={`${style.containerBody} containerForm`} key={index} data-testid={'tabs-details'}>
                        <div className='containerTitleCardTabs'>
                            <img src={item.icon} alt="" />
                            <Typography>{item.title}</Typography>
                        </div>

                        {
                            item.hasContent ?
                                <Grid container direction={"row"} className={style.contentDetails}>
                                    {
                                        item.columns?.map((column: any, index: number) => {
                                            if (['assignedTo'].includes(column.idLabel)) {
                                                return (
                                                    <RouterGuard roles={accesRoles.usersSSSA} type="component" key={index}>
                                                        <Grid item xs={12} sm={4} md={3} className={style.field} key={index} >
                                                            <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                            <ComboBoxCustom
                                                                handleChange={handleUserAssignment}
                                                                data={item.content.users}
                                                                idsDoc={[Number(idDocument)]}
                                                                currentValue={item.content.currentUser}
                                                                placeholder='Asignar'
                                                                focusSelected={true}
                                                                isDisabled={docState?.state.code === "1.3" || docState?.state.code === "1.4" || validateUserAuditor()}
                                                            />
                                                        </Grid>
                                                    </RouterGuard>
                                                )
                                            }

                                            if (['contrato'].includes(column.idLabel)) {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index} data-testid='grid_contrato'>
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <div style={{ display: 'inline-flex' }}>
                                                            <label><input type='radio' name="contrato" value="Si" disabled checked={item.content.contrato == "Si"}></input>Si</label>
                                                            <label><input type='radio' name="contrato" value="No" disabled checked={item.content.contrato == "No"}></input>No</label>
                                                        </div>
                                                    </Grid>
                                                )
                                            }

                                            if (['fechaInicioServicios', 'fechaFinalServicios'].includes(column.idLabel)) {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index} data-testid='grid_fecha'>
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <ProvitionServiceDateField 
                                                            value={item.content[column.idLabel]}
                                                            property={column.idLabel}
                                                            index={index}
                                                            docState={docState}
                                                            />
                                                    </Grid>
                                                )
                                            }
                                            
                                            if (['lineaDePago'].includes(column.idLabel)) {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index}>
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <LinePaymentField
                                                            value={item.content[column.idLabel]}
                                                            property={column.idLabel}
                                                            index={index}
                                                            docState={docState}
                                                            />
                                                    </Grid>
                                                )
                                            }

                                            if (['saldoFactura'].includes(column.idLabel)) {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index}>
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <Box className={styles.containerInputPayment}>
                                                            <Box className={styles.inputEditonDetailsContainer} data-testid='content_line_payment'>
                                                                <input
                                                                    data-testid={`input_value_${index}`}
                                                                    className={styles.inputDetails}
                                                                    type='text' 
                                                                    disabled 
                                                                    value={item.content[column.idLabel]}
                                                                />
                                                                <ShowBalanceHistory docState={docState} />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )
                                            }

                                            if (['nroContrato'].includes(column.idLabel)) {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} className={style.field} key={index}>
                                                        <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                        <ViewDetailButton
                                                            index={index}
                                                            value={item}
                                                            column={column}
                                                            accesRoles={accesRoles.auditor}
                                                            titleTooltip='Ver detalle del  contrato'
                                                            onClickIcon={() => navigate(`/consulta_documentos_electronicos/detalle_documento/${idDocument}/ver_detalle_contrato/${item?.content[column.idLabel]}`) }
                                                            disabledButton={false}
                                                            typeIcon={Image.iconoVer}
                                                        />
                                                    </Grid>
                                                )
                                            }

                                            return (
                                                <Grid item xs={12} sm={4} md={3} className={style.field} key={index} >
                                                    <Typography className={style.titleDetails}>{column.label}: </Typography>
                                                    <Input
                                                        startAdornment={
                                                            column.label === LABEL_ACTIVIDAD &&
                                                            <span></span> 
                                                        }
                                                        data-testid={`input_value_${index}`} 
                                                        className={`${style.inputDetails} ${column.label === LABEL_ACTIVIDAD && style[`${findActivityStatus(docState)}T`]}`} type='text' 
                                                        disabled 
                                                        value={
                                                            item.content[column.idLabel]
                                                        }
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid> :
                                <Box className={style.containerResults}>Aún no se ha cargado la información correspondiente de esta sección</Box>
                        }

                    </div>

                )
            }
            {showDocumentLines ? <DocumentLinesTable documentLines={documentLines} /> : null}
        </Fragment>
    )
}
