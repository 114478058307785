import { Box, Checkbox, IconButton, Modal, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import {RootState } from 'shared/store/store';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../read-documents/styles/modalSelectStates.module.scss';
interface Props{
    onCloseModal: ()=> void;
    openModal: boolean;
    paymentStates: any;
    selectAllActivies: ()=> void;
    onSelected: (id:number)=> void;
}

export const ModalStatesPayments = ({ onCloseModal, openModal, paymentStates, onSelected, selectAllActivies  }: Props) => {

    const { invoiceStates } = useSelector((state: RootState) => state);

    const handleCloseModal = ()=> {
        onCloseModal()
    }

    
  return (
    <Modal
        open={openModal}
        onClose={onCloseModal}
        className={styles.modalStates}
        data-testid='modal-card-states'
    >
        <>
            <Box className={styles.container}>
                <Box className={styles.header}>
                    <Box className={styles.containerTitleClose}>
                        <Typography
                            className={styles.titleModal}
                            variant="h6"
                        >
                            Seleccionar estado
                        </Typography>
                        <IconButton
                            className={styles.buttonClose}
                            onClick={handleCloseModal}
                            data-testid='boton-cerrar'
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box className={styles.containerTable}>
                    {
                        invoiceStates.length > 0 &&
                            <>
                                <div className={styles.selectAllContainer}>
                                    <Checkbox
                                        disableRipple
                                        checked={paymentStates.every((state:any) => state.selected)}
                                        onClick={() => { selectAllActivies() }}
                                        inputProps={{
                                            'aria-label': `select_all_activities_checkbox`,
                                            // @ts-ignore
                                            'data-testid': 'select_all_activities_input-check',
                                        }}
                                    />
                                    <span className={styles.selectAllCheckboxText}>Seleccionar todo</span>
                                </div>
                                <Box
                                    className={styles.contenedorColapsable}
                                >
                                    <Table size="small" aria-label="purchases">
                                        <TableBody>
                                            {
                                                paymentStates.map((contenido:any, index:number) => (
                                                    <TableRow 
                                                        key={contenido.name} 
                                                        className={` ${index % 2 === (!(index % 2 === 0) ? 0 : 1) && styles.rowIntermediate}`}
                                                    >
                                                        <TableCell>
                                                            <Checkbox
                                                                disableRipple
                                                                className={styles.checkbox}
                                                                checked={contenido.selected}
                                                                onChange={() => {onSelected(contenido.id)}}
                                                                inputProps={{ 
                                                                    'aria-label': `checkbox_${contenido.name}`,
                                                                    // @ts-ignore
                                                                    'data-testid': `input-check-${index}`, 
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell data-testid={`contenido-colapsable${contenido.code}`}>{contenido.name}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </>
                    }
                </Box>
            </Box>
        </>
    </Modal>
  )
}
