
import { Button, Checkbox, FormControl, IconButton, InputLabel, MenuItem, Pagination, PaginationItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from '../../pages/read-documents/styles/tableResults.module.scss'
import { Box } from '@mui/system';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Images from 'shared/assets/index';
import { ITable } from './interfaces/table.interface';
import { RouterGuard } from './RouteGuard';
import { Spinner } from './Spinner';
import { SearchItems } from './paginator/SearchItems';
import { debounceFunction } from 'shared/utils';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import { useTableResults } from 'shared/hooks/useTableResults';
import { IParamsUrl } from 'shared/models';
import DownloadIcon from '@mui/icons-material/Download';

export const TableComponent = (props: ITable<any>)=> {

  const dispatch = useDispatch<Dispatch>();
  const { columns, title,  optionsTrazact,  valuesResultsPages, isDisabledButtonTraza,  downloadExcel,  
    rowSelected, selectAllRows,  selectRow, isStyleActivity = false, idTable, isSearch = true } = props
  const { electronicDocuments, isLoading, sizeRows, searcher, width, handleChangePage, handleChangeSizePage, handleSortByColumn, activityIsDefeated } = useTableResults();
  const { content, contentSize, totalElements, size, number, paramsUrl, url } = electronicDocuments;
  const { open, text } = searcher;
  const params: IParamsUrl = {
    ...paramsUrl,
    filter: text
  }
  const [ hasSearch, setHasSearch ] = useState(isSearch)

  const startIconContent = optionsTrazact?.(idTable ?? '');
  const buttonClassName = `${isDisabledButtonTraza ? styles.disabledButton : ''} ${styles.buttons}`.trim();

  const shouldDisplayFlex = optionsTrazact === undefined || downloadExcel === undefined;
  const getSectionClassName = () => {
    const baseClass = styles.contentButtonMobil;
    const flexClass = shouldDisplayFlex ? styles['displayFlex'] : '';
    return `${baseClass} ${flexClass}`.trim();
  };

  useEffect(() => {
    if (open && hasSearch) {
      debounceFunction(() => {
        dispatch.electronicDocuments.getElectronicDocuments(params, url)
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    (content && !hasSearch) && setHasSearch(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[content])

  return (
    <React.Fragment>
      <Box className={styles.headerTable}>
        {
          title && <p className={'titleMain'}>{title}</p>
        }
        <Box className={styles.searchContainer}>
          <span className={styles.searchItems}>
            <SearchItems data={content!} placeholder='Buscar' autocomplete={false} />
          </span>
          {
            width > 900 ?
              <section className={styles.contentButton}>
                {
                  downloadExcel &&
                    <Tooltip title='Exportar Facturas' placement="top" arrow>
                      <IconButton
                        className={styles.buttonExportExcel}
                        data-testid="btn-export"
                        onClick={downloadExcel}
                        >
                        <img className={styles.exportImage} src={Images.iconoDescargar} alt="Exportar a excel" />
                      </IconButton>
                    </Tooltip>
                  }
                { optionsTrazact && optionsTrazact?.(idTable ?? '') }
              </section>
            : 
            <section className={getSectionClassName()} data-testid='section-buttons-mobil'>
              {
                downloadExcel &&
                  <Button onClick={downloadExcel} className={styles.buttons} data-testid="btn-export-mobil" startIcon={<DownloadIcon />}>
                    Exportar Facturas
                  </Button>
              }
              {
                optionsTrazact &&
                  <Button disabled={isDisabledButtonTraza} data-testid="btn-mobil-traza"  className={buttonClassName} startIcon={startIconContent}>
                    Asignar
                  </Button>
              }
            </section>
          }
        </Box>
      </Box>

      <Box className={`${props.classTotalElemnt ? props.classTotalElemnt : styles.contenedorTotalResultados }`} data-testid='contenedor_para_desktop'>
        <p className={styles.resultadosTotales}>{contentSize}/{totalElements}</p>
      </Box>

      {
        totalElements > 0 ? (
          <Paper className={styles.containerTableResults} style={{display: 'flex', flexDirection:'column'}}>
            <TableContainer className={styles.tableResults}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table" data-testid="table-results">
                {
                  columns.length > 0 && (
                    <>
                      <TableHead>
                        <TableRow className={styles.tablaHeader}>
                          {
                            (rowSelected && selectAllRows) &&
                            <TableCell padding="checkbox" className={styles.tableCell}>
                              <Checkbox
                                color="primary"
                                indeterminate={rowSelected.length > 0 && rowSelected.length < contentSize}
                                checked={contentSize > 0 && rowSelected.length === contentSize}
                                onChange={selectAllRows}
                                inputProps={{
                                  'aria-label': 'select all docs',
                                }}
                                data-testid='checkbox-select-all'
                                id={'selectAll'}
                              />
                            </TableCell>
                          }
                          {
                            columns.map((column, index) => (
                              <React.Fragment key={column.field + '_' + index}>
                              {
                                column?.rowGuard  
                                ? 
                                <RouterGuard roles={column.rowGuard.roles} type="component">
                                  <TableCell
                                    align="left"
                                    className={styles.tableCell}
                                    style={{ minWidth: column.minWidth }}
                                    data-testid={`table-header-${column.field}`}
                                  >
                                    {column.headerName}
                                    {column.sortable && <img src={Images.iconoOrdenar2} alt={`sort_${index}`} onClick={() => handleSortByColumn(column.sortable)} />}
                                  </TableCell>
                                </RouterGuard>
                                :
                                <TableCell
                                  align="left"
                                  className={styles.tableCell}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.headerName}
                                  {column.sortable && <img src={Images.iconoOrdenar2} alt={`sort_${index}`} onClick={() => handleSortByColumn(column.sortable)} />}
                                </TableCell>
                              }
                              </React.Fragment>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody data-testid='table-results-body'>
                        {content!.map((row:any, index) => (
                          <TableRow
                            key={index}
                          >
                            {
                              selectRow && rowSelected &&
                              <TableCell padding="checkbox" 
                                style={{background: isStyleActivity && activityIsDefeated(row) ? '#ffc8c8' : ''}}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={rowSelected.indexOf(row.id) !== -1}
                                  inputProps={{
                                    'aria-labelledby': `check${index}`,
                                  }}
                                  onChange={(e) => selectRow(e, row.id)}
                                  className={styles.checkboxFiles}
                                  id={`check${index}`}
                                />
                              </TableCell>
                            }
                            {columns.map((column, index2) => (
                              <React.Fragment key={column.field + '_' + index2}>
                                {
                                  column.rowGuard  
                                  ? 
                                  <RouterGuard roles={column.rowGuard.roles} type="component">
                                    <TableCell
                                      align="left"
                                      className={`${styles.tableCell} ${column.isReduceCellPadding && styles.padding1}`}
                                      style={{background: isStyleActivity && activityIsDefeated(row) ? '#ffc8c8' : ''}}
                                      data-testid={`table-cell-${column.field}`}
                                    >
                                      { column.renderCell ? column.renderCell(row) : row }
                                    </TableCell>
                                  </RouterGuard>
                                  :
                                  <TableCell
                                    align="left"
                                    className={`${styles.tableCell} ${column.isReduceCellPadding && styles.padding1}`}
                                    key={column.field + index2}
                                    style={{background: isStyleActivity && activityIsDefeated(row) ? '#ffc8c8' : ''}}
                                    data-testid={`table-cell2-${column.field}`}
                                  >
                                    { column.renderCell ? column.renderCell(row) : row }
                                  </TableCell>
                                }
                              </React.Fragment>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>  
                    </>
                  )
                }
              </Table>
            </TableContainer>
            <Box className={styles.footerGrid}>
                <Box className={styles.contenedorResultadosPorPagina}>
                    <p>Resultados por página</p>
                    <FormControl fullWidth className={styles.containerSizeRows}>
                    <InputLabel id="select-size-label" htmlFor='select-size-name'>Cantidad</InputLabel>
                    <Select
                      labelId="select-size-label"
                      id="select-size"
                      data-testid='select-size-pagination'
                      value={sizeRows ?? ''}
                      label="Cantidad"
                      className={styles.selectSizeRows}
                      onChange={handleChangeSizePage}
                      name='select-size-name'
                      inputProps={{
                        id: 'select-size-name',
                      }}
                    >
                      {
                        valuesResultsPages.map(value=>
                          <MenuItem value={value} key={value} data-testid={`menu_item_${value}`}>{value}</MenuItem>
                        )
                      }
                    </Select>
                    </FormControl>
                </Box>
                <Pagination
                    className={styles.paginador}
                    data-testid='action-pagination'
                    page={number}
                    count={Math.ceil(totalElements / size)}
                    siblingCount={0}
                    boundaryCount={1}
                    renderItem={(item) => (
                      <PaginationItem
                          slots={{
                            previous: () => <Box className={styles.botonPaginador}><KeyboardArrowLeft /><p>Ant.</p></Box>,
                            next: () => <Box className={styles.botonPaginador}><p>Sig.</p><KeyboardArrowRight /></Box>
                          }}
                          {...item}
                      />
                    )}
                    onChange={(e, page) => { handleChangePage(e, page) }}
                />
            </Box>
          </Paper>
        ): (
          <Box className={styles.containerResults} data-testid='message_no_search'>No se encontraron resultados relacionados a su búsqueda</Box>
        )

      }
      {(isLoading) && <Spinner />}
    </React.Fragment>
  )
}