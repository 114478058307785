export const columnsFieldsSupports = [
    {
        field: 'name',
        headerName: 'Nombre del soporte',
        minWidth: 230,
    },
        
    {
        field: 'abbreviation',
        headerName: 'Abreviatura',
        minWidth: 120,
    },

    {
        field: 'nameFile',
        headerName: 'Nombre del archivo',
        minWidth: 220,
    },

    {
        field: 'example',
        headerName:'EJEMPLO',
        minWidth: 180,
        disableReorder: true
    },

    {
        field: 'required',
        headerName: 'OBLIGATORIEDAD',
        minWidth:160
    }

]


export const fieldsSupports = [
    {
        name: 'Factura de venta en salud',
        abbreviation: 'No aplica',
        nameFile: 'NIT_NUMEROFACTURA',
        example: '99999999_FE238.pdf',
        required: 'Obligatorio'
    },
    {
        name: 'Resumen de atención u hoja de evolución',
        abbreviation: 'HEV_',
        nameFile: 'HEV_NIT_NUMEROFACTURA',
        example: 'HEV_99999999_FE238.pdf',
        required: 'Obligatorio'
    },
    {
        name: 'Epicrisis',
        abbreviation: 'EPI_',
        nameFile: 'EPI_NIT_NUMEROFACTURA',
        example: 'EPI_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Resultado de los procedimientos de apoyó diagnóstico',
        abbreviation: 'PDX_',
        nameFile: 'PDX_NIT_NUMEROFACTURA',
        example: 'PDX_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Descripción quirúrgica',
        abbreviation: 'DQX_',
        nameFile: 'DQX_NIT_NUMEROFACTURA',
        example: 'DQX_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Registro de anestesia',
        abbreviation: 'RAN_',
        nameFile: 'RAN_NIT_NUMEROFACTURA',
        example: 'RAN_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Comprobante de recibido del usuario',
        abbreviation: 'CRC_',
        nameFile: 'CRC_NIT_NUMEROFACTURA',
        example: 'CRC_99999999_FE238.pdf',
        required: 'Obligatorio'
    },
    {
        name: 'Traslado asistencial de pacientes',
        abbreviation: 'TAP_',
        nameFile: 'TAP_NIT_NUMEROFACTURA',
        example: 'TAP_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Transporte no asistencial ambulatorio de la persona',
        abbreviation: 'TNA_',
        nameFile: 'TNA_NIT_NUMEROFACTURA',
        example: 'TNA_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Prestación de servicios una vez agotada la cobertura de SOAT, ADRES o la entidad que haga sus veces',
        abbreviation: 'FAT_',
        nameFile: 'FAT_NIT_NUMEROFACTURA',
        example: 'FAT_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Factura de venta del material de osteosíntesis expedida por el proveedor',
        abbreviation: 'FMO_',
        nameFile: 'FMO_NIT_NUMEROFACTURA',
        example: 'FMO_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Orden o prescripción facultativa',
        abbreviation: 'OPF_',
        nameFile: 'OPF_NIT_NUMEROFACTURA',
        example: 'OPF_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Lista de Precios',
        abbreviation: 'LDP_',
        nameFile: 'LDP_NIT_NUMEROFACTURA',
        example: 'LDP_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Hoja de atención de urgencia',
        abbreviation: 'HAU_',
        nameFile: 'HAU_NIT_NUMEROFACTURA',
        example: 'HAU_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Hoja de atención odontológica',
        abbreviation: 'HAO_',
        nameFile: 'HAO_NIT_NUMEROFACTURA',
        example: 'HAO_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Hoja de administración de medicamentos',
        abbreviation: 'HAM_',
        nameFile: 'HAM_NIT_NUMEROFACTURA',
        example: 'HAM_99999999_FE238.pdf',
        required: 'Opcional'
    },
    {
        name: 'Evidencia del envió del tramite respectivo ',
        abbreviation: 'PDE_',
        nameFile: 'PDE_NIT_NUMEROFACTURA',
        example: 'PDE_99999999_FE238.pdf',
        required: 'Opcional'
    },
]

export type ISupport ={
    name: string,
    abbreviation: string,
    nameFile: string,
    example: string,
    required: string
}