import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import { Spinner } from 'shared/components/Spinner';
import styles from '../styles/modalRipsSoportes.module.scss'
import { CustomFileUploader } from 'shared/components';
import { activities } from 'shared/models/lineStates';
import { useLoadRips } from '../hooks/useLoadRips';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared';
import { RESOLUTION_2275_2023, RESOLUTION_3374_2000 } from '../helper/resolutions';
import { EResponseActivityStatus } from 'shared/constants/api.enum';

interface Props {
  idDoc: any;
  isHadleSubmit: boolean,
  setIsHadleSubmit: React.Dispatch<React.SetStateAction<boolean>>,
  setIsNextPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const LoadRips = ({idDoc, isHadleSubmit, setIsHadleSubmit, setIsNextPage}:Props) => {
    
  const {
    id,
    originalName,
    nameNewsRips,
    billingState,
    resolution3374Document,
    resolution2275Document,
    isRipsValidate,
    isLoading,
    typeAccept,
    activityStatus,
    handleChangeFile,
    handleOnclickUpload,
    removeFile,
    setIsRipsValidate,
  } = useLoadRips({idDoc,setIsNextPage})
    
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (!isHadleSubmit) return;

    const action = isRipsValidate && !id ? () => setIsNextPage(true) : handleOnclickUpload;
    action();

    setIsHadleSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isHadleSubmit])

  useEffect(() => {
    const isRips = billingState.code === activities.RIPSValidados;
    setIsRipsValidate(isRips);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingState])   

  useEffect(() => {
    return() => {
      dispatch.linkDocument.cleanState();
      dispatch.linkDocument.cleanStateNameNewsRips();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.containerUploadRips} data-testid='box_container_upload'>
      <Box className={styles.containerRips}>
        <Box className={styles.contentUploads}>
          {
            isRipsValidate ? (
              <Typography className={styles.textLabel} data-testid='text_currently'>Actualmente tiene RIPS cargados <b> según Resolución 3374 </b> en caso que desee reemplazarlos carguelos nuevamente.</Typography>
            ) : (
              <Typography className={styles.textLabel} data-testid='text_upload'>Cargue RIPS en formato <b> .ZIP según Resolución 3374 </b></Typography>
            )
          }
          <CustomFileUploader 
            fileLength={5}
            originalName={originalName}
            typeAccept={typeAccept}
            document={resolution3374Document}
            handleChangeFile={(file)=>handleChangeFile(file,RESOLUTION_3374_2000)}
            removeFile={removeFile}
          />
        </Box>
        <Box className={styles.separatorUploads}></Box>
        <Box className={styles.contentUploads}>
          {
            isRipsValidate ? (
              <Typography className={styles.textLabel} data-testid='text_currently_2275'>Actualmente tiene RIPS cargados <b> según Resolución 2275 JSON y CUV </b> en caso que desee reemplazarlos carguelos nuevamente.</Typography>
            ) : (
              <Typography className={styles.textLabel} data-testid='text_upload_2275'>Cargue RIPS Segun Resolución 2275 el <b> JSON y CUV dentro de un archivo en formato .ZIP </b></Typography>
            )
          }
          <CustomFileUploader 
            fileLength={5}
            originalName={nameNewsRips}
            typeAccept={typeAccept}
            document={resolution2275Document}
            handleChangeFile={(file)=>handleChangeFile(file, RESOLUTION_2275_2023)}
            removeFile={removeFile}
            isDisabled={activityStatus?.name === EResponseActivityStatus.FAIL}
          />
        </Box>

      </Box>
      {(isLoading) && <Spinner/>}
    </Box>
  )
}
