import React, { useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { IIframe } from 'shared/models/store/IIframes';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import styles from './styles/iframes.module.scss'
import { apiInstance } from 'shared/api/config/apiInstance';
import { WEBSITES } from 'shared/api';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'shared/store/store';

interface Props{
    iframe: IIframe
}

export const IframeItem = ({iframe}: Props) => {
    const dispatch = useDispatch<Dispatch>();
    const [openModal, setOpenModal] = useState(false);

    const deleteIframe = async()=> {
        await apiInstance.put(`${WEBSITES}/${iframe.id}`, {
            name: ' ',
            link: ' ',
            delete: true    
        }).then(()=>{
            dispatch.iframes.getIframeListAsync();
        })
    }

  return (
    <Box className={styles.itemIframe}>
        <Box className={styles.itemTitle}>
            <InsertLinkIcon />
            <Typography>{iframe.title}</Typography>
        </Box>
        <Box className={styles.itemActions}>
            <IconButton aria-label="delete" onClick={()=>setOpenModal(true)} data-testid='button_open_iframe'>
                <OpenInNewIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={()=> deleteIframe()} data-testid='button_delete_iframe'>
                <DeleteIcon />
            </IconButton>
        </Box>

        <Modal 
            open={openModal} 
            onClose={()=>setOpenModal(false)} 
            className={styles.modal} 
            data-testid='modal_iframes'
        >
            <Box className={styles.modalContainerIframes}>
                <Box className={styles.contentIframe}>
                    <IconButton aria-label="close" className={styles.btnCloseIframe} onClick={()=>setOpenModal(false)} data-testid='button_close_iframe'>
                        <CloseFullscreenIcon />
                    </IconButton>
                    <Typography className={styles.title} data-testid="title-iframes">{iframe.title}</Typography>
                    <iframe
                        src={iframe.url} height="100%" width="100%"
                        // sandbox='allow-scripts allow-modals allow-popups'
                    >Cargando sitio web ...</iframe>
                </Box>
            </Box>
        </Modal>
    </Box>
  )
}
