import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, GET_ELECTRONIC_DOCS, RootState, apiInstance } from 'shared';
import { downloadDocument, downloadsDescription, downloadsTitle } from 'shared/utils';
import Swal from 'sweetalert2';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { ILoadRips } from '../model/ILoadRips';
import { RESOLUTION_2275_2023, RESOLUTION_3374_2000 } from '../helper/resolutions';

export const useLoadRips = ({ idDoc, setIsNextPage }: ILoadRips ) => {

  const dispatch = useDispatch<Dispatch>();
  const { callGetDetailedDocument } = useDetailedConsultation();
  const { id, originalName, nameNewsRips } = useSelector((state: RootState) => state.linkDocument);
  const { billingState, activityStatus} = useSelector((state: RootState) => state.auditDetails);
  const [ resolution3374Document, setResolution3374Document ] = useState<any>(null);
  const [ resolution2275Document, setResolution2275Document ] = useState<any>(null);
  const [isRipsValidate, setIsRipsValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [typeResolutionSet, setTypeResolutionSet] = useState<string>('');
  const [responseRips, setResponseRips] = useState<any>(null);
  const typeAccept = ["ZIP"];

  useEffect(() => {
    if(id && typeResolutionSet){ postRips() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id])

  useEffect(() => {
    return() => {
      setResponseRips(null);
    }
  },[])

  const postRips = async() => {
    await apiInstance.post(`${GET_ELECTRONIC_DOCS}/${idDoc}/rips`, {
      attachmentId: id,
      typeResolution: typeResolutionSet
    })?.then((response) => {
      setResponseRips(response);
    })
    .catch((error) => removeFile())
    .finally(()=> setIsLoading(false) );
  }

  const handleChangeFile = (file: any, typeResolution: string) => {
    setTypeResolutionSet(typeResolution);
    setIsLoading(true);
    if (file && file.size < (5000000) && file.name.split('.').pop() === "zip" && typeResolution === RESOLUTION_2275_2023) {
      setResolution2275Document(file);
      getLink(file, typeResolution)
    }
    if (file && file.size < (5000000) && file.name.split('.').pop() === "zip" && typeResolution === RESOLUTION_3374_2000) {
      setResolution3374Document(file);
      getLink(file, typeResolution)
    }
  };

  const getLink = async(file:any, typeResolution: string ) => {
    dispatch.linkDocument.getLinkAsync({
      file: file, 
      url: `${GET_ELECTRONIC_DOCS}/${idDoc}/rips/generate-upload-link`,
      typeResolution
    })
    ?.then()
    .catch((error) => {    
      setIsLoading(false);
      setResolution3374Document(null);
      setResolution2275Document(null);
    })
  }

  const handleOnclickUpload = async() => {
    if(id){
      if(responseRips?.status === 206){
        downloadDocument(responseRips, downloadsTitle.errorRips, downloadsDescription.errorRips, 'error')
      } else {
        dispatch.linkDocument.cleanAllState();
        callGetDetailedDocument(idDoc || '');
        setIsNextPage(true);
      }
    } else {
        Swal.fire({
        icon: "error",
        html: `Debe cargar archivo RIPS`,
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  }

  const removeFile = () => {
    setResponseRips(null);
    if(typeResolutionSet === RESOLUTION_2275_2023){
      setResolution2275Document(null);
      dispatch.linkDocument.cleanStateNameNewsRips();
    }

    if(typeResolutionSet === RESOLUTION_3374_2000){
      setResolution3374Document(null);
      dispatch.linkDocument.cleanState();
    }
  };

  return {
    id,
    originalName,
    billingState,
    resolution3374Document,
    resolution2275Document,
    isRipsValidate,
    isLoading,
    typeAccept,
    activityStatus,
    handleChangeFile,
    handleOnclickUpload,
    removeFile,
    setIsRipsValidate,
    nameNewsRips,
  }
}
