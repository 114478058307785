import React, { Fragment, useEffect, useRef } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { RichTextEditor, Spinner } from 'shared/components';
import { InnerCountDown } from 'shared/components/InnerCountDown';
import styles from '../styles/modalResetAudit.module.scss'
import { useModalReset } from '../hooks/useModalReset';
import { IDropdown } from 'shared';

interface Props {
    id: number;
    bill: string;
    onClose: ()=> void,
    callGetDetailedDocument: (idDocument: string) => void
}

const MemoInnerCountDown = React.memo(InnerCountDown);

export const ModalResetAudit = ({id, bill, onClose, callGetDetailedDocument}: Props) => {

    const {isLoading, activeCode, activeIndexOtp, totalCaracteres, isDisableInputCode, otp, maxLength, richTextValue, onActiveResetAudit, errorInputOtp, auditCriteria, auditCriteriaList, setAuditCriteria,  
        onChangeRichText, sendCodeResetAudit, onResetAudit, onSetIsDisableInputCode, handleOnChangeCode, handleKeyDown, cancelCodeOtp, onSetDisableConfirmButton, onSetRichTextValue} = useModalReset( id, callGetDetailedDocument, onClose);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        onSetRichTextValue('<p><br></p>');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        inputRef.current?.focus();
    }, [activeIndexOtp]);

  return (
    <>
        <Box className={styles.informationContainer} data-testid="modal-reset-audit">
            <ErrorOutlineIcon className={styles.iconWarning}/>
            <Typography className={styles.titleWarning}>¡Atención!</Typography>

            { activeCode ? (
                <section className={styles.contentConfirmation}>
                    <Typography variant='body2' className={styles.descriptionText} data-testid="modal-counter-code">Por favor ingrese el código de confirmación enviado al correo electrónico de su supervisor, recuerde que este código vence en 30 minutos.</Typography>
                    <Typography variant='body2' className={styles.highlightedText}>
                        <MemoInnerCountDown setDisableInput={onSetIsDisableInputCode}/>
                    </Typography>
                    <Box className={styles.containerOtp}>
                        {
                            otp.map((_, index:any) => {
                                return (
                                    <React.Fragment key={index}>
                                        <input
                                            ref={index === activeIndexOtp ? inputRef : null}
                                            type="number"
                                            className={`${styles.inputOtp} ${errorInputOtp ? styles.inputOtpError : ''}`}
                                            onChange={(e)=> handleOnChangeCode(e, index)}
                                            onKeyDown={(e)=> handleKeyDown(e,index)}
                                            value={otp[index]}
                                            disabled={isDisableInputCode}
                                            data-testid={`input-otp-${index}`}
                                        />
                                        {
                                            index === otp.length - 1 ? null : (
                                                <span className={styles.separatorOtp} data-testid={`span-otp-${index}`} />
                                            )
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </Box>
                </section>
                )
                : (
                    <section className={styles.contentDetail}>
                        <Typography variant='body2' className={styles.descriptionText} data-testid="modal-text-editor">Ingrese el detalle para reiniciar auditoría de la factura {bill}</Typography>
                        <Typography variant='body2' className={styles.highlightedText}>Al confirmar se enviara un correo a su supervisor con un código de confirmación el cual deberá informar</Typography>
                        <Box className={styles.textRichDetailsContainer}>
                            <Box className={styles.textRichContainer}>
                                    <RichTextEditor
                                        onChange={onChangeRichText}
                                        readOnly={false}
                                        value={richTextValue}
                                        isHeightAuto={true}
                                    />
                            </Box>
                            <span className='details medium'>{totalCaracteres}/{maxLength}</span>
                        </Box>

                        <Box className={styles.selectCriteria}>
                            <label htmlFor={"1"}>
                                Criterio de Reinicio de Auditoría
                            </label>
                            <select 
                                placeholder={"Criterio de Reinicio de Auditoría"}
                                id={"1"}
                                value={auditCriteria}
                                onChange={(event) => {setAuditCriteria(event.target.value)}}
                                data-testid='select_audit_criteria'
                            >
                                <option value="">--</option>
                                {
                                    auditCriteriaList.length > 0 &&
                                        auditCriteriaList?.map((opcion: IDropdown) => (
                                            <option key={opcion.id} value={opcion.id} data-testid={`option-select-${opcion.id}`}>
                                                {opcion.name}
                                            </option>
                                        ))
                                }
                            </select>
                        </Box>

                        <Box className={styles.checkboxContainer}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        disableRipple 
                                        className={styles.checkbox}
                                        onChange={(event) => { onSetDisableConfirmButton(!event.target.checked) }}
                                        inputProps={{
                                            // @ts-ignore
                                            'data-testid': 'select_confirm_button',
                                        }}
                                    />
                                }
                                label={<span className={styles.checkboxLabel}>Confirmo que deseo reiniciar la auditoría</span>}
                            />
                        </Box>
                    </section>

                )
            }

            <Box className={styles.buttonsContainer}>
                <Grid container direction={"row"} justifyContent={'center'} className='buttonContainer'>
                    <Button
                        variant='contained'
                        color='secondary' 
                        className='buttonSubmit big'
                        data-testid='submit-reset-audit'
                        onClick={activeCode ? onResetAudit : sendCodeResetAudit}
                        disabled={activeCode ? onActiveResetAudit : false}
                    >
                        Reiniciar auditoría
                    </Button>
                    <Button
                        variant='contained'
                        color='secondary' 
                        className='buttonCancelar big' 
                        data-testid='cancel-reset-audit'
                        onClick={activeCode ? cancelCodeOtp : onClose}
                    >
                        Cancelar
                    </Button>
                </Grid>    
            </Box>
            {isLoading && <Spinner/>}
        </Box>
    </>
  )
}