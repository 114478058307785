import './styles/_spinnerText.scss'

export const SpinnerText = () => {
  return (
    <div className="loading-container" data-testid="loading-spinner">
      <span className="loading-text" data-testid="loading-text">
        <span className="loading-word">Cargando</span>
        <span className="loading-dot">.</span>
        <span className="loading-dot">.</span>
        <span className="loading-dot">.</span>
      </span>
    </div>
  )
}

