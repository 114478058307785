import { useState } from 'react';
import { TableRow, TableCell, IconButton, Collapse, Box, Table, TableBody, Checkbox } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IRowTableStates } from '../model/IModalStates';
import styles from '../styles/modalSelectStates.module.scss';
import { IActivities } from 'shared/models/store/IInvoiceStates';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';

export const RowStatesCollapsable = ({ 
    name, 
    content, 
    head,
}: IRowTableStates) => {

    const dispatch = useDispatch<Dispatch>();
    const { invoiceStates } = useSelector((state: RootState) => state);
    const [abrir, setAbrir] = useState(false);

    const onSelected = (id:number) => {
      dispatch.invoiceStates.changesStatesSelected({state: invoiceStates,id});
    }

    const onByActivitySelected = (activity: string) => {
      dispatch.invoiceStates.changesAllStatesByActivitySelected({state: invoiceStates}, activity);
    }

    return (
        <>
            <TableRow className={`${styles.rowCollapsible} ${head && styles.rowIntermediate}`}>
                <TableCell data-testid={'name-colapsable'}
                    style={{ color: content.every(({ isDisabled }) => isDisabled) ? '#6d6d6d73' : '' }}
                >
                    <Checkbox
                        disableRipple
                        className={styles.checkbox}
                        checked={content.every(({ selected, isDisabled }) => selected && !isDisabled)}
                        onClick={() => { !content.some(({ isDisabled }) => isDisabled) && onByActivitySelected(name) }}
                        inputProps={{
                            'aria-label': `activity_checkbox_${name}`,
                            // @ts-ignore
                            'data-testid': 'activity_input-check'
                        }}
                        disabled={content.every(({ isDisabled }) => isDisabled)}
                    />
                    {name}
                </TableCell>
                <TableCell>
                    <IconButton
                        className={styles.btnOpenCollapsible}
                        onClick={() => setAbrir(!abrir)}
                        data-testid={`boton-colapsable-${name}`}
                    >
                        {abrir ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={2}>
                    <Collapse in={abrir} timeout="auto" unmountOnExit>
                        <Box
                            className={styles.contenedorColapsable}
                            data-testid={`colapsable-${name}`}
                        >
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {
                                        content.map((contenido:IActivities, index:number) => (
                                            <TableRow 
                                                key={contenido.titulo} 
                                                className={` ${index % 2 === (!head ? 0 : 1) && styles.rowIntermediate}`}
                                                data-testid={`row-colapsable${contenido.id}`}
                                            >
                                                <TableCell>
                                                    <Checkbox
                                                        disableRipple
                                                        className={styles.checkbox}
                                                        checked={contenido.selected && !contenido.isDisabled}
                                                        onChange={() => {onSelected(contenido.id)}}
                                                        inputProps={{ 
                                                            'aria-label': `checkbox_${name}`,
                                                            // @ts-ignore
                                                            'data-testid': `input-check-${contenido.id}`, 
                                                        }}
                                                        disabled={contenido.isDisabled}
                                                    />
                                                </TableCell>
                                                <TableCell data-testid={`contenido-colapsable${contenido.id}`}>
                                                    {contenido.titulo}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}