import { IconButton, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'shared/store/store';
import styles from './styles/ModalMessage.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { memo } from 'react';

export const ModalMessageComponent = memo(() => {

    const messageModal = useSelector((state: RootState) => state.messageModal);
    const dispatch = useDispatch<Dispatch>();

    return (
        <Modal
            open={messageModal?.show || false}
            onClose={()=> { 
                dispatch.messageModal.cleanStateMessage() 
                messageModal?.onClose?.()
            }}
            className={styles.modalStates}
            data-testid='modal_message'
            style={{ display: messageModal?.hide ? 'none' : '' }}
        >
            <Box className={`${styles.container} ${styles[messageModal?.size || '']}`} data-testid='container_box'>
                <section className={styles.header}>
                    <Typography
                        className={styles.titleModal}
                    >
                        {messageModal?.title}
                    </Typography>
                    {
                        (messageModal?.isShowBtnClose === undefined || messageModal?.isShowBtnClose === true) ? 
                            <div>
                                <IconButton
                                    className={styles.buttonClose}
                                    onClick={messageModal?.onClose}
                                    data-testid='boton_cerrar'
                                >
                                    <CloseIcon />
                                </IconButton>

                            </div>
                            :
                            <div style={{width: '8%'}}></div>
                    }
                </section>
                <section className={styles.description} data-testid='content_description'>
                {
                    typeof messageModal.description != "string" ? (
                        messageModal?.description
                    ) : (
                        <p className={styles.textDescription} data-testid='text_description'>{messageModal.description}</p>
                    )
                }
                </section>
                <section className={styles.footerButton}>
                    {
                        messageModal?.OkTitle ? 
                            (() => {
                                const handleClick = messageModal.onOk 
                                    ? messageModal.onOk
                                    : () => dispatch.messageModal.cleanStateMessage();
                    
                                return (
                                    <button 
                                        className={styles.buttonOk}
                                        onClick={handleClick}
                                        data-testid='button_ok'
                                    >
                                        { messageModal?.OkTitle }
                                    </button>
                                );
                            })()
                        : <></>

                    }
                    {
                        messageModal?.cancelTitle ? 
                            (() => {
                                const handleCancelClick = messageModal.onCancel
                                    ? messageModal.onCancel
                                    : () => dispatch.messageModal.cleanStateMessage();
                    
                                return (
                                    <button 
                                        className={styles.buttonCancel}
                                        onClick={handleCancelClick}
                                        data-testid='button_cancel'
                                    >
                                        { messageModal?.cancelTitle }
                                    </button>
                                );
                            })()
                        : <></>
                    }
                </section>
            </Box>
        </Modal>

    )
})
