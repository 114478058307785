export const transformNameSupportToId = (name:string) => {
    const regex = /^\d{4}/;
    if(regex.test(name)) return '1'
    switch (name) {
        case 'HEV_':
            return '2';
        case 'EPI_':
            return '3';
        case 'PDX_':
            return '4';
        case 'DQX_':
            return '5';
        case 'RAN_':
            return '6';
        case 'CRC_':
            return '7';
        case 'TAP_':
            return '9';
        case 'TNA_':
            return '10';
        case 'FAT_':
            return '11';
        case 'FMO_':
            return '8';
        case 'OPF_':
            return '12';
        case 'LDP_':
            return '13';
        case 'HAU_':
            return '14';
        case 'HAO_':
            return '15';
        case 'HAM_':
            return '16';
        case 'PDE_':
            return '17';
        
    
        default:
            return null
    }

}