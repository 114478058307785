import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'
import { getData } from 'shared/api/services';
import { GET_BALANCE_HISTORY, GET_ELECTRONIC_DOCS } from 'shared';
import { useParams } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useShowBalanceHistory = () => {

    const { idDocument } = useParams();
    const [ activePayments, setActivePayments ] = useState(false);
    const [ dataPayments, setDataPayments ] = useState<any[]>([]);

    const convertDataPayments = (data:any) => {
        setDataPayments(
            data.map((data: any, index:number) => {
                return {
                    id: index,
                    name: data.transactionType,
                    createdAt: dayjs(data.transactionDate).tz('America/Bogota').format('DD MMMM YYYY'),
                    value: `$${Number(data.amount).toLocaleString('es-CO')}`,
                    balance: `$${Number(data.accumulatedBalance).toLocaleString('es-CO')}`
                }
            })
        )
    }

    const handleActivePayments = (value:boolean) => {
        setActivePayments(value);
    }

    const callDataBalanceHistory = ()=> {
        getData( `${GET_ELECTRONIC_DOCS}/${idDocument}${GET_BALANCE_HISTORY}`)?.then((response: any) => {
            response?.length > 0 && convertDataPayments(response)
        })
    }

    useEffect(() => {
        callDataBalanceHistory()
    }, [])
    

    return {
        dataPayments,
        activePayments,
        handleActivePayments,
        callDataBalanceHistory,
        convertDataPayments
    }
}
