export const LABEL_ACTIVIDAD = 'Actividad';

export const contentDataDocument: typeContentDataDocument[] = [
    { label: 'Nro. de factura', idLabel: 'nroFactura' },
    { label: 'Fecha de expedición', idLabel: 'fechaExpedicion' },
    { label: 'Código único de factura', idLabel: 'cunFactura' },
    { label: 'Nro. de Radicado', idLabel: 'numeroRadicado' },
    { label: 'Fecha Radicado', idLabel: 'fechaRadicado' },
    { label: 'Estado', idLabel: 'estado' },
    { label: LABEL_ACTIVIDAD, idLabel: 'actividad' },
    { label: 'Contrato', idLabel: 'contrato' },
    { label: 'Nro. Contrato', idLabel: 'nroContrato' },
    { label: 'Línea de pago', idLabel: 'lineaDePago' },
    { label: 'Fecha inicial de prestación de servicios', idLabel:'fechaInicioServicios'},
    { label: 'Fecha final de prestación de servicios', idLabel:'fechaFinalServicios'},
    { label: 'Asignada A', idLabel: 'assignedTo'},
    { label: 'Total Factura', idLabel: 'totalFactura'},
    { label: 'Total Autorizaciones de Pago', idLabel: 'authorizedPaymentValue'},
    { label: 'Saldo de la Factura', idLabel: 'saldoFactura'},
    { label: 'Valor Cuota de Recuperación', idLabel:'valorCuotaRecuperacion'},
    { label: 'Valor Recuperado', idLabel:'valorRecuperado'}
]
export const contentDataPS: typeContentDataDocument[] = [
    { label: 'Nit', idLabel: 'nitEmisor' },
    { label: 'Razón Social', idLabel: 'razonSocial' },
    { label: 'Código del Prestador', idLabel: 'codigoPrestador' },
    { label: 'Nombre del IPS o Sede', idLabel: 'nombreComercial' },
    { label: 'Tipo de contribuyente', idLabel: 'tipoContribuyente' },
    { label: 'Régimen Fiscal', idLabel: 'regimenFiscal' },
    { label: 'Responsabilidad Tributaria', idLabel: 'responsabilidadTributaria' },
    { label: 'Actividad Económica', idLabel: 'actividadEconómica' },
    { label: 'Teléfono/ Móvil', idLabel: 'telefonoMovil' },
    { label: 'Dirección', idLabel: 'direccion'},
    { label: 'Municipio - Departamento', idLabel: 'municipio'},
    { label: 'Correo', idLabel: 'email'},
]
export const contentDataAcquirer: typeContentDataDocument[] = [
    { label: 'Tipo de documento', idLabel: 'tipoDocumento' },
    { label: 'Nro. Documento', idLabel: 'nroDocumento' },
    { label: 'Tipo de usuario', idLabel: 'tipoUsuario' },
    { label: 'Primer Apellido del Usuario', idLabel: 'primerApellido'},
    { label: 'Segundo Apellido del Usuario', idLabel: 'segundoApellido'},
    { label: 'Primer Nombre del Usuario', idLabel: 'primerNombre'},
    { label: 'Segundo Nombre del Usuario', idLabel: 'segundoNombre'},
    { label: 'Edad', idLabel: 'edad'},
    { label: 'Tipo de Edad', idLabel: 'tipoEdad'},
    { label: 'Sexo', idLabel: 'sexo'},
    { label: 'Departamento', idLabel: 'departamento'},
    { label: 'Municipio', idLabel: 'municipio'},
    { label: 'Zona', idLabel: 'zona'},
]

export type typeContentDataDocument = {
    label: string,
    idLabel: string,
}
