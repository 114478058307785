import { IGlosasEDocumentsResponse, IGlosasResponse, IGlosasResponseConvert } from "shared/models"

export const convertGlossasDocument = (options: IGlosasResponse):IGlosasResponseConvert => {
    const glosses = options ? options.glossesEDocuments.map((state:IGlosasEDocumentsResponse) => {
        return {
            id: state.id,
            idGlosa: state.gloss.id,
            codeGlosa: state.gloss.code,
            nameGlosa: state.gloss.name,
            valueGlosa: state.valueGloss,
            statusGlosa: {
                id: state.state.id,
                name: state.state.name,
                code: state.state.code
            },
            justification: state.justification,
            valueAccept: state.valueAccept,
            valueSustained: state.valueSustained,
            valueNotAccept: state.valueNotAccept,
            glossAnswer: state.glossAnswer,
            answerConsolited: state.answerConsolited,
            glossSupport: state.glossSupport,
            glossStateDays: state.glossStateDays,
        }
    }) : []
    return{
        glossesEDocuments: glosses,
        glossesTotalValue: options.glossesTotalValue,
        allGlossConsolidated: options.allGlossConsolidated,
        sumValuesAllValueAccept: options.sumValuesAllValueAccept || 0,
        sumValuesAllValueSustained: options.sumValuesAllValueSustained || 0,
        sumValuesAllValueNotAccept: options.sumValuesAllValueNotAccept || 0,
        edocumentPaymentAuthorization: {
            createdAt: options.edocumentPaymentAuthorization.createdAt,
            createdBy: options.edocumentPaymentAuthorization.createdBy,
            glossesGeneralDetail: options.edocumentPaymentAuthorization.glossesGeneralDetail
        },
    }
}