/* eslint-disable react-hooks/rules-of-hooks */
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Spinner } from 'shared/components';
import Image from 'shared/assets/index'
import { useParams } from 'react-router-dom';
import { Dispatch, RootState } from 'shared/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { IGlosa } from 'shared/models';
import { contentGridGlosas } from '../helpers/contentGridGlosas';
import { useDetailedConsultation } from 'pages/detailed-consultation/hooks/useDetailedConsultation';
import { CellRenderExpand } from './CellRenderExpand';
import Swal from 'sweetalert2';
import styles from '../styles/ConsolidateGlosas.module.scss';

export const GridGlosas = () => {

    const {idDocument}= useParams();
    const dispatch = useDispatch<Dispatch>();
    const { consolidateGlosas } = useSelector((state: RootState) => state);
    const { glosas, glossesTotalValue } = consolidateGlosas
    const { callGetDetailedDocument } = useDetailedConsultation();
    const { loading } = useSelector((rootState: RootState) => rootState.loading.models.consolidateGlosas);
    
    const updateGlosas = (col:any) => dispatch.consolidateGlosas.updateGlosas(col);

    const deleteGlosa = (id:any) =>{
        const paramsGlosa = {
          electronicDocumentId: Number(idDocument),
          id: id,
          status: "I"
        }

        dispatch.consolidateGlosas.updateGlosasAsync(paramsGlosa)?.then(()=>{
            Swal.fire({
              title: "Glosa eliminada",
              icon: "success",
              timer: 5000,
            }).then(()=>{
                callGetDetailedDocument(idDocument || '');
            })
        })

    } 
    
  return (
    <>
        {
            glosas && 
                glosas.length > 0 ?
                <Box>
                    <TableContainer
                        className={styles.gridGlosasResults}
                        data-testid="grid-glosas"
                    >
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow className={styles.tableHeader}>
                                    {contentGridGlosas.map((col: any, i: number) => (
                                    <TableCell
                                        key={i}
                                        className={styles.tableCell}
                                        style={{ minWidth: col.minWidth }}
                                    >
                                        {col.headerName}
                                    </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {glosas.map((col: IGlosa, i: number) => (
                                <TableRow key={i} data-testid={"table-results-body"}>
                                    <TableCell className={styles.tableCell}>
                                        {col.id}
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        {col.codeGlosa}
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        {col.nameGlosa}
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        {col.statusGlosa.name}
                                    </TableCell>
                                    <TableCell className={styles.tableCell}>
                                        ${
                                            Number(col.valueGlosa).toLocaleString("es-CO")
                                        }
                                    </TableCell>
                                    <CellRenderExpand col={col.justification}/>

                                    <TableCell className={styles.tableCell}>
                                        <Tooltip title={"Editar glosa"} placement="top" arrow>
                                            <IconButton
                                                aria-label="update"
                                                data-testid="update-glosa"
                                                onClick={() => updateGlosas(col)}
                                            >
                                                <img src={Image.iconoEditar} alt="editar" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Eliminar glosa"} placement="top" arrow>
                                            <IconButton
                                                aria-label="delete"
                                                data-testid="delete-glosa"
                                                onClick={() => deleteGlosa(col.id)}
                                            >
                                                <img src={Image.iconoEliminar} alt="eliminar" />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>

                        </Table>
                    
                    </TableContainer>
                    <Box className={styles.contentTotalGlossas} data-testid="title-not-results">
                        <div className={styles.namePayment}>Total Glosas</div>
                        <div className={styles.separator}></div>
                        <div className={styles.valuePayment}>${glossesTotalValue?.toLocaleString("es-CO")}</div>   
                    </Box>
                </Box>    
                : (
                    <TableContainer
                        className={styles.gridGlosasResults}
                        data-testid="grid-glosas"
                    >
                        <Box className={styles.containerNotResults} data-testid="title-not-results">Aún no se ha cargado la información correspondiente de esta sección</Box>
                    </TableContainer>
                )
                
        }
        {(loading) && <Spinner/>}
    </>
    
  );
}
