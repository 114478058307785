import { Fragment } from 'react'
import { IComment } from 'shared/models/store/IComments'
import { CommentForm } from './CommentForm'
import { Box } from '@mui/material'
import { CommentsList } from './CommentsList'

interface Props{
    comments:IComment[]
}

export const Comments = ({comments}:Props) => {
  return (
    <Fragment>
      <CommentForm />
      {
        comments?.length > 0 
          ? <CommentsList />
          : <></>
      }
    </Fragment>
  )
}
