import React, { Fragment, useRef } from 'react';
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from 'formik';
import { Button, Grid} from '@mui/material';
import { Dispatch } from 'shared/store/store';
import { useDispatch } from 'react-redux';
import styles from '../styles/modalAbortDocuments.module.scss';
import { useParams } from 'react-router-dom';

export const ModalAbortDocument: React.FC<{onClose: ()=> void, id: number, callGetDetailedDocument: (idDocument: string) => void}> = ({ onClose, id, callGetDetailedDocument}) => {
  const dispatch = useDispatch<Dispatch>();
  const {idDocument}= useParams();

  const formRef = useRef<FormikProps<any>>(null);
  const validationSchema = () => Yup.object().shape({
    description: Yup.string().max(300)
  })

  const submitCancelDocument = (values:any) => {
    dispatch.electronicDocuments.putCancelDocument({
        url:`${id}/cancel`,
        cancelReason: values.description,
      }).then(()=>{
        dispatch.electronicDocuments.getElectronicDocumentAsync({id}).then(()=>{
          idDocument && callGetDetailedDocument(idDocument);
          onClose();
        }).catch(()=> onClose());
    }).catch(()=> onClose())
  }

  return (
    <Fragment>
      <Formik
        innerRef={formRef}
        initialValues={{
          description: "",
        }}
        onSubmit={(values) => submitCancelDocument(values)}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {
          formik => (
            <Form data-testid='form-abort-document' style={{ marginTop: '10px' }}>
              <Field
                name="description"
                as="textarea"
                placeholder={"Motivo del rechazo"}
                className={styles.fieldDocRejected}
                data-testid='text-description'  
                id="descripcion-form-items"
                maxLength={300}
              />

              <div className={styles.contentButtons}>
                <span className={styles.spanContador}>
                  {formik?.values?.description?.length || 0}/300
                </span>
                <Grid container direction={"row"} justifyContent={'center'} className='buttonContainer'>
                  <Button 
                    disabled={formik?.values?.description?.length < 1} 
                    type='submit' 
                    variant='contained' 
                    data-testid='btn-submit'
                    id='btn-aceptar'
                    color='secondary' 
                    className='buttonSubmit'
                    >
                      Aceptar
                  </Button>
                  <Button 
                    type="button" 
                    variant='contained' 
                    id='btn-retornar'
                    data-testid='btn-cancel'  
                    onClick={()=>onClose()}
                    color='secondary' 
                    className='buttonCancelar' 
                    >
                      Cancelar
                  </Button>
                </Grid>
              </div>
            </Form>
          )
        }
      </Formik>
    </Fragment>
  )
}
