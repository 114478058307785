import { useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch } from 'shared/store/store';
import { RichTextEditor } from 'shared/components';
import styles from './styles/CommentItem.module.scss';
import { putCommentToUpdate } from 'shared/api/services/requestComment';
import { useAuth } from 'shared/auth';
import { useParams } from 'react-router-dom';
import { accesRoles } from 'shared/models';

interface Props {
    id: string;
    comment: string;
    author: string;
    date: Date;
    wasUpdated: boolean;
    privacy: string
    modifiedAt: string
}

export const CommentItem = ({ id, comment, author, date, wasUpdated, privacy, modifiedAt }: Props) => {

    const dispatch = useDispatch<Dispatch>();
    const {authService} = useAuth();
    const userData = authService.getUser();
    const rol = authService.getUserRoles(); 
    const { idDocument } = useParams()
    
    const formattedDate = dayjs(new Date(date)).format('DD/MM/YYYY hh:mm A');
    const formatteDateMod = dayjs(new Date(modifiedAt)).format('DD/MM/YYYY hh:mm A')
    
    const [updateCommentReadOnly, setUpdateCommentReadOnly] = useState(true);
    const [commentText, setCommentText] = useState(comment);
    
    const cancelCommentEdition = () => {
        setUpdateCommentReadOnly(true);
        setCommentText(comment);
    }
    
    const doneCommentEdition = (newComment: string) => {

        setUpdateCommentReadOnly(true);
        if (newComment === comment) return;

        const element = document.createElement('div');
        element.innerHTML = newComment;

        const mentions: { [key: string]: string } = {};
        Array.from(element.querySelectorAll('[data-id]')).forEach(value => {
            mentions[value.textContent || ''] = value.getAttribute('data-id') || '';
        });

        const commentObj = {
            ...(Object.keys(mentions).length > 0  ? { mentions } : { mentions: { } }),
            message: newComment,
            isPrivate: privacy === 'publico',
            id
        };
       
        putCommentToUpdate(commentObj, (idDocument || ''))?.then(response => {
            dispatch.comments.getCommentListAsync(idDocument ?? '')
        }) 
    }

    return (
        <Box className={styles.commentContainer}  data-testid='component_comment_item'>
            <Box className={styles.commentHeader}>
                <Box className={styles.authorDateContainer}>
                    <div>
                        <p>
                            {author}
                        </p>
                            <section className={styles.dateContainer}>
                                {
                                    modifiedAt ?  
                                        <span data-testid='text_updated'>Actualizado {formatteDateMod} </span> :
                                        <span data-testid='text_updated'>{formattedDate}</span>
                                }

                            </section>
                    </div>
                </Box>
                {
                    updateCommentReadOnly ?
                        userData?.preferred_username == author &&
                            <IconButton
                                className={styles.updateCommentButton}
                                disableRipple
                                disabled={ userData?.preferred_username !== author }
                                onClick={() => { setUpdateCommentReadOnly(false) }}
                                data-testid='edit_comment_button'
                            >
                                <EditIcon />
                            </IconButton> 
                    :<Box className={styles.commentButtonsContainer}>
                        <IconButton
                            className={styles.cancelEditionButton}
                            disableRipple
                            onClick={() => { cancelCommentEdition() }}
                            data-testid='cancel_comment_button'
                        >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            className={styles.doneEditionButton}
                            disableRipple
                            onClick={() => { doneCommentEdition(commentText) }}
                            data-testid='done_comment_button'
                        >
                            <DoneIcon />
                        </IconButton>
                    </Box>
                }
            </Box>      
            <RichTextEditor
                value={commentText}
                onChange={(richText) => { setCommentText(richText) }}
                readOnly={updateCommentReadOnly}
                useMention={ rol.some(role => accesRoles.usersSSSA.includes(role)) }
                customClass='customCommentClass'
            />
        </Box>
    )
}
