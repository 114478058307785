import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -8,
        top: -8,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        color: '#fff',
        background:'#FF3D00',
        fontSize: 'clamp(0.7rem, 0.3042rem + 1.48vw, 0.8rem)',
        minWidth: '20px',
        minHeight: '20px',
        height: 'auto',
        width: 'auto',
    },
}));