import { useEffect, useState } from "react";

export const useWidth = () => {

    const [width, setWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        if (typeof window === "undefined") return;

        const debouncedResize = debounce(handleResize, 100);

        window.addEventListener("resize", debouncedResize);
        return () => {
            window.removeEventListener("resize", debouncedResize);
        };
    }, []);

    return { width };
}

export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<T>) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
