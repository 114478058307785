import { useState } from 'react'
import { getData } from 'shared/api/services';

export const useOpenPdf = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [openIframePdf, setOpenIframePdf] = useState(false);
    const [documentPdf, setDocumentPdf] = useState<any>({});

    const callServicePdf = (url:string)=>{
        setIsLoading(true);
        getData( url ).then((response: any) => {
          response && setDocumentPdf(response);
          setOpenIframePdf(true);
          setIsLoading(false);
        }).catch(()=> {
          setOpenIframePdf(false);
          setIsLoading(false);
        });
    }

    const closeModalPdf = ()=>{
        setOpenIframePdf(false);
    }

    return {
        isLoading,
        openIframePdf,
        documentPdf,
        callServicePdf,
        closeModalPdf
    }
}
