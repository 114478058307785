import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { apiInstance, masterRecord } from 'shared';
import { GET_AUDIT_RESTART_CRITERIA, GET_ELECTRONIC_DOCS } from 'shared/api';
import { postData } from 'shared/api/services/postdata';
import { Dispatch } from 'shared/store/store';
import { removeHTMLTagsFromText } from 'shared/utils';
import Swal from 'sweetalert2';

export const useModalReset= (id:any, callGetDetailedDocument: (idDocument: string) => void,  onClose?:()=>void) => {
    const dispatch = useDispatch<Dispatch>();
    const [isLoading, setIsLoading] = useState(false);
    const [richTextValue, setRichTextValue] = useState('');
    const [totalCaracteres, setTotalCaracteres] = useState(0);
    const [disableConfirmButton, setDisableConfirmButton] = useState(true);
    const [isDisableInputCode, setIsDisableInputCode] = useState(false);
    const [activeCode, setActiveCode] = useState(false);
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
    const [activeIndexOtp, setActiveIndexOtp] = useState(0);
    const [errorInputOtp, setErrorInputOtp] = useState(false);
    const [auditCriteria, setAuditCriteria ] = useState('')
    const [auditCriteriaList, setAuditCriteriaList] = useState<masterRecord[]>([])
    const maxLength: number = 1000;
    const minLength: number = 200;

    useEffect(() => {
        async function fetchData() {
            const response = await apiInstance.get(GET_AUDIT_RESTART_CRITERIA);
            setAuditCriteriaList(response.data)
        }

        auditCriteriaList.length <= 0 && fetchData()
    },[])

    const onActiveResetAudit = !otp.every((elemento:any) => elemento !== "") || isDisableInputCode;
    const onSetIsDisableInputCode = useCallback( ()=> {setIsDisableInputCode(true) }, []);
    const onSetRichTextValue = (value:string)=>{
        setRichTextValue(value);
    }
    const onSetDisableConfirmButton = (value:boolean)=>{
        setDisableConfirmButton(value);
    }

    const onChangeRichText = (richText: string) => {
        const cleanText = removeHTMLTagsFromText(richText);
        setTotalCaracteres(cleanText.length);
        setRichTextValue(cleanText.length < 1 ? '<p><br></p>' : richText);
    }

    const sendCodeResetAudit = () => {
        const detail:string = richTextValue;
        if(totalCaracteres > maxLength || totalCaracteres < minLength || auditCriteria === ''){
            dispatch.messageModal.setMessageModal({ hide: true })
            return Swal.fire({
                title: "Error",
                html: `
                    <p>Debe diligenciar la descripción, entre 200 y 1000 caracteres y escoger una opcion en el "Criterio de Reinicio de Auditoría" </p>
                `,
                icon: "error",
                timer: 7000,
            }).then(() => {
                dispatch.messageModal.setMessageModal({ hide: false })
            });
        }
        
        if(disableConfirmButton){
            dispatch.messageModal.setMessageModal({ hide: true })
            return Swal.fire({
                title: "Error",
                icon: "error",
                html: `
                    <p>Debe confirmar que desea reiniciar la auditoría.</p>
                `,
                timer: 7000,
            }).then(() => {
                dispatch.messageModal.setMessageModal({ hide: false })
            });
        }

        setIsLoading(true);

        postData(`${GET_ELECTRONIC_DOCS}/${id}/audit/restart-audit`, {
            detail,
            motiveId: parseInt(auditCriteria)
        }).then(() => {
                setActiveCode(true);
            }).finally(() => { setIsLoading(false) });
    }

    const onResetAudit = ()=> {
        const code: string = otp.join("");
        setIsLoading(true);
        postData(`${GET_ELECTRONIC_DOCS}/${id}/audit/restart-audit/execute`, {code})
            .then(() => {
                dispatch.electronicDocuments.getElectronicDocumentAsync({id}).then(()=>{
                    id && callGetDetailedDocument(id);
                    onClose && onClose();
                  })
            }).catch((e)=>{
                e?.response?.status === 404 && setErrorInputOtp(true);
            })
            .finally(() => { setIsLoading(false) });
    };

    const handleOnChangeCode = ({target}:React.ChangeEvent<HTMLInputElement>, index:number):void => {
        const {value} = target;
        const newOTP: string[] = [...otp];
        newOTP[index] = value.substring(value.length -1);

        (!value) ? setActiveIndexOtp(index - 1) : setActiveIndexOtp(index + 1);
        setErrorInputOtp(false);
        setOtp(newOTP);
    };

    const handleKeyDown = ({key}: React.KeyboardEvent<HTMLInputElement>, index:number): void => {
        if( key === 'Backspace') setActiveIndexOtp(index - 1);
    };

    const cancelCodeOtp = ()=> {
        setIsDisableInputCode(false);
        setActiveCode(false);
        setDisableConfirmButton(true);
        setOtp(new Array(6).fill(""));
        setActiveIndexOtp(0);
    };

  return {
    isLoading,
    activeCode,
    activeIndexOtp,
    totalCaracteres,
    richTextValue,
    isDisableInputCode,
    otp,
    maxLength,
    onActiveResetAudit,
    errorInputOtp,
    auditCriteria,
    auditCriteriaList,
    
    setAuditCriteria,
    onChangeRichText,
    sendCodeResetAudit,
    onResetAudit,
    onSetIsDisableInputCode,
    handleOnChangeCode,
    handleKeyDown,
    cancelCodeOtp,
    onSetRichTextValue,
    onSetDisableConfirmButton
  }
}
