import React, { Fragment } from 'react'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import CheckIcon from '@mui/icons-material/Check';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IDropdown, accesRoles } from 'shared/models'
import { IPropsFormBills } from '../model/IPropsFormBills'
import { ModalStates } from './ModalStates'
import { ComboBoxCustom } from 'shared/components/ComboBoxCustom';
import { RouterGuard } from 'shared/components';
import styles from '../styles/readDocuments.module.scss'
import '../styles/_readDocumentsMUI.scss';
import { useFormReadDocument } from '../hooks/useFormReadDocument';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

export const FormReadDocument = ({ dataProvider, dataFields }:IPropsFormBills) => {

    const { openModalStates, userRolIsHealthProvider, errors, valueDateTo, valueDateFrom, groupCheck,
        setValueDateTo, setValueDateFrom, setOpenModalStates, onCloseModal, handleChange, 
        handleSubmit, getValue, validateActivitiesSelected, validateFieldDisabled, handleUserAssignment, handleChangeCheck
    } = useFormReadDocument(dataProvider, dataFields);

    const renderInput =(params:any) => {
      return(
        <Box sx={{display:'flex', alignItems: 'center'}} className={styles.dataPicker}>
            <TextField 
                {...params} 
                onKeyDown={(e:any) => { e.which === 13 && e.preventDefault(); }}
                data-testid={params.label}
            />
        </Box>
      )
    }

    const getDateValue = (fieldName: string) => fieldName === 'dateTo' ? valueDateTo : valueDateFrom;

    const isSelectedFieldIsADifferentInvoice = getValue('typeDocument') !== '1';

    return (
        <Fragment>
            <form className={`${styles.formReadDocuments} form-documents`} noValidate onSubmit={handleSubmit} data-testid='form-read-documents'>
                <Typography className={'titleMain'}>Consulta de documentos</Typography>
                <Grid container direction={"row"} className={styles.containerReadDocuments}>
                    {
                        dataFields.map((field, i)=> (
                            <Fragment key={i}>
                                {
                                    field.type === 'Autocomplete' && userRolIsHealthProvider ? (
                                        <RouterGuard roles={accesRoles.usersSSSA} type='component'>
                                            <></>
                                        </RouterGuard>
                                    ) : (
                                        <Grid item xs={12} sm={6} md={6} lg={3} className={ styles.containerGridItem } key={i} >
                                            {
                                                field.type === 'text' &&
                                                    <>
                                                        <label htmlFor={field.id}>
                                                            {field.label}
                                                        </label>
                                                        <input 
                                                            type='text'
                                                            name={field.name}
                                                            placeholder={field.placeholder}
                                                            id={field.id}
                                                            data-testid={field.testId}
                                                            value={ getValue(field.name) }
                                                            onChange={handleChange}
                                                            disabled={validateFieldDisabled(field.name, field.isEnable)}
                                                        />
                                                    </>
                                            }
                                            {
                                                field.type === 'Select' &&
                                                    <>
                                                        <label htmlFor={field.id}>
                                                            {field.label}
                                                        </label>
                                                        <select 
                                                            name={field.name}
                                                            placeholder={field.placeholder}
                                                            id={field.id}
                                                            data-testid={field.testId}
                                                            value={getValue(field.name)}
                                                            onChange={handleChange}
                                                            disabled={!field.isEnable}
                                                        >
                                                            {
                                                                field?.options?.map((opcion: IDropdown) => (
                                                                    <option key={opcion.id} value={opcion.id} data-testid={`option-select-${opcion.id}`}>
                                                                        {opcion.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </>
                                            } 
                                            {
                                                field.type === 'datePicker' &&
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                                                    <DatePicker
                                                        label={field.label}
                                                        toolbarPlaceholder={field.label}
                                                        className={styles.contentDatePicker}
                                                        value={getDateValue(field.name)}
                                                        onChange={(newValue) => {
                                                            field.name === 'dateTo' ?
                                                            setValueDateTo(newValue) : setValueDateFrom(newValue);
                                                        }}
                                                        data-testid={field.testId}
                                                        renderInput={(params) => renderInput(params)}
                                                    />
                                                </LocalizationProvider>
                                            }
                                            {
                                                field.type === 'Autocomplete' &&
                                                    <Fragment>
                                                        <span className={styles.spanForLabel} data-testid={field.testId}>
                                                            {field.label}
                                                        </span>
                                                        <ComboBoxCustom 
                                                            handleChange={handleUserAssignment} 
                                                            data={field.options!} 
                                                            placeholder='Seleccione un usuario' 
                                                            focusSelected={true}
                                                            isDisabled={isSelectedFieldIsADifferentInvoice}    
                                                        />
                                                    </Fragment>
                                            } 
                                        </Grid>
                                    )
                                }
                            </Fragment>
                        ))
                    }
                    <Grid item xs={12} md={6} lg={3} className={ styles.containerGridItem }>
                        <label htmlFor='selectStates'>
                            Estados
                        </label>
                        <input
                            className={styles.selectStates}
                            onClick={ ()=> setOpenModalStates(true) }
                            value={validateActivitiesSelected() ? 'Actividades seleccionadas' : 'Seleccione Actividades' }
                            readOnly={true}
                            id='selectStates'
                            data-testid='select_states'
                        />
                        {
                            validateActivitiesSelected() 
                                ? <CheckIcon className={styles.checkIcon} data-testid="check_icon"/>
                                : <KeyboardArrowDownIcon className={styles.iconArrow} onClick={()=>setOpenModalStates(true)} data-testid='keyboard_arrow_down_icon'/>
                        }
                        

                    </Grid>
                    <Grid item xs={12} md={6} lg={6} className={ styles.containerGridItem }>
                        <Typography >Cumplimiento</Typography>
                            <FormGroup className={ styles.formGroupCheck }>
                                <FormControlLabel
                                    disabled={isSelectedFieldIsADifferentInvoice}    
                                    control={<Checkbox className={ styles.check } icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />} checked={groupCheck.onTime} onChange={handleChangeCheck} name="onTime" />}
                                    label="A tiempo"
                                    data-testid='check_on_time'
                                />
                                <FormControlLabel
                                    disabled={isSelectedFieldIsADifferentInvoice}    
                                    control={<Checkbox className={ styles.check } icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />} checked={groupCheck.nextToExpire} onChange={handleChangeCheck} name="nextToExpire" />}
                                    label="Próxima a vencer"
                                    data-testid='check_next_to_expire'
                                />
                                <FormControlLabel
                                    disabled={isSelectedFieldIsADifferentInvoice}    
                                    control={<Checkbox className={ styles.check } icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />} checked={groupCheck.expired} onChange={handleChangeCheck} name="expired" />}
                                    label="Vencida"
                                    data-testid='check_expired'
                                />
                            </FormGroup>
                    </Grid>
                    <Grid container className={styles.buttonContainer}>
                        {
                            errors.range && <div><Typography className={styles.errorMessage} data-testid='text_error_range'>{errors.range}</Typography></div> 
                        }
                        <div className={styles.buttons}>
                            <Button type='submit' variant='contained' color='secondary' className={styles.buttonSubmit} data-testid="btn-submit">
                                Consultar
                            </Button>
                        </div>
                    </Grid>
                </Grid>

            </form>
            <ModalStates
                onCloseModal={onCloseModal}
                openModal={openModalStates}
                isSelectedFieldIsADifferentInvoice={isSelectedFieldIsADifferentInvoice}
            />
        </Fragment>
    )
}
