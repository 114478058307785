import { createModel } from "@rematch/core";
import { RootModel } from "../rootModel";
import { IOptionsDropdowns } from "shared/models/store/IOptionsDropdowns";
import { getUsers as getUsersApi } from "shared/api/services";
import { convertOptionsDropdowns } from "shared/mappers/convertOptionsDropdowns";
import { apiInstance } from "shared/api/config/apiInstance";
import { GET_ACTIVITY_STATUS, GET_DATA_PAYMENTS_STATES, GET_DATA_PAYMENT_LINE, GET_DATA_PROVINCES, GET_DATA_TYPES_DOCS, GET_DATA_USER_TYPES_DOC } from "shared/api";

export const optionsDropdowns = createModel<RootModel>()({
  state: {
    users: [],
    userTypesDocument: [],
    typeDocument:[],
    departments: [],
    cities: [],
    paymentsLines: [],
    paymentsStates: [],
    activityStatus: [],    
  } as IOptionsDropdowns,
  reducers: {
    addUsers(state,payload){
      return {
        ...state,
        users: payload || [],
      };
    },
    addUserTypesDocument(state,payload){
      return {
        ...state,
        userTypesDocument: payload || [],
      };
    },
    addTypesDocuments(state,payload){
      return {
        ...state,
        typeDocument: payload || [],
      };
    },
    addDepartments(state,payload){
      return {
        ...state,
        departments: payload || [],
      };
    },
    addCities(state,payload){
      return {
        ...state,
        cities: payload || [],
      };
    },
    addPaymentsLines(state,payload){
      return {
        ...state,
        paymentsLines: payload || [],
      };
    },
    addPaymentsStates(state,payload){
      return {
        ...state,
        paymentsStates: payload || [],
      };
    },
    addActivityStatus(state,payload){
      return {
        ...state,
        activityStatus: payload || [],
      };
    }
  },
  effects: (dispatch) => ({

    async getUsers(_:void, rootState): Promise<void> {
      if (rootState.optionsDropdowns.users.length > 0) return Promise.resolve();
      getUsersApi().then((data)=> dispatch.optionsDropdowns.addUsers(convertOptionsDropdowns(data)));
    },
    async getUserTypeDocuments(_:void, rootState): Promise<void> {
      if (rootState.optionsDropdowns.userTypesDocument.length > 0) return Promise.resolve();
      const response = await apiInstance.get(`${GET_DATA_USER_TYPES_DOC}`);
      const { data } = response;
      const filteredDocuments = data.filter(({ code }: any) => ['CC', 'CE', 'PA', 'PE', 'CD', 'SC', 'DE', 'PT',].includes(code))
      dispatch.optionsDropdowns.addUserTypesDocument(filteredDocuments
          .map(({ id, code, name }: any) => ({ id, name: `${code}: ${name}` })));
    },
    async getTypesDocuments(_:void, rootState): Promise<void> {
      if (rootState.optionsDropdowns.typeDocument.length > 0) return Promise.resolve();
      const response = await apiInstance.get(`${GET_DATA_TYPES_DOCS}`);
      dispatch.optionsDropdowns.addTypesDocuments(convertOptionsDropdowns(response.data));
    },
    async getDepartments(_:void, rootState): Promise<void> {
      if (rootState.optionsDropdowns.departments.length > 0) return Promise.resolve();
      const response = await apiInstance.get(`${GET_DATA_PROVINCES}`);
      dispatch.optionsDropdowns.addDepartments(convertOptionsDropdowns(response.data));
    },
    async getCities(department): Promise<void> {
      const response = await apiInstance.get(`${GET_DATA_PROVINCES}/${department}/cities`);
      dispatch.optionsDropdowns.addCities(convertOptionsDropdowns(response.data));
    },
    async getPaymentsLines(_:void, rootState): Promise<void> {
      if (rootState.optionsDropdowns.paymentsLines.length > 0) return Promise.resolve();

      const response = await apiInstance.get(GET_DATA_PAYMENT_LINE);
      dispatch.optionsDropdowns.addPaymentsLines(convertOptionsDropdowns(response.data));
    },
    async getPaymentStates(_:void, rootState): Promise<void> {
      if (rootState.optionsDropdowns.paymentsStates.length > 0) return Promise.resolve();
      
      const response = await apiInstance.get(GET_DATA_PAYMENTS_STATES);
      dispatch.optionsDropdowns.addPaymentsStates(convertOptionsDropdowns(response.data));
    },
    async getActivityStatus(_:void, rootState): Promise<void> {
      if (rootState.optionsDropdowns.paymentsStates.length > 0) return Promise.resolve();
      
      const response = await apiInstance.get(GET_ACTIVITY_STATUS);
      dispatch.optionsDropdowns.addActivityStatus(convertOptionsDropdowns(response.data));
    }
  }),
});