import { createModel } from "@rematch/core";
import { apiInstance } from "shared/api/config/apiInstance";
import { IGetLink, ILinkDocument } from "shared/models/store/ILinkDocument";
import { RootModel } from "../rootModel";
import { linkDocAdapter, linkDocAdapterResolution } from "../../adapters/linkDoc.adapter";
import { putUploadDocBlob } from '../../api/services/putUploadDocBlob';
import { cleanFileName } from '../../utils/helpers/cleanFileName';
import { downloadDocument } from "shared/utils/helpers/downloadDocument";
import { downloadsDescription, downloadsTitle } from "shared/utils/data/infoDownloadDocument";
import { RESOLUTION_2275_2023 } from "pages/read-documents/helper/resolutions";

export const linkDocument = createModel<RootModel>()({
  state: {
    id: null,
    link: null,
    originalName: null,
    nameNewsRips: null,
    openModalRips: false,
  } as ILinkDocument,
  reducers: {
    getLink(state,payload){
      return {
        ...state,
        id: payload.id,
        link: payload.link,
        originalName: payload?.originalName ?? state.originalName,
        nameNewsRips: payload?.nameNewsRips ?? state.nameNewsRips,
      };
    },
    toogleModalRips(state, payload) {
      return {
        ...state,
        openModalRips: payload,
      };
    },
    cleanState(state) {
      return {
        ...state,
        id: null,
        link: null,
        originalName: null,
      };
    },
    cleanStateNameNewsRips(state) {
      return {
        ...state,
        nameNewsRips: null,
      };
    },
    cleanAllState(state) {
      return {
        id: null,
        link: null,
        originalName: null,
        nameNewsRips: null,
        openModalRips: false,
      };
    }
  },
  effects: (dispatch) => ({
    async getLinkAsync(params:IGetLink) {
      const response = await apiInstance.post(params.url, {
        fileNames: [cleanFileName(params.file.name)],
        typeResolution: params?.typeResolution,
      });
      
      const data = Array.isArray(response.data) ? response.data[0]: response.data;
      data && putUploadDocBlob(data.link, data.id, data.originalName, params.file).then(()=>{
        params.typeResolution?.includes(RESOLUTION_2275_2023) ? dispatch.linkDocument.getLink(linkDocAdapterResolution(data)) : dispatch.linkDocument.getLink(linkDocAdapter(data));
      })
    },

    async processDocumentPatchAsync({url,id}) {
      const response = await apiInstance.post(url, {attachmentId: id,}, {responseType: 'blob'});
      downloadDocument(response, downloadsTitle.successDocument, downloadsDescription.successDocument, 'success');
      dispatch.linkDocument.cleanState();
    }
  }),
});
