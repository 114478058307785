export enum EResponseCodes {
    OK = "OK",
    WARN = "WARN",
    FAIL = "FAIL",
}
export enum EResponseActivityStatus {
    OK = "A tiempo",
    WARN = "Proximo a vencer",
    FAIL = "Vencido",
}

export enum EStatusInvoice {
    docReceived = "Documento Recibido" 
}

export enum EApplicationStatus{
    correctlyApplied = 'Aplicada Correctamente',
    pendingLoadingRips = 'Pendiente de Carga de RIPS',
}

export enum EResolutionDocument{
    r2275 = 'RESOLUTION_2275_2023',
    r3374 = 'RESOLUTION_3374_2000',
}