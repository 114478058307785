import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, GET_ELECTRONIC_DOCS, apiInstance } from 'shared';
import Swal from 'sweetalert2';
import { IUploadSupport } from '../helpers/IUploadSupport';
import { RootState } from '../../../../../shared/store/store';
import { textWithInstructions } from '../../../../../shared/utils/helpers/textWithInstructions';
import { Typography } from '@mui/material';

export const useUploadSupport = ({ id, support, isReplace = false, maxLength = 5, setIsLoading, idDocument, callGetSupports }: IUploadSupport) => {
 
    const dispatch = useDispatch<Dispatch>();
    const { linkSupports } = useSelector((state: RootState) => state);
    const { supports, response } = linkSupports
    
    const [documents, setDocuments] = useState<File | null>();
    const [hasErrors, setHasErrors] = useState(false);
    const codes = supports.find(element => parseInt(element.code) == support)?.id;
    
    useEffect(() => {
        if (documents) setHasErrors(false)
    },[documents])

    const typeAccept = ["PDF","JPG","PNG","DOCX","XLSX","PPTX", "HTML", "ZIP"];

    const onCancel = () => {
        dispatch.messageModal.cleanStateMessage() 
        setDocuments(null)
    }

    const removeFile = () => setDocuments(null);

    const handleChangeFile = async(files: File) =>  {
        setDocuments(files);

        dispatch.linkSupports.getLinkAsync({
            file: [files], 
            url: `${GET_ELECTRONIC_DOCS}/${idDocument}/supports/generate-upload-link`,
            codes: [codes]
        })
    }

    const errorFileType = () => {
        Swal.fire({
          title: 'Carga fallida',
          text: `Debe garantizar que el formato y las extensiones de los soportes sea una de las siguientes: PDF,JPG,PNG,XLSX, DOCX,PPTX, HTML Y ZIP`,
          icon: 'error',
          timer: 5500
        })
    }
    
    const errorFileSize = () => {
        Swal.fire({
            title: 'Carga fallida',
            text: `Debe garantizar que el tamaño máximo de cada soporte no sea superior a ${maxLength} MB`,
            icon: 'error',
            timer: 5500
        })
    }

    const onOkButton = () => {
        const hasDocuments = Boolean(documents);
        setHasErrors(!hasDocuments);

        if (!hasDocuments) return
    
        uploadDocument()
    }
    
    const uploadDocument = async() => {
        setIsLoading?.(true) 
        const idResponse = response?.find(item => item.id)?.id
        const url = isReplace ? `${GET_ELECTRONIC_DOCS}/${idDocument}/supports/replace-individual-support` : `${GET_ELECTRONIC_DOCS}/${idDocument}/supports/upload-individual-support`;

        await apiInstance.post( url , { 
            attachmentIds: !isReplace && [ idResponse ] ,
            oldAttachmentId: id,
            newAttachmentId: idResponse
        }).then(resp => {
            setIsLoading?.(false)
            dispatch.messageModal.cleanStateMessage() 
            Swal.fire({
                title: "El soporte se ha almacenado de manera correcta.",
                icon: "success",
                timer: 5000,
            }).then(()=>{
                callGetSupports?.(idDocument)
                setIsLoading?.(true)
            })
        }).catch(error => {
            setIsLoading?.(false)
            dispatch.messageModal.cleanStateMessage()
        })
    }

    const TitleComplement = () => {
        const textInstructions = textWithInstructions(codes);
        return (
            <Typography className='descriptionTextSupports' data-testid='text_intructions'>{ textInstructions }</Typography>
        )
    }

    return {
        hasErrors,
        typeAccept,
        documents,

        onCancel,
        removeFile,
        handleChangeFile,
        errorFileType,
        errorFileSize,
        onOkButton,
        setDocuments,
        TitleComplement
    }
}
