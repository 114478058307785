
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { TabPanel } from 'pages';
import React, { useState } from 'react'
import { IPropCustomTabs } from './model/IPropCustomTabs';
import '../../components/tabs/styles/_customTabs.scss'

export const CustomTabs = ( { tabProps, hasStyleCustomTabs, customClass = '', valueTab = 0 }: IPropCustomTabs) => {

  const isDisabled = tabProps.find((tab, index) => index === valueTab)?.disabledTab

  const [value, setValue] = useState(valueTab);
  const [isDisableState, setIsDisableState] = useState(isDisabled);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(!tabProps[newValue].disabledTab) {
      setIsDisableState(false);
    }
    tabProps[newValue].callService?.( newValue );
    setValue(newValue);
  };
  
  return (
    <Box className='content'>
      <section className='headerTabs' data-testid='header_tabs'>
        {
          tabProps[value]?.title && <Typography className='titleMain' data-testid='title_main'>{ tabProps[value].title }</Typography>
        }

        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="simple tabs example"
          className={hasStyleCustomTabs ? 'contentTabs' : customClass}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          data-testid='tabs'
        >
          {
            tabProps?.map((tab, index) => (
              <Tab label={tab.label} key={tab.label} disabled={tab.disabledTab} data-testid={`tab_${index}`}/>
            ))
          }
        </Tabs>
      </section>
      {
        !isDisableState && tabProps.map((tab, index) => (
          value === index && (
            <TabPanel value={value} index={index} key={tab.label} className='tabPanelContentCustom'>
              {tab.component}
            </TabPanel>
          )
        ))
      }
      {
        isDisableState && (
          <section className='contentDisabledTab'>
            <Typography data-testid='content_disabled_tab'>Esta pestaña se encuentra deshabilitada</Typography>
          </section>
        )
      }
    </Box>
  )
}

