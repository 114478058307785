import { IRespDocuments } from "shared";
import { EResponseActivityStatus } from "shared/constants/api.enum";

export const findActivityStatus = (activity: IRespDocuments | any) : string => {
    if(activity?.activityStatus?.name === EResponseActivityStatus.FAIL) return "activityIsDefeated";
    if(activity?.activityStatus?.name === EResponseActivityStatus.WARN) return "activityIsWarn"
    if(activity?.activityStatus?.name === EResponseActivityStatus.OK) return "activityIsOnTime"

    return ''
}
