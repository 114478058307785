import { Fragment, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, RootState } from 'shared/store/store';
import { useWidth } from 'shared/hooks/HookDetailedConsultation/useWidth';
import { Navbar, SideBar } from 'shared/components';
import styles from './styles/layout.module.scss';
import { useLocation } from 'react-router';
import { Comments } from 'shared/components/comments/Comments';
import { ContainerIframes } from 'shared/components/iframes/ContainerIframes';
import Main from './main';

interface Props {
  children: React.ReactNode;
  isBackgroundImg?: boolean;
}

export const Layout = ({ children, isBackgroundImg = true }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const iframes =  useSelector((state: RootState) => state.iframes);
  const comments =  useSelector((state: RootState) => state.comments);

  const { width } = useWidth();
  const location = useLocation();

  useEffect(() => {
    width > 900 && dispatch.menuDrawer.activeMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((comments.openDrawer || iframes.openDrawer) && location.pathname) {
      dispatch.comments.handleOpenDrawer(false);  
      dispatch.iframes.handleOpenDrawer(false);
    }
  },[location])

  const ChildrenDrawer = memo(() => {
    return (
      <Box className={`${width < 600 ? styles['leftDrawerContentMobil'] : styles['leftDrawerContent']}`} data-testid='content_children_drawer'>
        <Fragment>
          <IconButton
            className={styles.buttonCloseCommentsDrawer}
            disableRipple
            onClick={() => { comments.openDrawer ? dispatch.comments.handleOpenDrawer(false): dispatch.iframes.handleOpenDrawer(false) }}
            data-testid='btn_close_mobil'
          >
            <CloseIcon />
          </IconButton>
          {
            comments.openDrawer && <Comments comments={comments.comments}/>
          }
          {
            iframes.openDrawer && <ContainerIframes iframes={iframes.iframes}/>
          }
        </Fragment>
      </Box>
    );
  });

  return (
    <Fragment>
      <Navbar />
      <Box className={styles.layout} data-testid='content_layout'>
        <SideBar />
        <Box sx={{ display: 'flex' }}>
          <Main open={comments.openDrawer || iframes.openDrawer}>
            <Box className={`${!isBackgroundImg ? styles['noBackgroundIm'] : styles['containerMain']}`} sx={{ marginLeft: 'auto', width: width > 900 ? "calc(100% - 231px)" : "100%" }} data-testid='box_main'>
              {children}
            </Box>
          </Main>
          <section>
            {
              width < 600 ?
                <Drawer  //drawer para mobil
                  variant="temporary"
                  open={comments.openDrawer || iframes.openDrawer}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  sx={{
                    display: { xs: 'block', sm: 'none' },
                    flexShrink: 0,
                    width: '80%',
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '80%' },
                  }}
                  anchor="right"
                  data-testid='drawer_mobil'
                >
                  <div className={styles.borderTop}></div>
                  <ChildrenDrawer/>
                </Drawer>
              : (
                <>
                  <div></div>
                  <Drawer
                    sx={{
                      width: '320px',
                      flexShrink: 0,
                      display: { xs: 'none', sm: 'block' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box',  width: '320px', position: 'relative',
                      },
                    }}
                    anchor="right"
                    variant='persistent'
                    open={comments.openDrawer || iframes.openDrawer}
                    data-testid='drawer_content'
                  >
                   <ChildrenDrawer/>
                  </Drawer>
                </>
              )
            }
          </section>
        </Box>
      </Box>
    </Fragment>
  )
}
