import { useState } from 'react'
import { getData } from 'shared/api/services';
import { contentGridPaymentAuthorization } from '../helpers/contentTableRowDetailed';
import { POST_PAYMENT_AUTHORIZATION_DETAILS } from 'shared/api';
import Image from 'shared/assets/index'
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale("es");

export const useTabAuthorizatePayments = () => {

  const [ dataPayments, setDataPayments ] = useState<any>({
    icon:'',
    title: '',
    description: '',
    columns: [],
    content: [],
  })
  const [isLoading, setIsLoading] = useState(true);

  const convertServicePaymentAuthorization = (dataDocs: any) => {
    const data = {
        icon: Image.iconoResumen,
        title: 'Detalle de Autorización de pagos',
        description:'La Secretaría Seccional de Salud y Protección Social de Antioquia en aplicación de los procedimientos internos, previo al pago efectivo de la presente Autorización para Pago, podrá verificar el estado de afiliación del usuario en el sistema de salud y si encuentra que se han reportado novedades que impliquen otro responsable pagador del servicio, procederá a realizar el ajuste de los valores glosados y avalados para pago.\n\nLa norma vigente permite reconocer novedades de afiliación retroactivas hasta un año después de la generación de las mismas. Decreto 780 de 2016 art 2.3.2.2.6, parágrafo 2, por lo que no es posible predecir cuáles serán las novedades retroactivas a la auditoria.\n\nLa Dirección Administrativa y Financiera hará los descuentos a que haya lugar. Se aclara que en caso de presentarse hallazgos por parte de la Contraloría General de la República, se acatarán según los informes producidos por este Ente de Control.\n\nEl auditor o supervisor, certifica que la factura relacionada a continuación surtió el proceso de auditoría y puede continuar el trámite para pago.',
        columns: contentGridPaymentAuthorization,
        content: dataDocs?.map((item:any) => {
            return {
              id: item.id || '',
              consecutive: item.consecutive || '',
              invoiceReference: item.invoiceReference || '',
              dateAuthorization: item.authorizedAt ? dayjs(new Date(item.authorizedAt)).format('DD MMMM YYYY HH:mm')  : "",
              valueAuthorization: item.authorizationValue || '',
              auditor: item.authorizedBy || '',
              line: item.paymentLine.name || '',
              referencePayment: item.paymentReference,
              state: item.state.name || '',
            }
        }) || []
    };    
    setDataPayments(data);
    setIsLoading(false);
  }

  const callDataPaymentAuthorization = (idDocument: string) => {
      getData( `${POST_PAYMENT_AUTHORIZATION_DETAILS}/${idDocument}/get-all`).then((response: any) => {
          response?.length > 0 && convertServicePaymentAuthorization(response)
          setIsLoading(false)
      }).catch(()=> setIsLoading(false))
  }

  return {
    callDataPaymentAuthorization,
    convertServicePaymentAuthorization,
    dataPayments,
    isLoading,
  }
}
