import React, { useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box } from '@mui/material';
import styles from '../styles/showPayment.module.scss'
import { IRespDocuments } from 'shared';
import { DataGrid, esES } from '@mui/x-data-grid';
import { contentTableRowBalance } from '../helpers';
import { useShowBalanceHistory } from '../hooks/useShowBalanceHistory';
import '../styles/_tableDetaildConsultation.scss'

interface Props {
    index?: number;
    value?: string;
    docState?:IRespDocuments,
}

export const ShowBalanceHistory = ({index, value, docState}:Props) => {

  const { activePayments, dataPayments, handleActivePayments } = useShowBalanceHistory();
    
  return (
    <>
        <VisibilityIcon 
            className={styles.editView} 
            onMouseOver={()=>handleActivePayments(true)} 
            onMouseOut={()=>handleActivePayments(false)}
            data-testid='btn-values-payments'
        />
        <div>

            {
                activePayments && (
                    <Box 
                        className={styles.modalInputAutorization}
                        onMouseOver={()=>handleActivePayments(true)} 
                        onMouseOut={()=>handleActivePayments(false)}
                        data-testid='container-values-payments'
                    >
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'8px'}>
                            <Box className='' padding={0} border={0}>
                                <DataGrid
                                    rows={dataPayments}
                                    columns={contentTableRowBalance}
                                    className={`${styles.tableResults} tableAuthorizationPayments`}
                                    data-testid="datagrid-payments"
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableDensitySelector
                                    disableColumnMenu={true}
                                    hideFooterSelectedRowCount={true}
                                    hideFooterPagination={true}
                                    hideFooter={true}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                />
                            </Box>
                            
                        </Box>
                    </Box>
                )
            }
        </div>
    
    </>
  )
}
