import { useEffect, useState } from 'react'
import Image from 'shared/assets/index'
import { contentDataSupports, customSortDocuments } from '../helpers';
import { useParams } from 'react-router-dom';
import { IResponseElectonicSupports, ITabSupports } from '../Models/IContentTabs';
import { getData } from 'shared/api/services/getData';
import { ELECTRONIC_SUPPORTS_DOWNLOAD, ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE, GET_DATA_SUPPORT_CONTAINER, GET_ELECTRONIC_DOCS, GET_ELECTRONIC_SUPPORTS } from 'shared/api';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'shared/store/store';
import { UploadSupport } from '../components/tab-support';
import { RootState, apiInstance } from 'shared';
import { statesActivities } from 'shared/models/lineStates';
import { useCheckRoleAccess } from 'shared/hooks/useCheckRoleAccess';

export const useTabSupports = () => {

    const dispatch = useDispatch<Dispatch>();
    const linkSupports = useSelector((state: RootState) => state.linkSupports);
    const auditDetails = useSelector((state: RootState) => state.auditDetails);
    const { checkRoles } = useCheckRoleAccess();

    const [documents, setDocuments] = useState<ITabSupports>({
        icon:'',
        title: '',
        columns: [],
        content: [],
    })
    const [isLoading, setIsLoading] = useState(true); 
    const { idDocument } = useParams();

    const { billingState } = auditDetails;

    const invoiceIsLessThanConsolidated = [...statesActivities.received, ...statesActivities.annulled,  ...statesActivities.fulfilment,
        ...statesActivities.invoiceActivityLowerWithConsolidatedGlosses
    ].includes(billingState.code);
    

    useEffect(() => {
        async function fetchData() {
          const response = await apiInstance.get(GET_DATA_SUPPORT_CONTAINER);
          dispatch.linkSupports.addSupports(response?.data);
        }
        linkSupports.supports?.length <= 0 && fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])  

    const convertServiceGetSupports = (dataDocs: IResponseElectonicSupports[]) => {      
        try {
            const data = {
                icon: Image.iconoCheck,
                title: 'Soportes de la prestación del Servicio',
                columns: contentDataSupports,
                content: dataDocs?.map((item: IResponseElectonicSupports) => {
                    const format = item.contentType?.split("/")[1] ?? "-";
                    const supportName = item.support?.name?.replace(" (Obligatorio)", "") ?? '-';
                    
                    return {
                        id: item.id ?? 0,
                        support:{
                            code: item?.support?.code ?? null,
                            name: supportName,
                        },
                        createdAt: item?.createdAt ?? "-",
                        contentType: format,
                        state: item?.stateName ?? "-",
                        createdBy: item?.createdBy ?? "-",
                        originalName: item?.originalName ?? "-",
                    };
                }) ?? []
            };
            //Ordena la lista por nombre
            const sortedData = {
                ...data,
                content: customSortDocuments(data.content)
            }

            setDocuments( sortedData );
        } catch (error) {
            console.error("Error in convertServiceGetSupports:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const callGetSupports = (idDocumentProp: string) => {
        getData( `${GET_ELECTRONIC_DOCS}/${idDocumentProp}${GET_ELECTRONIC_SUPPORTS}`).then((response: any) => {
            response?.length > 0 && convertServiceGetSupports(response)
            setIsLoading(false)
        }).catch(()=> setIsLoading(false))
    }

    const downloadSupports = ()=>{
        dispatch.electronicDocuments.getFileExcelToExport({
            url:`${idDocument}${ELECTRONIC_SUPPORTS_DOWNLOAD}`,
            text:"SOPORTES descargados con Exito"
        })
    }
    const downloadDocument = (id:number)=>{
        dispatch.electronicDocuments.getFileExcelToExport({
            url:`${idDocument}${ELECTRONIC_SUPPORTS_DOWNLOAD_SINGLE}?supportId=${id}`,
            text:"SOPORTE descargado con Exito"
        })
    }

    const supportNotLoaded = (support: string) => support.toLowerCase() == 'no cargado';
    const supportLoaded = (support: string) => support.toLowerCase() == 'cargado';

    const uploadDocument = ( id: number, support: any, isReplace?: boolean ) => {
        dispatch.messageModal.setMessageModal({
            show: true, 
            title: `${isReplace ? 'Reemplazo' : 'Carga'} de Soporte  "${ support.name }"`,
            onClose() { dispatch.messageModal.cleanStateMessage() },
            size: 'medium',
            description: <UploadSupport id={ id } support={support.code} setIsLoading={ setIsLoading } idDocument={ idDocument ?? '' } callGetSupports={ callGetSupports } isReplace={isReplace} checkRoles={checkRoles} maxLength={7}/>,
            
        })
    }

    return {
        isLoading,
        documents,
        invoiceIsLessThanConsolidated,

        callGetSupports,
        downloadDocument,
        downloadSupports,
        supportNotLoaded,
        uploadDocument,
        supportLoaded
    }
}
