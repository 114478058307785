import { Button, Grid, Box, Typography, IconButton, Link } from '@mui/material';
import { Fragment, useEffect } from 'react'
import './styles/_uploadSupport.scss'
import { FileUploader } from 'react-drag-drop-files';
import { DeleteOutline } from '@mui/icons-material';
import { IUploadSupport } from './helpers/IUploadSupport';
import { useUploadSupport } from './hook/useUploadSupport';
import { useDispatch } from 'react-redux';
import { accesRoles, Dispatch } from 'shared';

export const UploadSupport = ({ id, support, isReplace = false, maxLength = 5, setIsLoading, idDocument, callGetSupports, checkRoles }: IUploadSupport) => {

    const dispatch = useDispatch<Dispatch>();

    const {  hasErrors, typeAccept, documents, onCancel, removeFile, handleChangeFile, errorFileType,
        errorFileSize, onOkButton, setDocuments, TitleComplement } = useUploadSupport({ id, support, isReplace, maxLength, setIsLoading, idDocument, callGetSupports })

    useEffect(() => {
        return () => {
            dispatch.linkSupports.cleanState();
            setDocuments(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return (
        <Fragment>
            <Box className='containerUppload' data-testid='content_upload_support'>
                <section className='contentDescription'>
                    <Typography 
                        variant='body2' 
                        className='descriptionTextSupports' 
                        data-testid="modal-text">
                            Tenga en cuenta que solo se aceptarán los archivos que se encuentren nombrados de esta manera, teniendo presente las extensiones o formatos validos que son: <b>PDF, JPG, PNG, XLSX, DOCX, PPTX, HTML Y ZIP </b> y que el tamaño máximo permitido de cada archivo es de {maxLength} MB. 
                    </Typography>
                    {
                        checkRoles?.(accesRoles.healthProvider) && <TitleComplement/>      
                    }
                </section>
                <div className='divContentModal'>
                    <FileUploader
                        types={typeAccept}
                        maxSize={ maxLength }
                        hoverTitle={' '}
                        handleChange={ handleChangeFile }
                        onTypeError={ errorFileType }
                        onSizeError={ errorFileSize }
                        fileOrFiles={documents}
                        data-testid='file_uploader'
                    >
                        <Box className='contentFile' data-testid='btn_file_upload' component="span">
                            <Typography data-testid={documents ? "name-file" : "name-file2"}>
                                Arrastra el archivo a cargar o haz 
                                <Link className='fileDocument'><b> click aquí para seleccionar ARCHIVO </b></Link>
                            </Typography>
                            {
                                documents && (
                                    <Box className='contentFilesSelected' data-testid='content_name_file'>
                                        <IconButton onClick={() => removeFile()} data-testid="file-remove">
                                            <DeleteOutline className='icon_remove'/>
                                        </IconButton>
                                        <Typography className='textoArchivo'>{documents.name}</Typography>
                                    </Box>
                                )
                            }
                        </Box>
                    </FileUploader>
                </div>
                <Box display='flex' justifyContent='space-between'>
                    <span className='spanError' data-testid='message_error'>
                        { hasErrors && 'Por favor cargue un archivo para continuar' }
                    </span>
                </Box>
                <Grid container direction={"row"} justifyContent={'center'} className='buttonContainer'>
                    <Button variant='contained' color='secondary' className='buttonSubmit' data-testid="btn_ok" onClick={ onOkButton }>
                        { isReplace ? 'Remplazar' : 'Cargar' }
                    </Button>
                    <Button variant='contained' color='secondary' className='buttonCancelar' onClick={ onCancel }  data-testid='btn_close'>
                        Cancelar
                    </Button>
                </Grid>
            </Box>
        </Fragment>
    )
}
