import { Dispatch } from 'shared/store/store';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Layout } from '../../shared/utils/ui/Layout';
import styles from './home.module.scss'

const dataHome = {
  title:'Bienvenido',
  description: 'El Portal de Trazabilidad de Factura Electrónica, está diseñado para facilitar el seguimiento y la trazabilidad de las facturas que ingresan a la Secretaría de Salud del departamento por concepto de atenciones en salud de la población a cargo de esta entidad territorial.',
}

export const Home = () => {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.menuDrawer.changeSelectedIndex(0);
    dispatch.electronicDocuments.cleanState();
  }, [dispatch.electronicDocuments, dispatch.menuDrawer])

  return (
    <Layout>
      <Box className={styles.containerHome}>
        <Box className={styles.content}>
          <Typography className='fs-6 fs-bold my-1' variant='body2'>{dataHome.title}</Typography>
          <Typography className='fs-9' variant='body2'>
            {dataHome.description}
          </Typography>
          <Button variant='contained'>Saber más...</Button>
        </Box>
      </Box>
    </Layout>
  )
}
