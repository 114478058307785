import { IContentTabSupports, IResponseElectonicSupports } from "../Models/IContentTabs";

export const customSortDocuments = (data: any[]) => {

    //Se define el orden personalizado de los soportes, por support.code
    const customOrder: string[]  = ['8', '2', '3', '4', '5', '6', '7', '9','10','11','12', '13', '14', '15','16','17'];

    const orderMap: Map<string, number> = new Map(customOrder.map((code, index) => [code, index]));

    return [...data].sort((a: IResponseElectonicSupports, b: IResponseElectonicSupports): number => {
        const codeA: string | null = a.support.code;
        const codeB: string | null = b.support.code;

        if (codeA === null && codeB === null) return 0;
        if (codeA === null) return 1;
        if (codeB === null) return -1;

        if (orderMap.has(codeA) && orderMap.has(codeB)) {
          return orderMap.get(codeA)! - orderMap.get(codeB)!;
        }
        
        if (orderMap.has(codeA)) return -1;
        if (orderMap.has(codeB)) return 1;

        const numA: number = parseInt(codeA) || Infinity;
        const numB: number = parseInt(codeB) || Infinity;
        return numA - numB;
    });
};